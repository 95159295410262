import React, { useRef, useState } from 'react';
import { useUpdateSellerProfileMutation } from '../../api/authApiSlice';
import { uploadImageRequest } from '../../api/requests';
import { FaMediumM, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Instagram, Link, Upload, Youtube } from 'react-feather';
import { FileInput } from 'flowbite-react';
import { Avatar } from '@mui/material';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/toastSlice';

const EditProfile = ({ user, setEditPro, editPro, refetch }) => {
  const dispatch = useDispatch();
  //form
  const [name, setName] = useState(user?.name);
  const [lastName, setLastName] = useState(user?.lastName);
  const [bio, setBio] = useState(user?.sellerProfile?.bio);
  const [image, setImage] = useState('');
  const [facebook, setFacebook] = useState(user?.sellerProfile?.facebook);
  const [youtube, setYoutube] = useState(user?.sellerProfile?.youtube);
  const [twitter, setTwitter] = useState(user?.sellerProfile?.twitter);
  const [instagram, setInstagram] = useState(user?.sellerProfile?.instagram);
  const [tiktok, setTiktok] = useState(user?.sellerProfile?.tiktok);
  const [linkedin, setLinkedin] = useState(user?.sellerProfile?.linkedin);
  const [link, setLink] = useState(user?.sellerProfile?.link);
  const [medium, setMedium] = useState(user?.sellerProfile?.medium);
  const [profilePic, setProfilePic] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const [updateSellerProfile, result] = useUpdateSellerProfileMutation();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(e.target.files);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    let profilePicUrl = user?.sellerProfile?.picture?.url;
    let profilePicKey = user?.sellerProfile?.picture?.key;

    try {
      //if files were added, upload to server and update profile pic url/key variables
      if (profilePic.length) {
        const image = new FormData();
        image.append('productImages', profilePic[0]);
        const imageDataReq = await uploadImageRequest.post(
          '/products/imageupload',
          image
        );
        console.log(imageDataReq);
        profilePicUrl = imageDataReq.data[0].url;
        profilePicKey = imageDataReq.data[0].key;
      }

      //update profile request goes here
      const updateProfileReq = await updateSellerProfile({
        name,
        bio,
        facebook,
        youtube,
        twitter,
        instagram,
        linkedin,
        tiktok,
        link,
        medium,
        profilePicUrl,
        profilePicKey,
      }).unwrap();

      if (updateProfileReq === 'Profile updated') {
        refetch();
        setEditPro(false);
        dispatch(showNotification('Profile updated'));
      } else {
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between w-full mb-2">
        <div className="flex flex-col items-start gap-1">
          <p className="text-sm text-stone-800">Edit profile</p>
          <p className="text-xs text-stone-600">
            Changes made here will be visible on your public page
          </p>
        </div>

        <div className="flex items-center gap-2">
          <button
            type="button"
            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={(e) => setEditPro(!editPro)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleUpdateProfile}
          >
            Save
          </button>
        </div>
      </div>
      <form className="flex flex-col gap-2">
        <div className="w-full flex gap-2">
          <div className="flex flex-col gap-2 items-start w-3/6">
            <div className="flex flex-col w-full">
              <p className="text-stone-600 text-xs">Name</p>
              <input
                type="text"
                className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="flex flex-col w-full">
              <p className="text-stone-600 text-xs">Bio</p>
              <div className="flex flex-col w-full relative">
                <textarea
                  placeholder="A little about you.."
                  className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs resize-none h-20"
                  value={bio}
                  maxLength={100}
                  onChange={(e) => setBio(e.target.value)}
                />
                <div className="absolute bottom-0 right-0 mr-2 mb-2">
                  <p className="text-xs text-stone-600">{bio.length}/100</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <p className="text-stone-600 text-xs">Profile picture</p>
              <div className="flex items-center gap-4 w-full">
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  src={
                    selectedImage
                      ? selectedImage
                      : user?.sellerProfile?.picture?.url
                  }
                />

                <div className="file-upload">
                  <input
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    type="file"
                    onChange={handleImageChange}
                  />
                  <button
                    onClick={handleButtonClick}
                    className="text-xs rounded-md bg-gray-200 text-stone-800 p-1 pl-2 pr-2 flex items-center gap-2"
                  >
                    Upload <Upload size={14} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-3/6">
            <p className="text-stone-600 text-xs">Social links</p>
            <div className="flex flex-col gap-2 w-full">
              <div className="flex w-full">
                <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center justify-center p-1 pl-2 pr-2">
                  <FaXTwitter className="text-md" />
                </div>
                <input
                  type="text"
                  className="border text-xs border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 flex-1"
                  placeholder="https://www.x.com/account"
                  onChange={(e) => setTwitter(e.target.value)}
                  value={twitter}
                />
              </div>

              <div className="flex w-full">
                <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center justify-center p-1 pl-2 pr-2">
                  <Instagram size={17} />
                </div>
                <input
                  type="text"
                  className="border text-xs border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 flex-1"
                  placeholder="https://instagram.com/account"
                  onChange={(e) => setInstagram(e.target.value)}
                  value={instagram}
                />
              </div>

              <div className="flex w-full">
                <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center justify-center p-1 pl-2 pr-2">
                  <Youtube size={17} />
                </div>
                <input
                  type="text"
                  className="border text-xs border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 flex-1"
                  placeholder="https://youtube.com/youraccount"
                  onChange={(e) => setYoutube(e.target.value)}
                  value={youtube}
                />
              </div>

              <div className="flex w-full">
                <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center justify-center p-1 pl-2 pr-2">
                  <FaMediumM className="text-md" />
                </div>
                <input
                  type="text"
                  className="border text-xs border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 flex-1"
                  placeholder="https://medium.com/@account"
                  onChange={(e) => setMedium(e.target.value)}
                  value={medium}
                />
              </div>

              <div className="flex w-full">
                <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center justify-center p-1 pl-2 pr-2">
                  <Link size={17} />
                </div>
                <input
                  type="text"
                  className="border text-xs border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 flex-1"
                  placeholder="https://yourlink.com"
                  onChange={(e) => setLink(e.target.value)}
                  value={link}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
