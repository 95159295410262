import React from 'react';
import { ChevronRight } from 'react-feather';
import Demos from './Demos';
import Countries from './Countries';

const MobileDocs = ({ demos, docsView, setDocsView }) => {
  return (
    <>
      {docsView === 'home' && (
        <div className="h-screen w-full p-4 flex justify-center mx-auto mt-16">
          <div className="w-96 mx-auto flex flex-col gap-2">
            <div className="w-full flex items-end justify-between pb-0">
              <div className="flex flex-col items-start">
                <p className="text-sm text-stone-800">Fruntt Docs</p>

                <p className="text-xs text-stone-600">
                  Learn how to use and navigate Fruntt like a pro with our docs
                </p>
              </div>
            </div>
            <button
              type="button"
              onClick={() => setDocsView('countries')}
              className="flex items-center justify-between w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
            >
              <div className="flex flex-col gap-1 items-start w-8/12">
                <p className="text-stone-800 text-xs">Available Countries</p>
                <p className="text-stone-600 text-xs text-left">
                  Find out what countries Fruntt is available in
                </p>
              </div>
              <ChevronRight size={16} className="text-stone-800" />
            </button>
            <button
              type="button"
              onClick={() => setDocsView('demos')}
              className="flex items-center justify-between w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
            >
              <div className="flex flex-col gap-1 items-start w-8/12">
                <p className="text-stone-800 text-xs">Tutorials</p>
                <p className="text-stone-600 text-xs text-left">
                  Watch our quick tutorials on how to use various features in
                  Fruntt
                </p>
              </div>
              <ChevronRight size={16} className="text-stone-800" />
            </button>
            <button
              disabled
              className="h-10 text-xs text-stone-600 w-full border border-gray-200 hover:bg-gray-200 rounded-md p-2"
            >
              More coming soon
            </button>
          </div>
        </div>
      )}

      {docsView === 'demos' && (
        <Demos demos={demos} setDocsView={setDocsView} />
      )}

      {docsView === 'countries' && <Countries setDocsView={setDocsView} />}
    </>
  );
};

export default MobileDocs;
