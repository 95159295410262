import Cookies from 'js-cookie';
import { showNotification } from '../redux/toastSlice';

const handleLogoutUser = ({ navigate, dispatch, type }) => {
  Cookies.remove('currentUser');
  Cookies.remove('aToken');
  Cookies.remove('rToken');
  Cookies.remove('isAuth');
  if (type === 'logout') {
    dispatch(showNotification('You have been logged out'));
    navigate('/login');
  } else if (type === 'delete') {
    dispatch(showNotification('Account deleted'));
    navigate('/signup');
  }
};

export default handleLogoutUser;
