import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import { isMobile } from 'react-device-detect';
import Desktop from './Desktop';
import { useGetPayoutsQuery } from '../../../api/payoutsApiSlice';
import { Spinner } from 'flowbite-react';
import Cookies from 'js-cookie';
import SideNavbar from '../../../components/SideNavbar';
import { Link } from 'react-router-dom';
import { CreditCard, Settings } from 'react-feather';

const Payouts = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const { data, isLoading, isSuccess, refetch } = useGetPayoutsQuery();

  useEffect(() => {
    refetch();
  }, []);

  const styles = isMobile
    ? 'w-full mx-auto h-screen bg-white mt-16'
    : 'w-full mx-auto h-screen bg-white ml-2';

  let content;

  if (isLoading) {
    content = (
      <div
        className="w-full flex items-center justify-center"
        style={{ height: '600px' }}
      >
        <Spinner />
      </div>
    );
  } else if (!currentUser?.setup) {
    content = (
      <div
        className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
        style={{ height: '600px' }}
      >
        <CreditCard size={18} className="text-stone-800" />
        <p className="text-stone-800 text-sm">No payouts</p>
        <p className="text-stone-600 text-xs w-60 text-center">
          You need to finish your account setup in settings and connect a payout
          option.
        </p>

        <Link
          to="/settings"
          className="flex items-center justify-center gap-1 bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
        >
          <Settings size={14} />
          <p className="text-xs">Settings</p>
        </Link>
      </div>
    );
  } else if (isSuccess) {
    content = isMobile ? '' : <Desktop data={data} />;
  }

  return (
    <>
      <Navbar />
      <div className="flex max-w-6xl mx-auto">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default Payouts;
