import React, { useRef, useState } from 'react';
import moment from 'moment-timezone';
import { availCurrencies } from '../../utils/availCurrencies';
import { availCountries } from '../../utils/availCountries';
import Select from 'react-select';
import { uploadImageRequest } from '../../api/requests';
import { Avatar } from '@mui/material';
import { Info, Upload, X } from 'react-feather';
import { Tooltip } from 'flowbite-react';

const GoogAuthSetup = ({
  user,
  handleAccountSetup,
  handleCancelEdit,
  name,
  setName,
  pageName,
  setPageName,
  bio,
  setBio,
  handleCountry,
  handleCurrency,
  handleTimezone,
  fileInputRef,
  error,
  selectedImage,
  handleImageChange,
  handleButtonClick,
  pageTaken,
  country,
  currency,
  currencySym,
  timezone,
}) => {
  //timezone options
  const timezones = moment.tz.names().map((tz) => ({ value: tz, label: tz }));

  return (
    <div className="flex flex-col items-start p-4 border border-gray-200 w-full rounded-md gap-4">
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <p className="text-sm text-stone-800">Acount setup</p>
          <p className="text-xs text-stone-600">
            Finish setting up your account below. These settings can be changed
            later.
          </p>
        </div>

        <div className="flex items-center gap-2">
          <button
            type="button"
            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleCancelEdit}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleAccountSetup}
          >
            Finish
          </button>
        </div>
      </div>
      {error && (
        <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
          <X size={16} className="text-red-500" />
          <p className="text-stone-800 text-xs">{error}</p>
        </div>
      )}
      <div className="flex items-start gap-4 w-full">
        <div className="flex flex-col gap-4 items-start w-3/6">
          <div className="flex flex-col w-full items-start">
            <p className="text-stone-600 text-xs">Page Name</p>
            <div className="flex w-full">
              <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
                <p className="text-xs">fruntt.com/</p>
              </div>
              <input
                type="text"
                placeholder="Page Name"
                className="border text-xs border-gray-200 bg-gray-50 border-l-0 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1"
                onChange={(e) => setPageName(e.target.value)}
                value={pageName}
              />
            </div>
            {pageTaken && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2 mt-1">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">Page name taken</p>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full items-start">
            <p className="text-stone-600 text-xs">Name</p>
            <input
              type="text"
              className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
              maxLength={50}
            />
          </div>

          <div className="flex flex-col w-full">
            <p className="text-stone-600 text-xs">Bio</p>
            <div className="flex flex-col w-full relative">
              <textarea
                placeholder="A little about you.."
                className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs resize-none h-20"
                value={bio}
                maxLength={100}
                onChange={(e) => setBio(e.target.value)}
              />
              <div className="absolute bottom-0 right-0 mr-2 mb-2">
                <p className="text-xs text-stone-600">{bio.length}/100</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-full">
            <p className="text-stone-600 text-xs">Picture</p>
            <div className="flex items-center gap-2 w-full">
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={selectedImage ? selectedImage : ''}
              />

              <div className="file-upload">
                <input
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  type="file"
                  onChange={handleImageChange}
                />
                <button
                  onClick={handleButtonClick}
                  className="text-xs rounded-md bg-gray-200 text-stone-800 p-1 pl-2 pr-2 flex items-center gap-2"
                >
                  Upload <Upload size={14} />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 items-start w-3/6">
          <div className="flex flex-col w-full">
            <Tooltip
              content="This should match the country of your bank account or Stripe account that you will use for payouts"
              style="light"
              className="w-80 text-stone-600"
            >
              <div className="flex items-center gap-1">
                <p className="text-xs text-stone-600">Country</p>

                <Info size={12} className="text-stone-600" />
              </div>
            </Tooltip>
            <Select
              options={availCountries}
              onChange={handleCountry}
              menuPortalTarget={document.body}
              value={Object.keys(country).length ? country : undefined}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 45,
                  fontSize: '12px',
                  minHeight: 35,
                  height: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
            />
          </div>

          <div className="flex flex-col w-full">
            <Tooltip
              content="The currency that is displayed in your dashboard and on any new items you create"
              style="light"
              className="w-80 text-stone-600"
            >
              <div className="flex items-center gap-1">
                <p className="text-xs text-stone-600">Currency</p>

                <Info size={12} className="text-stone-600" />
              </div>
            </Tooltip>
            <Select
              options={availCurrencies}
              onChange={handleCurrency}
              value={
                currencySym
                  ? { value: currency, label: `${currency}(${currencySym})` }
                  : undefined
              }
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 900,
                  fontSize: '12px',
                  minHeight: 35,
                  height: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 900,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
            />
          </div>
          <div className="flex flex-col w-full">
            <p className="text-stone-600 text-xs">Timezone</p>
            <Select
              options={timezones}
              onChange={handleTimezone}
              menuPortalTarget={document.body}
              value={Object.keys(timezone).length ? timezone : undefined}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 99,
                  fontSize: '12px',
                  minHeight: 35,
                  height: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 99,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogAuthSetup;
