import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Desktop from './Desktop';

const Notifications = ({ currentUser }) => {
  return (
    <>
      <Navbar />
      <div className="mx-auto max-w-6xl">
        <Desktop />
      </div>
    </>
  );
};

export default Notifications;
