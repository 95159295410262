import './App.css';
import React from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import NoAuth from './components/NoAuth';
import RequireAuth from './components/RequireAuth';
import Register from './pages/Unauthenticated/Signup/Register';
import Login from './pages/Unauthenticated/Login/Login';
import DashHome from './pages/Authenticated/Home/DashHome';
import EditItem from './pages/Authenticated/EditItem/EditItem';
import Orders from './pages/Authenticated/Orders/Orders';
import OrderDetail from './pages/Authenticated/OrderDetail/OrderDetail';
import Settings from './pages/Authenticated/Settings/Settings';
import Pricing from './pages/Unauthenticated/Pricing';
import Products from './pages/Authenticated/Items/Products';
import ConfirmEmail from './pages/Unauthenticated/ConfirmEmail';
import Download from './pages/Unauthenticated/OrderSuccess/Download';
import PasswordReset from './pages/Unauthenticated/PasswordReset';
import RequestReset from './pages/Unauthenticated/RequestReset';
import TwitterAuth from './pages/Unauthenticated/TwitterAuth';
import Privacy from './pages/Unauthenticated/Privacy';
import TOS from './pages/Unauthenticated/TOS';
import 'normalize.css';
import Marketplace from './pages/Unauthenticated/Marketplace/Marketplace';
import Library from './pages/Authenticated/Library/Library';
import Customers from './pages/Authenticated/Customers/Customers';
import NotFound from './pages/Unauthenticated/NotFound';
import Contact from './pages/Unauthenticated/Contact/Contact';
import ItemDetail from './pages/Unauthenticated/ItemDetail/ItemDetail';
import Payouts from './pages/Authenticated/Payouts/Payouts';
import PayoutDetail from './pages/Authenticated/PayoutDetail/PayoutDetail';
import Notifications from './pages/Authenticated/Notifications/Notifications';
import Landing from './pages/Unauthenticated/Landing';
import Docs from './pages/Unauthenticated/Docs/Docs';
import CreatorDetail from './pages/Unauthenticated/CreatorDetail/CreatorDetail';
import NewItem from './pages/Authenticated/NewItem/NewItem';
import ItemReviews from './pages/Authenticated/ItemReviews/ItemReviews';
import Refunds from './pages/Authenticated/Refunds/Refunds';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<NoAuth />}>
            <Route path="*" element={<NotFound />} />

            <Route index element={<Landing />} />

            <Route path="privacy" element={<Privacy />} />

            <Route path="docs" element={<Docs />} />

            <Route path="tos" element={<TOS />} />

            <Route path="contact" element={<Contact />} />

            <Route path="pricing" element={<Pricing />} />

            <Route path="login" element={<Login />} />

            <Route path="signup" element={<Register />} />

            <Route path="marketplace" element={<Marketplace />} />

            <Route path=":pageUrl/:productUrl" element={<ItemDetail />} />

            <Route path=":storeUrl" element={<CreatorDetail />} />

            <Route path="auth/twitter" element={<TwitterAuth />} />

            <Route path="order/:orderId" element={<Download />} />

            <Route path="reset/password" element={<RequestReset />} />

            <Route path="password/reset/:token" element={<PasswordReset />} />

            <Route path="confirm/:userId" element={<ConfirmEmail />} />

            {/* routes require user to be logged in */}
            <Route element={<RequireAuth />}>
              <Route path="dashboard" element={<DashHome />} />

              <Route path="dashboard/items" element={<Products />} />

              <Route path="dashboard/orders" element={<Orders />} />

              <Route
                path="dashboard/orders/:orderId"
                element={<OrderDetail />}
              />

              <Route
                path="dashboard/item/reviews/:productId"
                element={<ItemReviews />}
              />

              <Route path="dashboard/customers" element={<Customers />} />

              <Route path="dashboard/payouts" element={<Payouts />} />

              <Route
                path="dashboard/payouts/:payoutId"
                element={<PayoutDetail />}
              />

              <Route path="dashboard/refunds" element={<Refunds />} />

              <Route path="dashboard/library" element={<Library />} />

              <Route path="dashboard/new/item" element={<NewItem />} />

              <Route
                path="dashboard/item/edit/:productId"
                element={<EditItem />}
              />

              <Route path="settings" element={<Settings />} />

              <Route path="notifications" element={<Notifications />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
