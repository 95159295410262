import React, { useState, useMemo, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { FileInput, Spinner, Tooltip } from 'flowbite-react';
import { Avatar } from '@mui/material';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import { uploadImageRequest } from '../../api/requests';
import { useAccountSetupMutation } from '../../api/authApiSlice';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Info, Settings, Upload, X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/toastSlice';
import moment from 'moment-timezone';
import { availCurrencies } from '../../utils/availCurrencies';
import { availCountries } from '../../utils/availCountries';
import GoogAuthSetup from './GoogAuthSetup';

const Setup = ({ refetch, user }) => {
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //form state
  const [pageName, setPageName] = useState('');
  const [name, setName] = useState(user?.name ? user?.name : '');
  const [bio, setBio] = useState('');
  const [timezone, setTimezone] = useState({});
  const [profilePic, setProfilePic] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [country, setCountry] = useState({});
  const [currency, setCurrency] = useState('');
  const [currencySym, setCurrencySym] = useState('');
  const [error, setError] = useState('');
  const [pageTaken, setPageTaken] = useState(false);
  const fileInputRef = useRef(null);

  //account setup hook
  const [accountSetup, { isLoading }] = useAccountSetupMutation();

  //country options
  const options = useMemo(() => countryList().getData(), []);
  //timezone options
  const timezones = moment.tz.names().map((tz) => ({ value: tz, label: tz }));

  const handleCancelEdit = () => {
    setEdit(false);
    setBio('');
    setName('');
    setPageName('');
    setCountry({});
    setCurrency('');
    setCurrencySym('');
    setSelectedImage(null);
    setProfilePic([]);
    setTimezone({});
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleAccountSetup = async (e) => {
    e.preventDefault();
    let profilePicUrl = '';
    let profilePicKey = '';

    if (!name || !bio || !country.value || !currency) {
      setError('Please fill out all fields');
      return;
    }

    if (!profilePic.length) {
      setError('Upload a profile picture!');
      return;
    }

    try {
      //if files were added, upload to server and update profile pic url/key variables
      if (profilePic.length) {
        const image = new FormData();
        image.append('productImages', profilePic[0]);
        const imageDataReq = await uploadImageRequest.post(
          '/products/imageupload',
          image
        );
        profilePicUrl = imageDataReq.data[0].url;
        profilePicKey = imageDataReq.data[0].key;
      }

      const setupReq = await accountSetup({
        name: name,
        bio: bio,
        pageName: pageName,
        country: country,
        currency: currency,
        currencySym: currencySym,
        profilePicUrl: profilePicUrl,
        profilePicKey: profilePicKey,
        timezone: timezone,
      }).unwrap();

      if (setupReq === 'Account setup') {
        dispatch(showNotification('Account setup complete'));
        refetch();
        navigate('/settings');
      }
    } catch (err) {
      if (!err?.status) {
        setError('Server not responding');
        return;
      } else if (err.status === 400) {
        if (err?.data?.msg === 'Page name taken') {
          setPageTaken(true);
          setCountry(err?.data?.country);
          setCurrency(err?.data?.currency);
          setCurrencySym(err?.data?.currencySym);
          setTimezone(err?.data?.timezone);
          return;
        }
      } else {
        console.log(err);
        setError('There was an error, try again soon');
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(e.target.files);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCountry = (value) => {
    setCountry(value);
  };

  const handleCurrency = (value) => {
    setCurrency(value.value);
    const selectedLabel = value?.label;
    const symbol = selectedLabel.match(/\(([^)]+)\)/)[1];
    setCurrencySym(symbol);
  };

  const handleTimezone = (value) => {
    setTimezone(value);
  };

  useEffect(() => {
    setError('');
  }, [name, bio, country, currency, profilePic, pageName]);

  useEffect(() => {
    setPageTaken(false);
  }, [pageName]);

  return isLoading ? (
    <div className="flex items-center justify-center border border-gray-200 w-full rounded-md h-96">
      <Spinner />
    </div>
  ) : (
    <>
      {edit ? (
        <>
          {user?.googleAuth ? (
            <GoogAuthSetup
              user={user}
              handleAccountSetup={handleAccountSetup}
              handleCancelEdit={handleCancelEdit}
              name={name}
              setName={setName}
              pageName={pageName}
              setPageName={setPageName}
              bio={bio}
              setBio={setBio}
              handleCountry={handleCountry}
              handleCurrency={handleCurrency}
              handleTimezone={handleTimezone}
              fileInputRef={fileInputRef}
              error={error}
              selectedImage={selectedImage}
              handleImageChange={handleImageChange}
              handleButtonClick={handleButtonClick}
              pageTaken={pageTaken}
              country={country}
              currency={currency}
              currencySym={currencySym}
              timezone={timezone}
            />
          ) : (
            <div className="flex flex-col items-start p-4 border border-gray-200 w-full rounded-md gap-4">
              {error && (
                <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                  <X size={16} className="text-red-500" />
                  <p className="text-stone-800 text-xs">{error}</p>
                </div>
              )}
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col">
                  <p className="text-sm text-stone-800">Acount setup</p>
                  <p className="text-xs text-stone-600">
                    Finish setting up your account below. These settings can be
                    changed later.
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                    onClick={handleAccountSetup}
                  >
                    Finish
                  </button>
                </div>
              </div>
              <div className="flex items-start gap-4 w-full">
                <div className="flex flex-col gap-4 items-start w-3/6">
                  <div className="flex flex-col w-full items-start">
                    <p className="text-stone-600 text-xs">Name</p>
                    <input
                      type="text"
                      className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs"
                      placeholder="Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      maxLength={50}
                    />
                  </div>

                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Bio</p>
                    <div className="flex flex-col w-full relative">
                      <textarea
                        placeholder="A little about you.."
                        className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md p-2 text-xs resize-none h-20"
                        value={bio}
                        maxLength={100}
                        onChange={(e) => setBio(e.target.value)}
                      />
                      <div className="absolute bottom-0 right-0 mr-2 mb-2">
                        <p className="text-xs text-stone-600">
                          {bio.length}/100
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <p className="text-stone-600 text-xs">Picture</p>
                    <div className="flex items-center gap-2 w-full">
                      <Avatar
                        sx={{ width: 32, height: 32 }}
                        src={selectedImage ? selectedImage : ''}
                      />

                      <div className="file-upload">
                        <input
                          style={{ display: 'none' }}
                          ref={fileInputRef}
                          type="file"
                          onChange={handleImageChange}
                        />
                        <button
                          onClick={handleButtonClick}
                          className="text-xs rounded-md bg-gray-200 text-stone-800 p-1 pl-2 pr-2 flex items-center gap-2"
                        >
                          Upload <Upload size={14} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 items-start w-3/6">
                  <div className="flex flex-col w-full">
                    <Tooltip
                      content="This should match the country of your bank account or Stripe account that you will use for payouts"
                      style="light"
                      className="w-80 text-stone-600"
                    >
                      <div className="flex items-center gap-1">
                        <p className="text-xs text-stone-600">Country</p>

                        <Info size={12} className="text-stone-600" />
                      </div>
                    </Tooltip>
                    <Select
                      options={availCountries}
                      onChange={handleCountry}
                      menuPortalTarget={document.body}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 45,
                          fontSize: '12px',
                          minHeight: 35,
                          height: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                    />
                  </div>

                  <div className="flex flex-col w-full">
                    <Tooltip
                      content="The currency that is displayed in your dashboard and on any new items you create"
                      style="light"
                      className="w-80 text-stone-600"
                    >
                      <div className="flex items-center gap-1">
                        <p className="text-xs text-stone-600">Currency</p>

                        <Info size={12} className="text-stone-600" />
                      </div>
                    </Tooltip>
                    <Select
                      options={availCurrencies}
                      onChange={handleCurrency}
                      menuPortalTarget={document.body}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 900,
                          fontSize: '12px',
                          minHeight: 35,
                          height: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 900,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Timezone</p>
                    <Select
                      options={timezones}
                      onChange={handleTimezone}
                      menuPortalTarget={document.body}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 99,
                          fontSize: '12px',
                          minHeight: 35,
                          height: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 99,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div
          className="flex flex-col items-center justify-center border border-gray-200 w-full rounded-md gap-2"
          style={{ height: '600px' }}
        >
          <Settings size={18} className="text-stone-800" />
          <p className="text-stone-800 text-sm">Account Setup</p>
          <p className="text-xs text-stone-800">Account ID: {user?._id}</p>
          <p className="text-stone-600 text-xs text-center w-80">
            Finish your account setup so you can connect a payout option and
            create items
          </p>
          <button
            type="button"
            className="flex items-center justify-center bg-gray-200 text-xs text-stone-800 rounded-md p-1 pl-2 pr-2"
            onClick={(e) => setEdit(!edit)}
          >
            Finish Setup <ChevronRight size={14} />
          </button>
        </div>
      )}
    </>
  );
};

export default Setup;
