import React, { useState, useEffect } from 'react';
import EditDesktop from './EditDesktop';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useUpdateDigitalProductMutation,
  useDeleteProductMutation,
  useDeleteItemImageMutation,
} from '../../../api/productsApiSlice';
import { uploadImageRequest } from '../../../api/requests';
import { showNotification } from '../../../redux/toastSlice';
import { useDispatch } from 'react-redux';

const EditDigital = ({ product, refetch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const [title, setTitle] = useState(product?.title);
  const [description, setDescription] = useState(product?.description);
  const [info, setInfo] = useState(product?.info);
  const [price, setPrice] = useState(product?.price);
  const [image, setImage] = useState([]);
  const [files, setFiles] = useState([]);
  const [published, setPublished] = useState(product?.published);
  const [inReview, setInReview] = useState(product?.inReview);
  const [digitalType, setDigitalType] = useState(product?.digitalType);
  const [link, setLink] = useState(product?.link);
  const [callToAction, setCallToAction] = useState(product?.callToAction);
  const [payChoice, setPayChoice] = useState(product?.payChoice);
  const [suggestedPrice, setSuggestedPrice] = useState(product?.suggestedPrice);
  const [url, setUrl] = useState(product?.url);
  const [free, setFree] = useState(product?.free);
  const [marketplace, setMarketplace] = useState(product?.marketplace);
  const [category, setCategory] = useState(product?.categories);
  const [dupLink, setDupLink] = useState(product?.duplicationLink);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const [unpublish, setUnpublish] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);
  const [versionTitle, setVersionTitle] = useState('');
  const [versionSum, setVersionSum] = useState('');
  const [versionPrice, setVersionPrice] = useState(0);
  const [platform, setPlatform] = useState(product?.platform);
  const [versions, setVersions] = useState(product?.versions);
  const [add, setAdd] = useState(false);
  const [urlTaken, setUrlTaken] = useState(false);
  const [errMsgs, setErrMsgs] = useState([]);

  const [updateDigitalProduct, result] = useUpdateDigitalProductMutation();
  const [deleteProduct, { isLoading: deletingProduct }] =
    useDeleteProductMutation();
  const [productContent, setProductContent] = useState(product?.content);

  const handleType = (value) => {
    setDigitalType(value.value);
  };

  const handleAction = (value) => {
    setCallToAction(value.value);
  };

  const handleCategory = (value) => {
    setCategory(value);
  };

  const handlePlatform = (value) => {
    setPlatform(value.value);
    setCategory([]);
  };

  const handleAddVariation = () => {
    setVersions([
      ...versions,
      {
        title: versionTitle,
        description: versionSum,
        price: versionPrice,
        link: '',
      },
    ]);
    setVersionPrice(0);
    setVersionSum('');
    setVersionTitle('');
    setAdd(false);
  };

  const handleRemoveVariation = (index) => {
    const newVersions = [...versions];
    newVersions.splice(index, 1);
    setVersions(newVersions);
  };

  const setLinkForIndex = (index, link, title) => {
    // const newVersions = [...versions];
    // newVersions[index].link = link;

    const newVersions = versions.map((version, i) => {
      if (i === index) {
        // Return a new object with the updated link property
        return { ...version, link: link };
      }
      return version; // For other indices, return the original object
    });

    setVersions(newVersions);
    const updatedErrMsgs = errMsgs.filter(
      (err) =>
        err.msg !==
        `${
          platform === 'Notion' ? 'Duplicate' : 'Remix'
        } link for ${title} variation missing`
    );
    setErrMsgs(updatedErrMsgs);
  };

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) => err.msg !== 'Item title missing'
    );
    setErrMsgs(updatedErrMsgs);
  }, [title]);

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) => err.msg !== 'Item URL missing'
    );
    setErrMsgs(updatedErrMsgs);
    setUrlTaken(false);
  }, [url]);

  useEffect(() => {
    const updatedErrMsgs = errMsgs.filter(
      (err) =>
        err.msg !==
        `${
          platform === 'Notion'
            ? 'Duplicate link missing'
            : 'Remix link missing'
        }`
    );
    setErrMsgs(updatedErrMsgs);
  }, [dupLink]);

  const handleSaveEdit = async (e) => {
    e.preventDefault();

    setUpdatingProduct(true);

    let uploadedFiles = [];
    let uploadedImages = [];
    let newCoverImageUrl = '';
    let newCoverImageKey = '';

    const errs = [];
    if (!title.trim()) {
      errs.push({ type: 'detail', msg: 'Item title missing' });
    }
    if (!url.trim()) {
      errs.push({ type: 'detail', msg: 'Item URL missing' });
    }
    if (!dupLink.trim()) {
      errs.push({
        type: 'content',
        msg: `${platform === 'Notion' ? 'Duplicate' : 'Remix'} link missing`,
      });
    }
    if (!image.length && !product?.coverImages.length) {
      errs.push({ type: 'media', msg: 'Item media missing' });
    }
    if (versions.length) {
      versions.map((ver) => {
        if (!ver?.link) {
          errs.push({
            type: 'content',
            msg: `${platform === 'Notion' ? 'Duplicate' : 'Remix'} link for ${
              ver?.title
            } variation missing`,
          });
        }
      });
    }

    if (errs.length) {
      setErrMsgs(errs);
      setUpdatingProduct(false);
      return;
    }

    //to see if quill editor is empty
    var regex = /(<([^>]+)>)/gi;
    const hasText = !!productContent.replace(regex, '').length;
    const hasInfo = !!info.replace(regex, '').length;

    try {
      //first try to upload new coverImage if one exists
      if (image.length) {
        const imageToUpload = new FormData();
        // imageToUpload.append('productImages', image[0].file);
        for (var x = 0; x < image.length; x++) {
          imageToUpload.append('productImages', image[x]);
        }
        const imageDataReq = await uploadImageRequest.post(
          '/products/imageupload',
          imageToUpload
        );
        // newCoverImageUrl = imageDataReq.data[0].url;
        // newCoverImageKey = imageDataReq.data[0].key;
        uploadedImages = imageDataReq.data;
      }

      const editProductReq = await updateDigitalProduct({
        title: title,
        description: description,
        price: free ? 0 : price,
        published: published,
        unpublish: unpublish,
        coverImageUrl: newCoverImageUrl,
        coverImageKey: newCoverImageKey,
        coverImages: uploadedImages,
        files: uploadedFiles,
        productId: product?._id,
        digitalType: digitalType,
        content: hasText ? productContent : '',
        info: hasInfo ? info : '',
        suggestedPrice: suggestedPrice ? suggestedPrice : price,
        payChoice: payChoice,
        callToAction: callToAction,
        url: url,
        free: free,
        marketplace: marketplace,
        categories: category,
        dupLink: dupLink,
        platform: platform,
        versions: versions,
        urlChanged: url === product?.url ? false : true,
      }).unwrap();

      if (editProductReq === 'Product updated') {
        dispatch(showNotification('Item updated'));
        refetch();
        setUpdatingProduct(false);
        navigate('/dashboard/items');
      } else if (editProductReq === 'Url taken') {
        setUrlTaken(true);
        setUpdatingProduct(false);
        return;
      }
    } catch (err) {
      console.log(err);
      setUpdatingProduct(false);
      setError('There was an error, please try again later');
    }
  };

  const handleDelete = async () => {
    const deleteItemReq = await deleteProduct(product._id).unwrap();

    if (deleteItemReq === 'Item deleted') {
      dispatch(showNotification('Item deleted'));
      navigate('/dashboard/items');
    } else {
      setError('There was an error deleting your item');
      return;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Customize the warning message
      const warningMessage =
        'You have unsaved changes. Are you sure you want to leave?';

      // Modern browsers require a return value to trigger the confirmation dialog
      event.preventDefault();
      event.returnValue = warningMessage;

      return warningMessage;
    };

    // Add event listener for the 'beforeunload' event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location, navigate]);

  return (
    <EditDesktop
      product={product}
      title={title}
      setTitle={setTitle}
      digitalType={digitalType}
      setDigitalType={setDigitalType}
      description={description}
      setDescription={setDescription}
      info={info}
      setInfo={setInfo}
      price={price}
      setPrice={setPrice}
      setImage={setImage}
      image={image}
      setFiles={setFiles}
      published={published}
      inReview={inReview}
      setPublished={setPublished}
      handleDelete={handleDelete}
      deletingProduct={deletingProduct}
      handleSaveEdit={handleSaveEdit}
      error={error}
      refetchProduct={refetch}
      link={link}
      setLink={setLink}
      productContent={productContent}
      setProductContent={setProductContent}
      callToAction={callToAction}
      setCallToAction={setCallToAction}
      payChoice={payChoice}
      setPayChoice={setPayChoice}
      suggestedPrice={suggestedPrice}
      setSuggestedPrice={setSuggestedPrice}
      setUrl={setUrl}
      url={url}
      free={free}
      setFree={setFree}
      marketplace={marketplace}
      setMarketplace={setMarketplace}
      category={category}
      handleAction={handleAction}
      handleType={handleType}
      handleCategory={handleCategory}
      updatingProduct={updatingProduct}
      emptyFields={emptyFields}
      dupLink={dupLink}
      setDupLink={setDupLink}
      handlePlatform={handlePlatform}
      platform={platform}
      setUnpublish={setUnpublish}
      unpublish={unpublish}
      versions={versions}
      setVersions={setVersions}
      handleAddVariation={handleAddVariation}
      handleRemoveVariation={handleRemoveVariation}
      add={add}
      setAdd={setAdd}
      setVersionPrice={setVersionPrice}
      setVersionSum={setVersionSum}
      setVersionTitle={setVersionTitle}
      setLinkForIndex={setLinkForIndex}
      urlTaken={urlTaken}
      errMsgs={errMsgs}
    />
  );
};

export default EditDigital;
