import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { availCountries } from '../../utils/availCountries';
import { availCurrencies } from '../../utils/availCurrencies';
import { useUpdateAccountInfoMutation } from '../../api/authApiSlice';
import { Info, Lock, X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/toastSlice';
import { Tooltip } from 'flowbite-react';
import moment from 'moment-timezone';
import { FcGoogle } from 'react-icons/fc';

const EditAccount = ({ user, refetch, edit, setEdit, setChange, change }) => {
  const dispatch = useDispatch();

  const [country, setCountry] = useState(user?.country);
  const [email, setEmail] = useState(user?.email);
  const [currency, setCurrency] = useState(user?.currency?.code);
  const [currencySym, setCurrencySym] = useState(user?.currency?.symbol);
  const [timezone, setTimezone] = useState(user?.timezone);
  const [showTooltip, setShowTooltip] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const currencyValue = {
    value: currency,
    label: `${currency}(${currencySym})`,
  };

  const options = useMemo(() => countryList().getData(), []);
  const timezones = moment.tz.names().map((tz) => ({ value: tz, label: tz }));

  const [updateAccountInfo, { isLoading, error }] =
    useUpdateAccountInfoMutation();

  const handleSaveAccountInfo = async (e) => {
    e.preventDefault();

    try {
      const updateAccountInfoReq = await updateAccountInfo({
        email,
        country,
        currency,
        currencySym,
        timezone,
        emailChanged: user?.email === email ? false : true,
      }).unwrap();

      if (updateAccountInfoReq.msg === 'User updated') {
        refetch();
        setEdit(false);
        dispatch(showNotification('Account updated'));
      } else if (updateAccountInfoReq.msg === 'Email taken') {
        setEmailTaken(true);
        return;
      }
    } catch (err) {
      return;
    }
  };

  const handleCurrency = (value) => {
    setCurrency(value.value);
    const selectedLabel = value?.label;
    const symbol = selectedLabel.match(/\(([^)]+)\)/)[1];
    setCurrencySym(symbol);
  };

  const handleCountry = (value) => {
    setCountry(value);
  };

  const handleTimezone = (value) => {
    setTimezone(value.value);
  };

  useEffect(() => {
    setEmailTaken(false);
  }, [email]);

  return (
    <div className="w-full flex-col">
      <div className="flex items-center justify-between w-full mb-2">
        <div className="flex flex-col items-start gap-1">
          {user?.googleAuth ? (
            <div className="flex items-end gap-2">
              <p className="text-sm text-stone-800">Edit account</p>
              <div className="border border-gray-200 rounded-md flex items-center justify-center gap-2 p-1">
                <p className="text-xs text-stone-800">Authenticated using</p>{' '}
                <FcGoogle className="text-lg" />
              </div>
            </div>
          ) : (
            <p className="text-sm text-stone-800">Edit Account</p>
          )}
          <p className="text-xs text-stone-600">{`ID: ${user?._id}`}</p>
        </div>

        <div className="flex items-center gap-2">
          <button
            type="button"
            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={(e) => setEdit(!edit)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleSaveAccountInfo}
            disabled={isLoading}
          >
            Save
          </button>
        </div>
      </div>

      <form className="flex items-start w-full gap-2">
        <div className="w-3/6 flex flex-col items-start gap-2">
          <div className="flex flex-col w-full">
            <p className="text-xs text-stone-600">Email</p>
            {user?.googleAuth ? (
              <div className="flex flex-col w-full">
                <input
                  type="text"
                  className="border border-gray-200 w-full bg-gray-50 rounded-md text-xs"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  disabled={user?.googleAuth}
                  onMouseOver={() => setShowTooltip(!showTooltip)}
                  onMouseOut={() => setShowTooltip(!showTooltip)}
                />
                {showTooltip && (
                  <p className="text-stone-600 text-xs flex items-center gap-1">
                    Changing email is unavailable when signed in with{' '}
                    <FcGoogle className="text-xs" />
                  </p>
                )}
              </div>
            ) : (
              <input
                type="text"
                className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md text-xs"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            )}
            {emailTaken && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2 mt-1">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">Email already in use</p>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full">
            <p className="text-xs text-stone-600">Timezone</p>
            <Select
              options={timezones}
              onChange={handleTimezone}
              className="w-full"
              value={{ label: timezone, value: timezone }}
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 99999,
                  fontSize: '12px',
                  minHeight: 35,
                  height: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2 w-3/6">
          <div className="flex flex-col w-full">
            <div className="flex items-center gap-1">
              <p className="text-xs text-stone-600">Country</p>
              <Tooltip
                content="Should always match the country of your bank account or Stripe account"
                style="light"
                className="w-72 text-stone-600"
              >
                <Info size={12} className="text-stone-600" />
              </Tooltip>
            </div>
            <Select
              options={availCountries}
              onChange={handleCountry}
              className="w-full"
              value={country}
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 0,
                  fontSize: '12px',
                  minHeight: 35,
                  height: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
            />
          </div>
          <div className="flex flex-col w-full">
            <Tooltip
              content="The currency that is displayed in your dashboard and on any new items you create"
              style="light"
              className="w-80 text-stone-600"
            >
              <div className="flex items-center gap-1">
                <p className="text-xs text-stone-600">Currency</p>

                <Info size={12} className="text-stone-600" />
              </div>
            </Tooltip>
            <Select
              options={availCurrencies}
              onChange={handleCurrency}
              value={currencyValue}
              className="text-sm"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  backgroundColor: 'rgb(249 250 251)',
                  borderWidth: 1,
                  '&:hover': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  '&:focus': {
                    backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  fontSize: '12px',
                  position: 'relative',
                  minHeight: 35,
                  height: 35,
                }),
                indicatorsContainer: (provided) => ({
                  ...provided,
                  height: 35,
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 999,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
            />
          </div>
        </div>
      </form>
      {/* <div className='w-full flex justify-start'>
        <Link
          to='/dashboard/password/change'
          className='flex items-center justify-center rounded-md p-1 pl-2 pr-2 hover:bg-gray-200 text-stone-800 text-xs mt-2'
        >
          Change Password
          <Lock className='ml-1' size={13} />
        </Link>
      </div> */}
    </div>
  );
};

export default EditAccount;
