import React from 'react';
import { isMobile } from 'react-device-detect';
import { AlertOctagon } from 'react-feather';
import { Link } from 'react-router-dom';

const ItemNotFound = () => {
  return isMobile ? (
    <div className="w-full p-4">
      <div className="w-full mx-auto h-96 rounded-md flex items-center justify-center mt-52">
        <div className="flex flex-col items-center w-72 gap-2">
          <AlertOctagon size={18} className="text-stone-800" />
          <p className="text-sm text-stone-800 text-center">
            Item does not exist
          </p>
          <p className="text-xs text-stone-600 text-center">
            The item you are trying to view does not exist or it was deleted.
          </p>
          <Link to="/">
            <button className="text-stone-800 rounded-md text-xs p-1 pl-2 pr-2 bg-gray-200 mt-2">
              Return Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="max-w-2xl mx-auto h-96 rounded-md flex items-center justify-center mt-52">
      <div className="flex flex-col items-center w-72 gap-2">
        <AlertOctagon size={18} className="text-stone-800" />
        <p className="text-sm text-stone-800 text-center">
          Item does not exist
        </p>
        <p className="text-xs text-stone-600 text-center">
          The item you are trying to view does not exist or it was deleted.
        </p>
        <Link to="/">
          <button className="text-stone-800 rounded-md text-xs p-1 pl-2 pr-2 bg-gray-200 mt-2">
            Return Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ItemNotFound;
