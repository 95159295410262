import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import { useGetStoreOrdersQuery } from '../../../api/ordersApiSlice';
import Spinner from '../../../components/Spinner';
import OrdersMobile from './OrdersMobile';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import DesktopOrders from './DesktopOrders';
import SideNavbar from '../../../components/SideNavbar';

const Orders = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [tableView, setTableView] = useState('all');
  const {
    data: orders,
    isLoading,
    isSuccess,
    isError,
    isRefetching,
    refetch,
  } = useGetStoreOrdersQuery({ storeId: currentUser?.store?._id });

  useEffect(() => {
    refetch();
  }, []);

  let content;
  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    currentUser.newOrders = orders.unseen;
    const updatedUser = JSON.stringify(currentUser);
    Cookies.set('currentUser', updatedUser, { sameSite: 'Lax' });
    content = isMobile ? (
      <OrdersMobile
        orders={orders}
        tableView={tableView}
        setTableView={setTableView}
      />
    ) : (
      <DesktopOrders
        orders={orders.orders}
        refetch={refetch}
        newOrders={currentUser?.newOrders}
        isRefetching={isRefetching}
        currentUser={currentUser}
      />
    );
  }

  const styles = isMobile
    ? 'w-full mt-16 mx-auto h-fit p-2 bg-gray-50'
    : 'w-full mx-auto h-screen bg-white ml-2';

  return (
    <>
      <Navbar />
      <div className="flex mx-auto max-w-6xl">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default Orders;
