import React, { useEffect, useState } from 'react';
import Randomstring from 'randomstring';
import { Checkbox, Datepicker, Flowbite, Spinner } from 'flowbite-react';
import { dateTheme } from '../../utils/dateTheme';
import moment from 'moment';
import { X } from 'react-feather';
import { useEditDiscountMutation } from '../../api/productsApiSlice';
import { showNotification } from '../../redux/toastSlice';
import { useDispatch } from 'react-redux';

const EditDiscount = ({
  discountId,
  code,
  percent,
  amount,
  max,
  limit,
  expDate,
  editDis,
  setEditDis,
  refetch,
  handleClearDis,
  editDisActive,
  setEditDisActive,
}) => {
  const dispatch = useDispatch();

  const [editPercent, setEditPercent] = useState(percent);
  const [editCode, setEditCode] = useState(code);
  const [editAmount, setEditAmount] = useState(amount);
  const [editLimit, setEditLimit] = useState(limit);
  const [editMax, setEditMax] = useState(max);
  const [editExpDate, setEditExpDate] = useState(expDate);
  const [exp, setExp] = useState(false);
  const isExpDate = editExpDate ? true : false;

  //date picker min date
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const [editDiscount, { isLoading }] = useEditDiscountMutation();

  const handleGenerateCode = () => {
    const code = Randomstring.generate(5);
    setEditCode(code);
  };

  const handleClearDate = () => {
    setEditExpDate('');
    setExp(false);
  };

  const handleCancel = () => {
    handleClearDis();
    setEditDis(!editDis);
    setEditDisActive(!editDisActive);
  };

  const handleSaveDiscount = async (e) => {
    e.preventDefault();

    //just date string
    const justDate = editExpDate ? new Date(editExpDate).toDateString() : '';

    try {
      const req = await editDiscount({
        discountId: discountId,
        code: editCode,
        percent: editPercent,
        limit: editLimit,
        maxLimit: editLimit ? editMax : 0,
        amount: editAmount,
        expDate: justDate,
      }).unwrap();

      if (req === 'Discount edited') {
        dispatch(showNotification('Discount updated'));
        refetch();
        handleClearDis();
        setEditDis(!editDis);
        setEditDisActive(!editDisActive);
      }
    } catch (err) {
      return;
    }
  };

  let content;

  content = (
    <div className="flex flex-col w-full">
      <div className="flex items-end justify-between w-full mb-6">
        <div className="flex flex-col items-start">
          <p className="text-sm text-stone-800">Edit discount</p>
          <p className="text-xs text-stone-600">
            Editing an existing discount on this item
          </p>
        </div>

        <div className="flex items-center gap-2">
          <button
            type="button"
            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleSaveDiscount}
          >
            Save
          </button>
        </div>
      </div>
      <div className="w-full flex items-start gap-2">
        <form className="flex flex-col gap-2 w-6/12">
          <div className="flex flex-col gap-1">
            <p className="text-stone-600 text-xs">Code</p>
            <div className="flex items-center gap-1">
              <input
                type="text"
                className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                placeholder="Enter code OR generate one"
                onChange={(e) => setEditCode(e.target.value)}
                value={editCode}
              />
              <button
                type="button"
                onClick={handleGenerateCode}
                className="p-2 bg-gray-200 text-stone-800 rounded-md text-xs"
              >
                Generate
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-stone-600 text-xs">Type</p>
            <div className="flex items-center gap-1 justify-between">
              <div className="flex items-center gap-1 w-3/6">
                <button
                  type="button"
                  className={`border border-gray-200 ${
                    editPercent ? 'bg-gray-200' : 'bg-white'
                  } text-stone-800 rounded-md p-2 w-3/6 text-xs`}
                  onClick={() => setEditPercent(true)}
                >
                  Percent
                </button>
                <button
                  type="button"
                  className={`border border-gray-200 ${
                    !editPercent ? 'bg-gray-200' : 'bg-white'
                  } text-stone-800 rounded-md p-2 w-3/6 text-xs`}
                  onClick={() => setEditPercent(false)}
                >
                  Fixed
                </button>
              </div>
              {editPercent ? (
                <div className="flex w-3/6">
                  <input
                    type="number"
                    onChange={(e) => setEditAmount(e.target.value)}
                    value={editAmount}
                    placeholder="Percent"
                    className="border text-xs border-r-0 border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tl-md rounded-bl-md p-2 pl-1 flex-1"
                  />
                  <div
                    className="rounded-tr-md rounded-br-md bg-gray-50 border border-l-0 border-gray-200
      flex items-center p-2"
                  >
                    <p className="text-xs font-bold">%</p>
                  </div>
                </div>
              ) : (
                <div className="flex w-3/6">
                  <div
                    className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200
       flex items-center p-2"
                  >
                    <p className="text-xs font-bold">$</p>
                  </div>
                  <input
                    type="number"
                    onChange={(e) => setEditAmount(e.target.value)}
                    value={editAmount}
                    placeholder="Amount"
                    className="border text-xs border-l-0 border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={(e) => setEditLimit(e.target.checked)}
                checked={editLimit}
              />
              <p className="text-xs text-stone-600">Limit number of uses</p>
            </div>
            {editLimit && (
              <div className="flex flex-col gap-1">
                <p className="text-stone-600 text-xs">Max limit</p>
                <input
                  type="number"
                  className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                  placeholder="Max limit"
                  onChange={(e) => setEditMax(e.target.value)}
                  value={editMax}
                />
              </div>
            )}
          </div>
        </form>
        <div className="flex flex-col items-center justify-center w-6/12">
          {exp || isExpDate ? (
            <>
              {isExpDate ? (
                <div className="flex flex-col gap-1 w-full">
                  <p className="text-xs text-stone-600">Expiration date</p>
                  <input
                    type="text"
                    disabled
                    className={`border border-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1`}
                    value={moment(editExpDate).format('MMMM D YYYY')}
                  />
                  <button
                    type="button"
                    onClick={handleClearDate}
                    className="bg-gray-200 w-full rounded-md text-xs text-stone-800 p-1"
                  >
                    Remove date
                  </button>
                </div>
              ) : (
                <div className="flex flex-col w-full gap-1">
                  <div className="flex items-center justify-between">
                    <p className="text-xs text-stone-600">
                      Set expiration date
                    </p>
                    <X
                      size={16}
                      className="text-red-400 cursor-pointer"
                      onClick={handleClearDate}
                    />
                  </div>
                  <Datepicker
                    onSelectedDateChanged={(date) => setEditExpDate(date)}
                    theme={dateTheme}
                    // minDate={tomorrow}
                    inline
                  />
                </div>
              )}
            </>
          ) : (
            <div className="rounded-md border border-gray-200 text-xs text-stone-800 p-2 flex flex-col gap-2 items-center justify-center w-full">
              <p className="text-xs text-stone-800">Expiration</p>
              <p className="text-xs text-stone-600 text-center w-72">
                Set a date for when this discount code should expire and no
                longer be valid
              </p>
              <button
                type="button"
                onClick={() => setExp(!exp)}
                className="bg-gray-200 text-xs text-stone-800 rounded-md p-2"
              >
                Set date
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return content;
};

export default EditDiscount;
