import React, { useState, useMemo } from 'react';
import countryList from 'react-select-country-list';
import ReactCountryFlag from 'react-country-flag';
import {
  Instagram,
  Lock,
  Youtube,
  Link as FLink,
  Edit,
  Clipboard,
  Check,
} from 'react-feather';
import EditAccount from './EditAccount';
import { Spinner } from 'flowbite-react';
import EditProfile from './EditProfile';
import { FaMediumM, FaTiktok } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

//mui
import Avatar from '@mui/material/Avatar';
import { FaXTwitter } from 'react-icons/fa6';
import DeleteAccount from './DeleteAccount';
import PasswordChange from './PasswordChange';

const Profile = ({ user, refetch, isFetching }) => {
  const [country, setCountry] = useState(user?.country);
  const [zip, setZip] = useState(user?.zipcode);
  const [email, setEmail] = useState(user?.email);

  const [edit, setEdit] = useState(false);
  const [editPro, setEditPro] = useState(false);
  const [deleteAcc, setDeleteAcc] = useState(false);
  const [change, setChange] = useState(false);

  const [copied, setCopied] = useState(false);

  const options = useMemo(() => countryList().getData(), []);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(user?._id)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
        console.error('Failed to copy!', err);
      });
  };

  return isFetching ? (
    <div className="w-full h-96 flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="w-full flex flex-col items-start gap-8 border border-gray-200 rounded-md bg-white p-4">
      {change ? (
        <PasswordChange setChange={setChange} change={change} />
      ) : (
        <>
          {editPro ? (
            ''
          ) : (
            <div className="flex flex-col items-start w-full">
              {edit ? (
                <>
                  <EditAccount
                    user={user}
                    edit={edit}
                    setEdit={setEdit}
                    refetch={refetch}
                    change={change}
                    setChange={setChange}
                  />
                </>
              ) : (
                <>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-col items-start gap-1">
                      {user?.googleAuth ? (
                        <div className="flex items-end gap-2">
                          <p className="text-sm text-stone-800">Account</p>
                          <div className="border border-gray-200 rounded-md flex items-center justify-center gap-2 p-1">
                            <p className="text-xs text-stone-800">
                              Authenticated using
                            </p>{' '}
                            <FcGoogle className="text-lg" />
                          </div>
                          {copied && (
                            <div className="flex items-center justify-start gap-2 border border-gray-200 rounded-md p-1 pr-2 pl-2">
                              <Check size={16} className="text-green-400" />
                              <p className="text-stone-800 text-xs">
                                Copied to clipboard
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex items-end gap-2">
                          <p className="text-sm text-stone-800">Account</p>
                          {copied && (
                            <div className="flex items-center justify-start gap-2 border border-gray-200 rounded-md p-1 pr-2 pl-2">
                              <Check size={16} className="text-green-400" />
                              <p className="text-stone-800 text-xs">
                                Copied to clipboard
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      <div className="flex gap-1">
                        <p className="text-xs text-stone-600">{`ID: ${user?._id}`}</p>
                        <Clipboard
                          size={14}
                          className="text-stone-800 hover:cursor-pointer"
                          onClick={copyToClipboard}
                        />
                      </div>
                    </div>

                    <button
                      type="button"
                      className="text-stone-800"
                      onClick={(e) => setEdit(!edit)}
                    >
                      <Edit size={14} />
                    </button>
                  </div>

                  <div className="flex items-start w-full gap-2 mt-2">
                    <div className="flex flex-col items-start w-full gap-2">
                      <input
                        type="text"
                        value={user?.email}
                        className="w-full bg-gray-50 rounded-md text-xs border-gray-50"
                        disabled
                      />
                      <button
                        type="button"
                        className="flex items-center justify-center rounded-md p-1 pl-2 pr-2 hover:bg-gray-200 text-stone-800 text-xs"
                        onClick={() => setChange(!change)}
                      >
                        Change Password
                        <Lock className="ml-1" size={13} />
                      </button>
                    </div>

                    <div className="flex flex-col w-full gap-2">
                      <div className="flex w-full">
                        <input
                          type="text"
                          className="border text-xs bg-gray-50 border-gray-50 rounded-tl-md rounded-bl-md p-2 flex-1"
                          value={`${user?.country?.label} - ${user?.currency?.code}(${user?.currency?.symbol})`}
                          disabled
                        />
                        <div className="rounded-tr-md rounded-br-md bg-gray-50 border border-l-0 border-gray-50 flex items-center justify-center p-1 pr-2">
                          <ReactCountryFlag
                            countryCode={user?.country?.value}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        value={user?.timezone}
                        className="w-full bg-gray-50 rounded-md text-xs border-gray-50"
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {edit ? (
            ''
          ) : (
            <div className="flex flex-col items-start w-full">
              {editPro ? (
                <EditProfile
                  user={user}
                  refetch={refetch}
                  setEditPro={setEditPro}
                  editPro={editPro}
                />
              ) : (
                <>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm text-stone-800">Profile</p>
                      <p className="text-xs text-stone-600">
                        This is visible on your public page
                      </p>
                    </div>

                    <button
                      type="button"
                      className="text-stone-800"
                      onClick={(e) => setEditPro(!editPro)}
                    >
                      <Edit size={14} />
                    </button>
                  </div>

                  <div className="w-full flex gap-2 mt-2">
                    <div className="flex flex-col w-3/6">
                      <input
                        type="text"
                        value={user?.name}
                        className="w-full bg-gray-50 rounded-md text-xs border-gray-50"
                        disabled
                      />
                      <div className="w-full flex items-center gap-2 mt-4">
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          src={user?.sellerProfile?.picture?.url}
                        />
                        <div className="flex items-center gap-4">
                          {user?.sellerProfile?.twitter ? (
                            <a
                              href={user?.sellerProfile?.twitter}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FaXTwitter className="text-stone-800 text-md" />
                            </a>
                          ) : (
                            <FaXTwitter className="text-gray-200 text-md" />
                          )}

                          {user?.sellerProfile?.instagram ? (
                            <a
                              href={user?.sellerProfile?.instagram}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Instagram size={17} className="text-stone-800" />
                            </a>
                          ) : (
                            <Instagram size={17} className="text-gray-200" />
                          )}

                          {user?.sellerProfile?.youtube ? (
                            <a
                              href={user?.sellerProfile?.youtube}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Youtube size={17} className="text-stone-800" />
                            </a>
                          ) : (
                            <Youtube size={17} className="text-gray-200" />
                          )}

                          {user?.sellerProfile?.medium ? (
                            <a
                              href={user?.sellerProfile?.medium}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FaMediumM className="text-stone-800 text-md" />
                            </a>
                          ) : (
                            <FaMediumM className="text-gray-200 text-md" />
                          )}

                          {user?.sellerProfile?.link ? (
                            <a
                              href={user?.sellerProfile?.tiktok}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FLink size={17} className="text-stone-800" />
                            </a>
                          ) : (
                            <FLink size={17} className="text-gray-200" />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="w-3/6">
                      <textarea
                        placeholder="Bio"
                        className="w-full h-18 bg-gray-50 border-gray-50 rounded-md text-xs resize-none"
                        value={user?.sellerProfile?.bio}
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {editPro || edit ? (
            ''
          ) : (
            <div className="flex flex-col">
              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col items-start">
                  <p className="text-sm text-stone-800">Delete account</p>
                  <p className="text-xs text-stone-600">
                    Deleting your account will remove all data from our servers
                  </p>
                  {deleteAcc ? (
                    <DeleteAccount
                      setDeleteAcc={setDeleteAcc}
                      deleteAcc={deleteAcc}
                      user={user}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => setDeleteAcc(!deleteAcc)}
                      className="text-stone-800 bg-red-200 rounded-md p-1 pl-2 pr-2 text-xs mt-2"
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Profile;
