export const availCurrencies = [
  { value: 'ALL', label: 'ALL(L)' }, // Albania
  { value: 'DZD', label: 'DZD(دج)' }, // Algeria
  { value: 'AOA', label: 'AOA(Kz)' }, // Angola
  { value: 'XCD', label: 'XCD($)' }, // Antigua & Barbuda, St. Lucia
  { value: 'ARS', label: 'ARS($)' }, // Argentina
  { value: 'AMD', label: 'AMD(֏)' }, // Armenia
  { value: 'AUD', label: 'AUD($)' }, // Australia
  { value: 'EUR', label: 'EUR(€)' }, // Austria, Belgium, Estonia, Finland, France, Germany, Greece, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Monaco, Netherlands, Portugal, San Marino, Slovakia, Slovenia, Spain
  { value: 'AZN', label: 'AZN(₼)' }, // Azerbaijan
  { value: 'BSD', label: 'BSD($)' }, // Bahamas
  { value: 'BHD', label: 'BHD(.د.ب)' }, // Bahrain
  { value: 'BDT', label: 'BDT(৳)' }, // Bangladesh
  { value: 'XOF', label: 'XOF(CFA)' }, // Benin, Côte d’Ivoire, Niger, Senegal
  { value: 'BTN', label: 'BTN(Nu.)' }, // Bhutan
  { value: 'BOB', label: 'BOB(Bs.)' }, // Bolivia
  { value: 'BAM', label: 'BAM(KM)' }, // Bosnia & Herzegovina
  { value: 'BWP', label: 'BWP(P)' }, // Botswana
  { value: 'BND', label: 'BND($)' }, // Brunei
  { value: 'BGN', label: 'BGN(лв)' }, // Bulgaria
  { value: 'KHR', label: 'KHR(៛)' }, // Cambodia
  { value: 'CAD', label: 'CAD($)' }, // Canada
  { value: 'CLP', label: 'CLP($)' }, // Chile
  { value: 'COP', label: 'COP($)' }, // Colombia
  { value: 'CRC', label: 'CRC(₡)' }, // Costa Rica
  { value: 'HRK', label: 'HRK(kn)' }, // Croatia
  { value: 'CZK', label: 'CZK(Kč)' }, // Czech Republic
  { value: 'DKK', label: 'DKK(kr)' }, // Denmark
  { value: 'DOP', label: 'DOP(RD$)' }, // Dominican Republic
  { value: 'USD', label: 'USD($)' }, // Ecuador, El Salvador
  { value: 'EGP', label: 'EGP(E£)' }, // Egypt
  { value: 'ETB', label: 'ETB(Br)' }, // Ethiopia
  { value: 'XAF', label: 'XAF(CFA)' }, // Gabon
  { value: 'GMD', label: 'GMD(D)' }, // Gambia
  { value: 'GHS', label: 'GHS(₵)' }, // Ghana
  { value: 'GTQ', label: 'GTQ(Q)' }, // Guatemala
  { value: 'GYD', label: 'GYD($)' }, // Guyana
  { value: 'HKD', label: 'HKD($)' }, // Hong Kong
  { value: 'HUF', label: 'HUF(Ft)' }, // Hungary
  { value: 'ISK', label: 'ISK(kr)' }, // Iceland
  { value: 'INR', label: 'INR(₹)' }, // India
  { value: 'IDR', label: 'IDR(Rp)' }, // Indonesia
  { value: 'ILS', label: 'ILS(₪)' }, // Israel
  { value: 'JMD', label: 'JMD(J$)' }, // Jamaica
  { value: 'JPY', label: 'JPY(¥)' }, // Japan
  { value: 'JOD', label: 'JOD(د.ا)' }, // Jordan
  { value: 'KZT', label: 'KZT(₸)' }, // Kazakhstan
  { value: 'KES', label: 'KES(Sh)' }, // Kenya
  { value: 'KWD', label: 'KWD(د.ك)' }, // Kuwait
  { value: 'LAK', label: 'LAK(₭)' }, // Laos
  { value: 'CHF', label: 'CHF(CHF)' }, // Liechtenstein, Switzerland
  { value: 'MOP', label: 'MOP(P)' }, // Macao SAR China
  { value: 'MGA', label: 'MGA(Ar)' }, // Madagascar
  { value: 'MYR', label: 'MYR(RM)' }, // Malaysia
  { value: 'MUR', label: 'MUR(₨)' }, // Mauritius
  { value: 'MXN', label: 'MXN($)' }, // Mexico
  { value: 'MDL', label: 'MDL(L)' }, // Moldova
  { value: 'MNT', label: 'MNT(₮)' }, // Mongolia
  { value: 'MAD', label: 'MAD(د.م.)' }, // Morocco
  { value: 'MZN', label: 'MZN(MT)' }, // Mozambique
  { value: 'NAD', label: 'NAD($)' }, // Namibia
  { value: 'NZD', label: 'NZD($)' }, // New Zealand
  { value: 'NGN', label: 'NGN(₦)' }, // Nigeria
  { value: 'MKD', label: 'MKD(ден)' }, // North Macedonia
  { value: 'NOK', label: 'NOK(kr)' }, // Norway
  { value: 'OMR', label: 'OMR(ر.ع.)' }, // Oman
  { value: 'PKR', label: 'PKR(₨)' }, // Pakistan
  { value: 'PAB', label: 'PAB(B/.)' }, // Panama
  { value: 'PYG', label: 'PYG(₲)' }, // Paraguay
  { value: 'PEN', label: 'PEN(S/.)' }, // Peru
  { value: 'PHP', label: 'PHP(₱)' }, // Philippines
  { value: 'PLN', label: 'PLN(zł)' }, // Poland
  { value: 'QAR', label: 'QAR(ر.ق)' }, // Qatar
  { value: 'RON', label: 'RON(lei)' }, // Romania
  { value: 'RWF', label: 'RWF(FRw)' }, // Rwanda
  { value: 'SAR', label: 'SAR(ر.س)' }, // Saudi Arabia
  { value: 'RSD', label: 'RSD(дин.)' }, // Serbia
  { value: 'SGD', label: 'SGD($)' }, // Singapore
  { value: 'ZAR', label: 'ZAR(R)' }, // South Africa
  { value: 'KRW', label: 'KRW(₩)' }, // South Korea
  { value: 'LKR', label: 'LKR(₨)' }, // Sri Lanka
  { value: 'SEK', label: 'SEK(kr)' }, // Sweden
  { value: 'TWD', label: 'TWD(NT$)' }, // Taiwan
  { value: 'TZS', label: 'TZS(Sh)' }, // Tanzania
  { value: 'THB', label: 'THB(฿)' }, // Thailand
  { value: 'TTD', label: 'TTD(TT$)' }, // Trinidad & Tobago
  { value: 'TND', label: 'TND(د.ت)' }, // Tunisia
  { value: 'TRY', label: 'TRY(₺)' }, // Turkey
  { value: 'AED', label: 'AED(د.إ)' }, // United Arab Emirates
  { value: 'GBP', label: 'GBP(£)' }, // United Kingdom
  { value: 'UYU', label: 'UYU($)' }, // Uruguay
  { value: 'UZS', label: 'UZS(лв)' }, // Uzbekistan
  { value: 'VND', label: 'VND(₫)' }, // Vietnam
];
