import React, { useEffect, useState } from 'react';
import {
  useAddDiscountMutation,
  useGetProductDiscountsQuery,
  useDeleteDiscountMutation,
} from '../../api/productsApiSlice';
import { Edit, Plus, Trash, X } from 'react-feather';
import Randomstring from 'randomstring';
import { Checkbox, Datepicker, Flowbite, Spinner } from 'flowbite-react';
import { dateTheme } from '../../utils/dateTheme';
import moment from 'moment';
import EditDiscount from './EditDiscount';
import { showNotification } from '../../redux/toastSlice';
import { useDispatch } from 'react-redux';

const Discounts = ({ productId, editDisActive, setEditDisActive }) => {
  const dispatch = useDispatch();

  const [add, setAdd] = useState(false);
  const [editDis, setEditDis] = useState(false);
  const [percent, setPercent] = useState(true);
  const [code, setCode] = useState('');
  const [amount, setAmount] = useState(0);
  const [limit, setLimit] = useState(false);
  const [max, setMax] = useState(0);
  const [exp, setExp] = useState(false);
  const [expDate, setExpDate] = useState('');
  const [discountId, setDiscountId] = useState('');
  const [error, setError] = useState('');
  //date picker min date
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const {
    data: discounts,
    isLoading: gettingDiscounts,
    isSuccess,
    refetch,
  } = useGetProductDiscountsQuery({ productId: productId });
  const [addDiscount, { isLoading }] = useAddDiscountMutation();
  const [deleteDiscount, { isLoading: deletingDis }] =
    useDeleteDiscountMutation();

  const handleAddDiscount = async (e) => {
    e.preventDefault();

    if (!code || !amount) {
      setError(`Missing discount code or ${percent ? 'percentage' : 'amount'}`);
      return;
    }

    const parsedAmount = parseFloat(amount);
    if (!Number.isInteger(parsedAmount)) {
      setError(
        `Please enter whole number for ${percent ? 'percentage' : 'amount'}`
      );
      return;
    }

    //just date string
    const justDate = expDate ? new Date(expDate).toDateString() : '';

    try {
      const req = await addDiscount({
        productId: productId,
        code: code,
        percent: percent,
        limit: limit,
        maxLimit: limit ? max : 0,
        amount: amount,
        expDate: justDate,
      }).unwrap();

      if (req === 'Discount created') {
        setAmount(0);
        setMax(0);
        setCode('');
        setExp(false);
        setPercent(true);
        setExpDate('');
        setAdd(!add);
        setEditDisActive(!editDisActive);
        setError('');
        dispatch(showNotification('Discount created'));
        refetch();
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const handleDeleteDiscount = async (e, discountId) => {
    e.preventDefault();

    try {
      const req = await deleteDiscount({
        discountId: discountId,
      }).unwrap();

      if (req === 'Discount deleted') {
        dispatch(showNotification('Discount deleted'));
        refetch();
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const handleSetEdit = (discount) => {
    setCode(discount?.code);
    setPercent(discount?.percent);
    setAmount(discount?.amount);
    setExpDate(discount?.expDate);
    setLimit(discount?.limit);
    setMax(discount?.maxLimit);
    setDiscountId(discount?._id);
    setEditDis(true);
    setEditDisActive(!editDisActive);
  };

  const handleGenerateCode = () => {
    const code = Randomstring.generate(5);
    setCode(code);
  };

  const handleCancel = () => {
    setAmount(0);
    setMax(0);
    setCode('');
    setExp(false);
    setPercent(true);
    setLimit(false);
    setExpDate('');
    setAdd(!add);
    setEditDisActive(!editDisActive);
  };

  const handleClearDis = () => {
    setAmount(0);
    setMax(0);
    setCode('');
    setExp(false);
    setLimit(false);
    setPercent(true);
    setExpDate('');
  };

  const handleClearDate = () => {
    setExpDate('');
    setExp(false);
  };

  const handleCreateDis = () => {
    setAdd(!add);
    setEditDisActive(!editDisActive);
  };

  useEffect(() => {
    setError('');
  }, [code, amount]);

  let content;

  if (isSuccess) {
    content = (
      <div className="flex flex-col border border-gray-200 w-full rounded-md p-4">
        {editDis ? (
          <EditDiscount
            productId={productId}
            code={code}
            amount={amount}
            percent={percent}
            limit={limit}
            max={max}
            expDate={expDate}
            editDis={editDis}
            setEditDis={setEditDis}
            discountId={discountId}
            refetch={refetch}
            handleClearDis={handleClearDis}
            setEditDisActive={setEditDisActive}
            editDisActive={editDisActive}
          />
        ) : (
          <>
            {add ? (
              <div className="flex items-end justify-between w-full mb-6">
                <div className="flex flex-col items-start">
                  <p className="text-sm text-stone-800">Create discount</p>
                  <p className="text-xs text-stone-600">
                    Creating new discount for this item
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    type="button"
                    className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                    onClick={handleAddDiscount}
                  >
                    Create
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
            {add ? (
              <div className="w-full flex items-start gap-2">
                <form className="flex flex-col gap-2 w-6/12">
                  {error && (
                    <div className="border border-gray-200 rounded-md p-2">
                      <p className="text-stone-800 text-xs flex items-center gap-1">
                        <X size={14} className="text-red-400" />
                        {error}
                      </p>
                    </div>
                  )}
                  <div className="flex flex-col gap-1">
                    <p className="text-stone-600 text-xs">Code</p>
                    <div className="flex items-center gap-1">
                      <input
                        type="text"
                        className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder="Enter code OR generate one"
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                      />
                      <button
                        type="button"
                        onClick={handleGenerateCode}
                        className="p-2 bg-gray-200 text-stone-800 rounded-md text-xs"
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-stone-600 text-xs">Type</p>
                    <div className="flex items-center gap-1 justify-between">
                      <div className="flex items-center gap-1 w-3/6">
                        <button
                          type="button"
                          className={`border border-gray-200 ${
                            percent ? 'bg-gray-200' : 'bg-white'
                          } text-stone-800 rounded-md p-2 w-3/6 text-xs`}
                          onClick={() => setPercent(true)}
                        >
                          Percent
                        </button>
                        <button
                          type="button"
                          className={`border border-gray-200 ${
                            !percent ? 'bg-gray-200' : 'bg-white'
                          } text-stone-800 rounded-md p-2 w-3/6 text-xs`}
                          onClick={() => setPercent(false)}
                        >
                          Fixed
                        </button>
                      </div>
                      {percent ? (
                        <div className="flex w-3/6">
                          <input
                            type="number"
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Percent"
                            className="border text-xs border-r-0 border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tl-md rounded-bl-md p-2 pl-1 flex-1"
                          />
                          <div
                            className="rounded-tr-md rounded-br-md bg-gray-50 border border-l-0 border-gray-200
                    flex items-center p-2"
                          >
                            <p className="text-xs font-bold">%</p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex w-3/6">
                          <div
                            className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200
                     flex items-center p-2"
                          >
                            <p className="text-xs font-bold">$</p>
                          </div>
                          <input
                            type="number"
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Amount"
                            className="border text-xs border-l-0 border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                      <Checkbox
                        onChange={(e) => setLimit(e.target.checked)}
                        checked={limit}
                      />
                      <p className="text-xs text-stone-600">
                        Limit number of uses
                      </p>
                    </div>
                    {limit && (
                      <div className="flex flex-col gap-1">
                        <p className="text-stone-600 text-xs">Max limit</p>
                        <input
                          type="number"
                          className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                          placeholder="Max limit"
                          onChange={(e) => setMax(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </form>
                <div className="flex flex-col items-center justify-center w-6/12">
                  {exp ? (
                    <div className="flex flex-col w-full gap-1">
                      <div className="flex items-center justify-between">
                        <p className="text-xs text-stone-600">
                          Expiration date
                        </p>
                        <X
                          size={16}
                          className="text-red-400 cursor-pointer"
                          onClick={handleClearDate}
                        />
                      </div>
                      <Datepicker
                        onSelectedDateChanged={(date) => setExpDate(date)}
                        theme={dateTheme}
                        minDate={tomorrow}
                        inline
                      />
                    </div>
                  ) : (
                    <div className="rounded-md border border-gray-200 text-xs text-stone-800 p-2 flex flex-col gap-2 items-center justify-center w-full">
                      <p className="text-xs text-stone-800">Set Expiration</p>
                      <p className="text-xs text-stone-600 text-center w-72">
                        Set a date for when this discount code should expire and
                        no longer be valid
                      </p>
                      <button
                        type="button"
                        onClick={() => setExp(!exp)}
                        className="bg-gray-200 text-xs text-stone-800 rounded-md p-2"
                      >
                        Set date
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {discounts.length ? (
                  <div className="grid grid-cols-5 gap-2">
                    {discounts.map((discount) => (
                      <div className="flex flex-col border border-gray-200 rounded-md relative">
                        <div className="absolute right-0 mr-2 mt-2">
                          <p className="text-stone-600 text-xs">
                            {discount?.numberOfUses === 1
                              ? '1 use'
                              : `${discount?.numberOfUses} uses`}
                          </p>
                        </div>
                        <div className="flex flex-col gap-2 p-2">
                          <div className="flex flex-col">
                            <p className="text-stone-600 text-xs">Offer</p>
                            {discount?.percent ? (
                              <p className="text-stone-800 text-xs">
                                {discount?.amount}% off
                              </p>
                            ) : (
                              <p className="text-stone-800 text-xs">
                                ${discount?.amount} off
                              </p>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <p className="text-stone-600 text-xs">Expires</p>
                            <p className="text-stone-800 text-xs">
                              {discount?.expDate
                                ? moment(discount?.expDate).format(
                                    'MMMM D YYYY'
                                  )
                                : 'Never'}
                            </p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-stone-600 text-xs">Limit</p>
                            <p className="text-stone-800 text-xs">
                              {discount?.limit
                                ? `${discount?.maxLimit} ${
                                    discount?.maxLimit === 1 ? 'use' : 'uses'
                                  }`
                                : 'Unlimited'}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between border-t border-gray-200 p-2">
                          <p className="text-stone-800 text-xs">
                            {discount?.code}
                          </p>
                          <div className="flex items-center gap-4">
                            <button
                              type="button"
                              disabled={deletingDis}
                              onClick={(e) =>
                                handleDeleteDiscount(e, discount?._id)
                              }
                            >
                              <Trash size={14} className="text-red-400" />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleSetEdit(discount)}
                            >
                              <Edit size={14} className="text-stone-800" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div
                      className="border border-gray-200 rounded-md flex items-center justify-center hover:cursor-pointer bg-gray-50 p-2"
                      onClick={handleCreateDis}
                    >
                      <p className="text-xs text-stone-800 flex items-center gap-1">
                        Create <Plus size={14} />
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full">
                    <div className="flex flex-col items-center">
                      <p className="text-stone-800 text-sm">Discounts</p>
                      <p className="text-stone-600 text-xs text-center w-72">
                        Attract more customers by creating and offering
                        discounts on your item
                      </p>
                    </div>
                    <button
                      type="button"
                      className="flex items-center justify-center bg-gray-200 text-xs text-stone-800 rounded-md pt-1 pb-1 pl-2 pr-2 mt-2"
                      onClick={handleCreateDis}
                    >
                      Create Discount
                    </button>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  } else if (gettingDiscounts) {
    content = (
      <div className="w-full h-96 flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return content;
};

export default Discounts;
