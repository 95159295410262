import React from 'react';
import { Link } from 'react-router-dom';
import { FaXTwitter } from 'react-icons/fa6';
import { isMobile } from 'react-device-detect';
import MobileFooter from './MobileFooter';
import Cookies from 'js-cookie';
import { Map, Youtube } from 'react-feather';

const Footer = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  return isMobile ? (
    <MobileFooter />
  ) : (
    <footer className="w-full h-16 flex mt-24">
      {currentUser ? (
        <div className="w-full mx-auto">
          <div className="max-w-6xl mx-auto h-full flex justify-between items-center">
            <div className="h-full flex justify-center items-center">
              {/* <Link to='/' className='h-full flex justify-center items-center'>
                <IoStorefrontOutline className='text-stone-800 text-xl font-bold' />
                <p
                  className='text-md text-stone-800 mt-2 font-black'
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link> */}
              <Link
                to="/dashboard"
                className="h-full flex gap-1 justify-center items-center"
              >
                {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
                <Map size={18} />
                <p
                  className="font-black text-stone-800 text-md"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link>
            </div>

            <div className="flex items-center gap-10">
              <Link to="/contact" className="text-stone-600 text-xs">
                Contact
              </Link>
              <Link to="/privacy" className="text-stone-600 text-xs">
                Privacy
              </Link>
              <Link to="/tos" className="text-stone-600 text-xs">
                Terms
              </Link>
            </div>

            <div className="flex w-16 justify-between text-4xl">
              <a
                href="https://www.youtube.com/channel/UCkcusfEUbfJ09dDuKYJFRHQ"
                target="_blank"
              >
                <Youtube size={20} className="text-stone-800" />
              </a>

              <a href="https://twitter.com/fruntt_" target="_blank">
                <FaXTwitter className="text-xl text-stone-800" />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="max-w-6xl mx-auto h-full flex justify-between items-center">
            <div className="h-full flex justify-center items-center">
              {/* <Link to='/' className='h-full flex justify-center items-center'>
                <IoStorefrontOutline className='text-stone-800 font-bold text-xl' />
                <p
                  className='text-stone-800 font-black text-md mt-1'
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link> */}
              <Link
                to="/"
                className="h-full flex gap-1 justify-center items-center"
              >
                {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
                <Map size={18} />
                <p
                  className="font-black text-stone-800 text-md"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link>
            </div>

            <div className="flex items-center gap-10">
              <Link to="/contact" className="text-stone-600 text-xs">
                Contact
              </Link>
              <Link to="/privacy" className="text-stone-600 text-xs">
                Privacy
              </Link>
              <Link to="/tos" className="text-stone-600 text-xs">
                Terms
              </Link>
            </div>

            <div className="flex w-16 justify-between text-4xl">
              <a
                href="https://www.youtube.com/channel/UCkcusfEUbfJ09dDuKYJFRHQ"
                target="_blank"
              >
                <Youtube size={20} className="text-stone-800" />
              </a>

              <a href="https://twitter.com/fruntt_" target="_blank">
                <FaXTwitter className="text-xl text-stone-800" />
              </a>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
