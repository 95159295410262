import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';
import Alert from '@mui/material/Alert';

//filepond
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Checkbox, Spinner, Tooltip } from 'flowbite-react';
import { ChevronUp, ExternalLink, Info, Plus, Trash, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { framerCats, notionCats } from '../../../utils/itemCats';

const DesktopForm = ({
  handleAddProduct,
  setTitle,
  setPlatform,
  setDescription,
  description,
  title,
  setPrice,
  setImage,
  setPublished,
  published,
  image,
  setFiles,
  files,
  error,
  payChoice,
  setPayChoice,
  price,
  callToAction,
  info,
  setInfo,
  setSuggestedPrice,
  setUrl,
  addingProduct,
  setFree,
  free,
  handleType,
  handleAction,
  handleCategory,
  category,
  emptyFields,
  setDupLink,
  currentUser,
  handlePlatform,
  platform,
  versions,
  add,
  setAdd,
  handleAddVariation,
  handleRemoveVariation,
  setVersionPrice,
  setVersionSum,
  setVersionTitle,
  setLinkForIndex,
  urlTaken,
  url,
  errMsgs,
  dupLink,
}) => {
  const [addDescription, setAddDescription] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const detailErrs = errMsgs.filter((err) => err.type === 'detail');
  const contentErrs = errMsgs.filter((err) => err.type === 'content');
  const mediaErrs = errMsgs.filter((err) => err.type === 'media');

  const actionOptions = [
    { value: 'buy', label: 'Buy Now' },
    { value: 'want', label: 'I want this!' },
    { value: 'get', label: 'Get Now' },
  ];

  const defaultAction = { value: 'buy', label: 'Buy Now' };

  const defaultPlatform = { value: platform, label: platform };
  const platforms = [
    { value: 'Notion', label: 'Notion' },
    { value: 'Framer', label: 'Framer' },
  ];

  const handleFree = () => {
    setFree(!free);
    setPrice(0);
  };

  return (
    <div className="w-full flex flex-col items-start gap-2">
      {error && (
        <Alert severity="error" className="mb-2">
          {error}
        </Alert>
      )}
      <div className="flex items-center justify-between w-full border border-gray-200 rounded-md p-4">
        <div className="flex flex-col items-start">
          <p className="text-sm text-stone-800">Create New Item</p>
          <p className="text-xs text-stone-600">
            Create and offer a new item on your page
          </p>
        </div>
        {currentUser?.bankAdded || currentUser?.stripeOnboard ? (
          <Tooltip
            content={
              <p className="text-xs text-stone-800">
                Items are reviewed within 24 hours. Approval is only needed the
                first time you publish an item.
              </p>
            }
            style="light"
            className="w-72"
            arrow={false}
          >
            <div className="flex items-center gap-2">
              <Checkbox
                onChange={(e) => setPublished(e.target.checked)}
                checked={published}
              />
              <div className="flex flex-col">
                <p className="text-xs text-stone-800">Publish</p>
                <p className="text-xs text-stone-600">
                  Submit item for approval
                </p>
              </div>
            </div>
          </Tooltip>
        ) : (
          <div className="flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
            <Info size={16} className="text-red-500" />
            <p className="text-stone-800 text-xs">
              Enable payouts to publish item
            </p>
          </div>
        )}
        <div className="flex items-center gap-2">
          <Link
            to="/dashboard/items"
            className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
          >
            Cancel
          </Link>
          <button
            type="button"
            className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
            onClick={handleAddProduct}
            disabled={addingProduct}
          >
            Save
          </button>
        </div>
      </div>

      <div className="w-full flex items-start gap-2">
        {errMsgs.length ? (
          <>
            {detailErrs.length ? (
              <div className="flex flex-col border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs flex items-center gap-1">
                  Details
                  <X size={14} className="text-red-400" />
                </p>
                <div className="flex flex-col gap-1">
                  {detailErrs.map((err) => (
                    <p className="text-xs text-stone-600">{err.msg}</p>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {mediaErrs.length ? (
              <div className="flex flex-col border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs flex items-center gap-1">
                  Media
                  <X size={14} className="text-red-400" />
                </p>
                <div className="flex flex-col">
                  {mediaErrs.map((err) => (
                    <p className="text-xs text-stone-600">{err.msg}</p>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {contentErrs.length ? (
              <div className="flex flex-col border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs flex items-center gap-1">
                  Content
                  <X size={14} className="text-red-400" />
                </p>
                <div className="flex flex-col gap-1">
                  {contentErrs.map((err) => (
                    <p className="text-xs text-stone-600">{err.msg}</p>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {urlTaken && (
          <div className="border border-gray-200 rounded-md p-2">
            <p className="text-stone-800 text-xs flex items-center gap-1">
              <X size={14} className="text-red-400" />
              Item URL{' '}
              <span className="font-bold">
                /{currentUser?.store?.name}/{url}
              </span>{' '}
              is being used
            </p>
          </div>
        )}
      </div>

      {addingProduct ? (
        <div className="w-full rounded-md border border-gray-200 flex items-center justify-center h-96">
          <Spinner />
        </div>
      ) : (
        <Tabs className="w-full">
          <TabList>
            <Tab>Details</Tab>
            <Tab>Media</Tab>
            <Tab>Discounts</Tab>
            <Tab>Content</Tab>
          </TabList>

          <TabPanel>
            <form className="w-full flex flex-col gap-4 border border-gray-200 rounded-md bg-white p-4">
              <div className="flex items-start w-full gap-4">
                <div className="flex flex-col gap-4 w-6/12">
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Platform</p>
                    <Select
                      value={defaultPlatform}
                      options={platforms}
                      onChange={handlePlatform}
                      placeholder="Categories"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          fontSize: '12px',
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 99999,
                          position: 'relative',
                          height: 35,
                          minHeight: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="w-full">
                      <p className="text-stone-600 text-xs">Title</p>
                    </div>
                    <input
                      type="text"
                      className={`border ${
                        emptyFields.includes('field1')
                          ? 'border-red-300'
                          : 'border-gray-200'
                      } hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                      placeholder="Title"
                      onChange={(e) => setTitle(e.target.value)}
                      value={title}
                      maxLength={50}
                    />
                  </div>

                  <div className="flex flex-col w-full">
                    <div className="flex w-full justify-between">
                      <p className="text-stone-600 text-xs">
                        Summary (optional)
                      </p>
                    </div>
                    <div className="flex flex-col w-full relative">
                      <textarea
                        placeholder="Summary"
                        className="border border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 bg-gray-50 resize-none text-xs"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        maxLength={75}
                      />
                      <div className="absolute bottom-0 right-0 mr-2 mb-2">
                        <p className="text-xs text-stone-600">
                          {description.length}/75
                        </p>
                      </div>
                    </div>
                    {/* <textarea
                      type="text"
                      className="border border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2 bg-gray-50 resize-none text-xs"
                      placeholder="Summary"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      maxLength={75}
                    /> */}
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Categories</p>
                    <Select
                      options={platform === 'Notion' ? notionCats : framerCats}
                      onChange={handleCategory}
                      value={category}
                      isMulti
                      placeholder="Categories"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      isOptionDisabled={(option) => category.length >= 5}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          fontSize: '12px',
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 99999,
                          position: 'relative',
                        }),

                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">URL</p>
                    <div className="flex w-full">
                      <div
                        className={`rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 ${
                          urlTaken ? 'border-red-400' : 'border-gray-200'
                        } flex items-center p-2 pr-0.5`}
                      >
                        <p className="text-xs">
                          fruntt.com/{currentUser?.store?.name}/
                        </p>
                      </div>
                      <input
                        type="text"
                        placeholder="URL"
                        className={`border text-xs border-l-0 ${
                          urlTaken ? 'border-red-400' : 'border-gray-200'
                        } bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1`}
                        onChange={(e) => setUrl(e.target.value)}
                        value={url}
                      />
                    </div>
                    {urlTaken && (
                      <p className="text-xs text-stone-800">
                        This URL is taken
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-6/12 flex flex-col gap-4 items-start">
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Call to action</p>
                    <Select
                      options={actionOptions}
                      onChange={handleAction}
                      value={
                        Object.keys(callToAction).length
                          ? callToAction
                          : defaultAction
                      }
                      placeholder="Call to action"
                      menuPortalTarget={document.body}
                      menuPosition={'fixed'}
                      isSearchable={false}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'rgb(229 231 235)',
                          backgroundColor: 'rgb(249 250 251)',
                          borderWidth: 1,
                          '&:hover': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          '&:focus': {
                            backgroundColor: 'rgb(229 231 235)', // Keep the same border color on hover
                          },
                          fontSize: '12px',
                          borderRadius: '.375rem',
                          boxShadow: 'none',
                          zIndex: 999,
                          position: 'relative',
                          height: 35,
                          minHeight: 35,
                        }),
                        indicatorsContainer: (provided) => ({
                          ...provided,
                          height: 35,
                        }),
                        menuPortal: (provided) => ({
                          ...provided,
                          zIndex: 999,
                          fontSize: '12px',
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? 'rgb(229 231 235)'
                            : state.isFocused
                            ? 'rgb(249 250 251)'
                            : '',
                          color: 'black',
                        }),
                      }}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-col w-full">
                    <p className="text-stone-600 text-xs">Price</p>
                    <div className="flex w-full items-start">
                      <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
                        <p className="text-xs">
                          {currentUser?.currency?.symbol}
                        </p>
                      </div>
                      <input
                        type="number"
                        className={`border ${
                          emptyFields.includes('field4')
                            ? 'border-red-300'
                            : 'border-gray-200'
                        } hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-tr-md rounded-br-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder={payChoice ? '$9+' : '$9'}
                        value={price}
                        step={1}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-4 items-start">
                    {payChoice ? (
                      ''
                    ) : (
                      <div className="flex items-center gap-2">
                        <Checkbox onChange={handleFree} />
                        <p className="text-xs text-stone-600">Free item</p>
                      </div>
                    )}
                    {free ? (
                      ''
                    ) : (
                      <div className="flex items-center gap-2">
                        <Checkbox
                          onChange={(e) => setPayChoice(e.target.checked)}
                        />
                        <p className="text-xs text-stone-600">
                          Customer sets price
                        </p>
                      </div>
                    )}
                  </div>

                  {payChoice ? (
                    <div className="flex items-center w-full">
                      <div className="flex flex-col w-6/12">
                        <p className="text-stone-600 text-xs">Minimum</p>
                        <div className="flex w-full items-start">
                          <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
                            <p className="text-xs">
                              {currentUser?.currency?.symbol}
                            </p>
                          </div>
                          <input
                            type="number"
                            className={`border ${
                              emptyFields.includes('field4')
                                ? 'border-red-300'
                                : 'border-gray-200'
                            } hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-tr-md rounded-br-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                            placeholder={payChoice ? '$9+' : '$9'}
                            value={price}
                            step={1}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col w-6/12 ml-2">
                        <p className="text-stone-600 text-xs">Suggested</p>
                        <input
                          type="number"
                          className={`border ${
                            emptyFields.includes('field4')
                              ? 'border-red-300'
                              : 'border-gray-200'
                          } hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 focus:bg-gray-200 focus:border-gray-200`}
                          placeholder={payChoice ? '$9+' : '$9'}
                          step={1}
                          onChange={(e) => setSuggestedPrice(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {versions.length > 0 && (
                    <div className="flex flex-col gap-1 w-full">
                      <p className="text-xs text-stone-600">Variations</p>
                      {versions.map((version, index) => (
                        <div className="flex items-center w-full gap-2">
                          <div
                            className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200"
                            key={index}
                          >
                            <div className="flex flex-col">
                              <p className="text-xs text-stone-800">
                                {version?.title}
                              </p>
                              <p className="text-xs text-stone-600">
                                {version?.description}
                              </p>
                            </div>
                            <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center text-xs">
                              <p className="text-xs text-stone-800">
                                ${version?.price}
                              </p>
                            </div>
                          </div>
                          <X
                            size={18}
                            className="text-red-400 hover:cursor-pointer"
                            onClick={() => handleRemoveVariation(index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {add ? (
                    <div className="w-full flex flex-col gap-1 items-start">
                      <p className="text-xs text-stone-600">Adding variation</p>
                      <input
                        type="text"
                        className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder="Title"
                        onChange={(e) => setVersionTitle(e.target.value)}
                      />
                      <textarea
                        type="text"
                        className="border border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full h-12 rounded-md p-2 bg-gray-50 resize-none text-xs"
                        placeholder="Summary"
                        onChange={(e) => setVersionSum(e.target.value)}
                        maxLength={75}
                      />
                      <input
                        type="number"
                        className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                        placeholder="Price"
                        step={1}
                        onChange={(e) => setVersionPrice(e.target.value)}
                      />
                      <div className="flex items-center gap-2">
                        <button
                          type="button"
                          className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                          onClick={(e) => setAdd(!add)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                          onClick={handleAddVariation}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setAdd(!add)}
                      className="rounded-md bg-gray-50 hover:bg-gray-200 border border-gray-200 text-xs text-stone-800 p-2 flex flex-col items-center justify-center w-full"
                    >
                      <p className="text-xs text-stone-800 flex items-center gap-1">
                        Add Variation
                        <Plus size={12} />
                      </p>
                      <p className="text-xs text-stone-600">
                        Offer different variations of your item
                      </p>
                    </button>
                  )}
                </div>
              </div>

              {addDescription ? (
                <div className="flex flex-col gap-2 pb-10">
                  <div className="flex items-center justify-between w-full">
                    <p className="text-sm text-stone-800">Add description</p>

                    <div>
                      <button
                        type="button"
                        className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                        onClick={(e) => setAddDescription(!addDescription)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <ReactQuill
                    value={info}
                    onChange={setInfo}
                    className="h-80"
                    placeholder="Start typing description here..."
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center border border-gray-200 w-full rounded-md p-4">
                  <p className="text-stone-800 text-sm">Description</p>
                  <p className="text-stone-600 text-xs text-center w-96">
                    Tell potential customers more about your item. This is
                    visible on the item detail page and on orders after a
                    purchase.
                  </p>
                  <button
                    type="button"
                    className="flex items-center justify-center bg-gray-200 text-xs text-stone-800 rounded-md pt-1 pb-1 pl-2 pr-2 mt-2"
                    onClick={(e) => setAddDescription(!addDescription)}
                  >
                    Add Description
                  </button>
                </div>
              )}
            </form>
          </TabPanel>

          <TabPanel>
            <div className="border border-gray-200 rounded-md bg-white p-4 flex flex-col gap-4">
              <div className="flex flex-col">
                <p className="text-sm text-stone-800 text-center">
                  Upload Images
                </p>
                <p className="text-stone-600 text-center text-xs">
                  Click and drag to reorder images
                </p>
              </div>
              <FilePond
                files={image}
                // imageResizeTargetWidth={500}
                allowReorder
                name="productImages"
                // onupdatefiles={(file) => setImage(file)}
                instantUpload={false}
                allowMultiple
                maxFiles={5}
                onupdatefiles={(fileItems) => {
                  setImage(fileItems.map((fileItem) => fileItem.file));
                }}
                onreorderfiles={(fileItems) => {
                  setImage(fileItems.map((fileItem) => fileItem.file));
                }}
                labelIdle="Drag & drop or browse and reorder your images here"
              />
              <div className="w-full flex justify-between">
                <p className="text-stone-600 text-xs">{image.length}/5</p>
                <p className="text-stone-600 text-xs">
                  Recommended size 1280x720
                </p>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="flex items-center justify-center border border-gray-200 w-full rounded-md p-4 h-96">
              <div className="flex flex-col gap-2 items-center">
                <p className="text-stone-800 text-sm">
                  Discounts not available
                </p>
                <p className="text-stone-600 text-xs text-center w-72">
                  Creating and offering discounts is only available once your
                  item is approved. Publish your item to be approved.
                </p>
              </div>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="flex flex-col gap-4 items-start border border-gray-200 rounded-md w-full p-4 bg-white">
              <div className="flex flex-col justify-center bg-white">
                <p className="text-xs text-stone-800">
                  Add Project {versions.length ? 'Links' : 'Link'}
                </p>
                <p className="text-xs text-stone-600">
                  This is what the customer receives after a purchase
                </p>
              </div>

              <div className="flex flex-col w-full">
                <div className="flex flex-col">
                  <p className="text-stone-600 text-xs">
                    {platform === 'Notion' ? 'Duplicate' : 'Remix'} link
                  </p>
                </div>
                <input
                  type="text"
                  className={`border ${
                    emptyFields.includes('field1')
                      ? 'border-red-300'
                      : 'border-gray-200'
                  } hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                  placeholder={
                    platform === 'Notion'
                      ? 'https://carbonated-hill-6bc.notion.site/43be8a4d9'
                      : 'https://framer.com/projects/new?duplicate=xrop2yEDr5RWhR50BPJM'
                  }
                  onChange={(e) => setDupLink(e.target.value)}
                  value={dupLink}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                {versions.map((version, index) => (
                  <div className="flex flex-col w-full" key={index}>
                    <p className="text-xs text-stone-600">
                      {platform === 'Notion' ? 'Duplicate' : 'Remix'} link for
                      variation:{' '}
                      <span className="font-bold">{version?.title}</span>
                    </p>
                    <input
                      type="text"
                      className={`border border-gray-200 hover:border-gray-200 bg-gray-50 text-xs focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 w-full rounded-md p-2 outline outline-0`}
                      placeholder={
                        platform === 'Notion'
                          ? 'https://carbonated-hill-6bc.notion.site/43be8a4d9'
                          : 'https://framer.com/projects/new?duplicate=xrop2yEDr5RWhR50BPJM'
                      }
                      onChange={(e) =>
                        setLinkForIndex(index, e.target.value, version?.title)
                      }
                      value={version?.link ? version?.link : ''}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-col items-start w-full gap-2">
                <button
                  type="button"
                  onClick={(e) => setShowInfo(!showInfo)}
                  className="flex items-end justify-center text-xs text-stone-600"
                >
                  {showInfo ? (
                    <>
                      Hide <ChevronUp size={16} className="ml-1" />
                    </>
                  ) : (
                    <>Where do I get this?</>
                  )}
                </button>
                {showInfo && (
                  <div className="flex flex-col gap-4">
                    <p className="text-stone-600 text-xs w-96">
                      {platform === 'Notion'
                        ? `Once you have created something in Notion,
                      you have the ability to share and publish it using the top
                      right menu. After publishing, a duplicate link is
                      created. Paste this in the feild above. After a purchase, customers will be able to open the link in Notion.`
                        : `Once you have created something in Framer, click the logo in the top left -> File -> 'Copy Remix Link'. Paste this in the feild above. After a purchase, customers will be able to open the link in Framer.`}
                    </p>

                    <a
                      href={
                        platform === 'Notion'
                          ? `https://www.notion.so/help/public-pages-and-web-publishing`
                          : `https://www.framer.com/help/articles/how-to-create-a-remix-link/`
                      }
                      target="_blank"
                      className="text-xs text-stone-600 flex gap-1"
                    >
                      {' '}
                      Open Tutorial <ExternalLink size={14} />
                    </a>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default DesktopForm;
