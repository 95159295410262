import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import handleLogoutUser from '../../utils/logout';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import MobileNavbar from './MobileNavbar';
import {
  Bell,
  Book,
  HelpCircle,
  LogOut,
  Map,
  Settings,
  X,
  XCircle,
} from 'react-feather';
import { hideNotification } from '../../redux/toastSlice';
import { Dropdown } from 'flowbite-react';
import { Avatar } from '@mui/material';

const Navbar = () => {
  const navigate = useNavigate();

  const toast = useSelector((state) => state.toast);
  const dispatch = useDispatch();

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const handleLogout = () => {
    handleLogoutUser({ navigate, dispatch, type: 'logout' }); //logout function in utils
  };

  useEffect(() => {
    if (toast.isOpen) {
      const timeout = setTimeout(() => {
        dispatch(hideNotification());
      }, 4000); // 5 seconds timeout

      return () => clearTimeout(timeout);
    }
  }, [toast.isOpen]);

  return isMobile ? (
    <MobileNavbar currentUser={currentUser} handleLogout={handleLogout} />
  ) : currentUser ? (
    <nav className="w-full bg-white top-0 left-0 right-0 mb-8">
      <div className="max-w-6xl h-full mx-auto flex justify-between items-center border border-gray-200 rounded-md mt-8 p-2">
        {/* logo section */}
        <div>
          <Link to="/dashboard" className="h-full flex gap-1">
            <Map size={18} />
            <p
              className="font-black text-stone-800 text-md"
              style={{ fontFamily: 'Inter, sans-serif' }}
            >
              Fruntt
            </p>
          </Link>
        </div>

        {toast.isOpen && (
          <div className="flex items-center justify-start gap-1 bg-gray-50 border border-gray-200 rounded-md p-1 pl-2 pr-2">
            <X
              size={14}
              onClick={() => dispatch(hideNotification())}
              className="hover:cursor-pointer text-stone-800"
            />
            <p className="text-stone-800 text-xs">{toast.message}</p>
          </div>
        )}

        <Dropdown
          label=""
          renderTrigger={() => (
            <Avatar
              src={
                currentUser?.sellerProfile?.picture?.url
                  ? currentUser?.sellerProfile?.picture?.url
                  : currentUser?.name
              }
              sx={{ width: 26, height: 26 }}
              className="hover:cursor-pointer"
            />
          )}
          className="z-50"
        >
          <Link to="/settings">
            <Dropdown.Item className="text-xs text-stone-800 hover:border-gray-200 hover:outline-none outline-none w-full flex items-center gap-1 ring-0">
              <Settings size={14} /> Settings
            </Dropdown.Item>
          </Link>

          <Link to="/notifications">
            <Dropdown.Item className="text-xs text-stone-800 w-full flex items-center gap-1 hover:border-gray-200 hover:outline-none outline-none ring-0">
              <Bell size={14} /> Notifications
            </Dropdown.Item>
          </Link>

          <Link to="/docs">
            <Dropdown.Item className="text-xs text-stone-800 w-full flex items-center gap-1 hover:border-gray-200 hover:outline-none outline-none ring-0">
              <Book size={14} /> Docs
            </Dropdown.Item>
          </Link>

          <Link to="/contact">
            <Dropdown.Item className="text-xs text-stone-800 w-full flex items-center gap-1 hover:border-gray-200 hover:outline-none outline-none ring-0">
              <HelpCircle size={14} /> Contact Us
            </Dropdown.Item>
          </Link>

          <Dropdown.Divider />

          <Dropdown.Item
            type="button"
            onClick={handleLogout}
            className="text-stone-800 text-xs flex items-center gap-1 w-full hover:border-gray-200 hover:outline-none outline-none ring-0"
          >
            Logout <LogOut size={14} />
          </Dropdown.Item>
        </Dropdown>
      </div>
    </nav>
  ) : (
    <nav className="w-full bg-white fixed top-0 z-50">
      <div className="max-w-6xl mx-auto flex justify-between items-center border border-gray-200 rounded-md p-2 mt-8">
        {/* logo section */}

        <Link to="/" className="h-full flex gap-1">
          <Map size={18} />
          <p
            className="font-black text-stone-800 text-md"
            style={{ fontFamily: 'Inter, sans-serif' }}
          >
            Fruntt
          </p>
        </Link>

        {toast.isOpen && (
          <div className="flex items-center justify-start gap-1 bg-gray-50 border border-gray-200 rounded-md p-1 pl-2 pr-2">
            <X
              size={14}
              onClick={() => dispatch(hideNotification())}
              className="hover:cursor-pointer text-stone-800"
            />
            <p className="text-stone-800 text-xs">{toast.message}</p>
          </div>
        )}

        {/* links section */}
        <div className="h-full flex items-center justify-between">
          <div className="flex gap-2 items-center">
            <Link
              to="/docs"
              className="flex text-xs p-1 pl-2 pr-2 justify-center items-center hover:bg-gray-200 text-stone-800 rounded-md"
            >
              <p className="font-medium">Docs</p>
            </Link>
            <Link
              to="/pricing"
              className="flex text-xs p-1 pl-2 pr-2 justify-center items-center hover:bg-gray-200 text-stone-800 rounded-md"
            >
              <p className="font-medium">Pricing</p>
            </Link>
            <Link
              to="/signup"
              className="flex text-xs p-1 pl-2 pr-2 items-center justify-center hover:bg-gray-200 text-stone-800 rounded-md"
            >
              <p className="font-medium">Sell</p>
            </Link>
            <Link
              to="/login"
              className="flex text-xs items-center justify-center bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2"
            >
              <p className="font-medium">Login</p>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
