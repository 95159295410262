import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Footer from '../../../components/Footer/Footer';
import Navbar from '../../../components/Navbar/Navbar';
import { useGetProductReviewsQuery } from '../../../api/productsApiSlice';
import { Spinner } from 'flowbite-react';
import Desktop from './Desktop';
import { useParams } from 'react-router-dom';
import SideNavbar from '../../../components/SideNavbar';

const ItemReviews = () => {
  const { productId } = useParams();

  const {
    data: reviews,
    isLoading,
    isSuccess,
    refetch,
  } = useGetProductReviewsQuery({ productId: productId });

  useEffect(() => {
    refetch();
  }, []);
  let content;

  if (isLoading) {
    content = (
      <div className="w-full h-96 flex items-center justify-center">
        <Spinner />
      </div>
    );
  } else if (isSuccess) {
    content = <Desktop reviews={reviews} productId={productId} />;
  }

  const styles = isMobile
    ? 'w-full mx-auto h-screen bg-white mt-16'
    : 'w-full mx-auto h-screen bg-white ml-2';

  return (
    <>
      <Navbar />
      <div className="flex mx-auto max-w-6xl">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};
export default ItemReviews;
