export const framerCats = [
  { value: 'Agency', label: 'Agency' },
  { value: 'AI', label: 'AI' },
  { value: 'Blog', label: 'Blog' },
  { value: 'Business', label: 'Business' },
  { value: 'Ecommerce', label: 'Ecommerce' },
  { value: 'Landing Page', label: 'Landing Page' },
  { value: 'Modern', label: 'Modern' },
  { value: 'Portfolio', label: 'Portfolio' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Personal', label: 'Personal' },
  { value: 'SaaS', label: 'SaaS' },
  { value: 'Startup', label: 'Startup' },
  { value: 'Web3', label: 'Web3' },
  { value: 'Animated', label: 'Animated' },
];

export const notionCats = [
  { value: 'productivity', label: 'Productivity' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'work', label: 'Work' },
  { value: 'operations', label: 'Operations' },
  { value: 'business', label: 'Business' },
  { value: 'student', label: 'Student' },
  { value: 'finance', label: 'Finance' },
  { value: 'personal', label: 'Personal' },
  { value: 'product', label: 'Product' },
  { value: 'planner', label: 'Planner' },
  { value: 'teaching', label: 'Teaching' },
  { value: 'goals', label: 'Goals' },
  { value: 'project', label: 'Project' },
  { value: 'roadmap', label: 'Roadmap' },
  { value: 'organization', label: 'Organization' },
  { value: 'startup', label: 'Startup' },
  { value: 'design', label: 'Design' },
  { value: 'ai', label: 'AI' },
  { value: 'engineering', label: 'Engineering' },
];
