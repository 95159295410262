import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { useSendResetEmailMutation } from '../../api/authApiSlice';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import { Check, X } from 'react-feather';

const RequestReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [noUser, setNoUser] = useState('');
  const [token, setToken] = useState('');

  const [sendResetEmail, { isLoading }] = useSendResetEmailMutation();

  const handleRequestLink = async (e) => {
    e.preventDefault();

    setError('');
    setNoUser('');

    if (!email) {
      setError('Please enter your email');
      return;
    }

    try {
      const resetEmailReq = await sendResetEmail({ email: email }).unwrap();
      if (resetEmailReq.msg === 'Email sent') {
        // Code to store timestamp when password reset link is requested
        localStorage.setItem('prra', new Date().getTime());
        setToken(resetEmailReq.token);
        setEmailSent(true);
        return;
      } else if (resetEmailReq === 'User does not exist') {
        setNoUser('User does not exist');
        return;
      }
    } catch (err) {
      console.log(err);
      setError('There was a server error');
      return;
    }
  };

  useEffect(() => {
    setError('');
    setNoUser(false);
  }, [email]);

  return isMobile ? (
    <>
      <Navbar />
      <div className="flex flex-col gap-2 items-center mx-auto justify-center h-screen w-full p-4">
        {emailSent ? (
          <div className="border border-gray-200 rounded-md p-2 flex flex-col gap-4 items-center justify-center text-center">
            <p className="flex items-center gap-1 text-sm text-stone-800">
              <Check size={16} className="text-green-400" />
              Link sent
            </p>
            <p className="text-xs text-stone-600 text-center">
              A link to reset your password has been sent to{' '}
              <span className="font-bold">{email}</span> and will expire in 30
              minutes
            </p>
            <div className="flex items-center gap-1">
              {token ? (
                <>
                  <Link
                    to="/login"
                    className="flex items-center justify-center bg-white border border-gray-200 p-2 rounded-md text-xs text-stone-800"
                  >
                    Login
                  </Link>
                  <Link
                    to={`/password/reset/${token}`}
                    className="flex items-center justify-center bg-gray-200 border border-gray-200 p-2 rounded-md text-xs text-stone-800"
                  >
                    Reset
                  </Link>
                </>
              ) : (
                <Link
                  to="/login"
                  className="flex items-center justify-center bg-gray-200 p-2 rounded-md text-xs text-stone-800"
                >
                  Return to login
                </Link>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col items-start w-full">
              <p className="text-lg text-stone-800">Reset Password</p>
              <p className="text-stone-600 text-xs">
                Did you forget your password? Request a reset link below.
              </p>
            </div>
            {error && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{error}</p>
              </div>
            )}
            {noUser && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{noUser}</p>
              </div>
            )}
            <form className="flex flex-col gap-2 items-center w-full bg-white p-2 rounded-md border border-gray-200">
              <input
                type="email"
                placeholder="Email"
                className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent focus:ring-0 text-stone-800 hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                onChange={(e) => setEmail(e.target.value)}
              />

              {isLoading ? (
                <div className="w-full flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <button
                    disabled={isLoading}
                    className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs"
                    onClick={handleRequestLink}
                  >
                    Request Link
                  </button>
                </>
              )}
            </form>
          </>
        )}
      </div>
      <Footer />
    </>
  ) : (
    <>
      <Navbar />
      <div
        className="flex flex-col gap-2 items-center mx-auto justify-center h-screen p-4"
        style={{ width: '400px' }}
      >
        {emailSent ? (
          <div className="border border-gray-200 rounded-md p-2 flex flex-col gap-4 items-center justify-center">
            <p className="flex items-center gap-1 text-sm text-stone-800">
              <Check size={16} className="text-green-400" />
              Link sent
            </p>
            <p className="text-xs text-stone-600 text-center">
              A link to reset your password has been sent to{' '}
              <span className="font-bold">{email}</span> and will expire in 30
              minutes
            </p>
            {token ? (
              <div className="flex items-center gap-1">
                <Link
                  to="/login"
                  className="flex items-center justify-center bg-white border border-gray-200 p-2 rounded-md text-xs text-stone-800"
                >
                  Login
                </Link>
                <Link
                  to={`/password/reset/${token}`}
                  className="flex items-center justify-center bg-gray-200 border border-gray-200 p-2 rounded-md text-xs text-stone-800"
                >
                  Reset
                </Link>
              </div>
            ) : (
              <Link
                to="/login"
                className="flex items-center justify-center bg-gray-200 p-2 rounded-md text-xs text-stone-800"
              >
                Return to login
              </Link>
            )}
          </div>
        ) : (
          <>
            <div className="flex flex-col items-start w-full">
              <p className="text-lg text-stone-800">Reset Password</p>
              <p className="text-stone-600 text-xs">
                Did you forget your password? Request a reset link below.
              </p>
            </div>
            {error && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{error}</p>
              </div>
            )}
            {noUser && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{noUser}</p>
              </div>
            )}

            <form className="flex flex-col gap-2 items-center w-full bg-white p-2 rounded-md border border-gray-200">
              <input
                type="email"
                placeholder="Email"
                value={email}
                className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent focus:ring-0 text-stone-800 hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                onChange={(e) => setEmail(e.target.value)}
              />

              {isLoading ? (
                <div className="w-full flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <button
                    disabled={isLoading}
                    className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs"
                    onClick={handleRequestLink}
                  >
                    Request Link
                  </button>
                </>
              )}
            </form>
          </>
        )}
      </div>
      <Footer />
    </>
  );
  // return (
  //   <>
  //     <Navbar />
  //     <div className='max-w-7xl mx-auto h-screen'>
  //       {emailSent ? (
  //         isMobile ? (
  //           <div className='flex flex-col items-center p-2 border rounded drop-shadow-md w-11/12 mx-auto mt-32 bg-white'>
  //             <AiOutlineCheckCircle className='text-green-400 text-5xl' />
  //             <p className='font-medium text-stone-800 text-xl mt-2 text-center'>
  //               A reset link has been sent, check your inbox soon!
  //             </p>
  //             <Link
  //               to='/'
  //               className='border-2 rounded border-stone-800 flex items-center justify-center text-stone-800 w-28 h-10 mt-4'
  //             >
  //               Return Home
  //             </Link>
  //           </div>
  //         ) : (
  //           <div className='flex flex-col items-center p-2 border rounded drop-shadow-md w-8/12 mx-auto mt-32 bg-white'>
  //             <AiOutlineCheckCircle className='text-green-400 text-5xl' />
  //             <p className='font-medium text-stone-800 text-xl mt-2 text-center'>
  //               A reset link has been sent, check your inbox soon!
  //             </p>
  //             <Link
  //               to='/'
  //               className='border-2 rounded border-stone-800 flex items-center justify-center text-stone-800 w-28 h-10 mt-4'
  //             >
  //               Return Home
  //             </Link>
  //           </div>
  //         )
  //       ) : isMobile ? (
  //         <div className='flex flex-col p-2 border rounded drop-shadow-md w-11/12 mx-auto mt-32 bg-white'>
  //           <p className='font-medium text-stone-800 text-2xl'>
  //             Oops, you forgot your password
  //           </p>
  //           <p className='font-medium text-gray-400 text-lg mt-2'>
  //             Enter your email below to receive a link to reset your password
  //           </p>

  //           <form className='flex flex-col' onSubmit={handleRequestLink}>
  //             {error && (
  //               <Alert severity='error' className='mt-2'>
  //                 {error}
  //               </Alert>
  //             )}
  //             <input
  //               type='email'
  //               placeholder='Enter email'
  //               className='border-2 border-slate-200 hover:border-slate-300 focus:outline focus:outline-1 focus:outline-slate-300 w-full rounded mt-4 p-2'
  //               autoFocus
  //               onChange={(e) => setEmail(e.target.value)}
  //             />
  //             <button
  //               type='submit'
  //               className='h-11 w-full border-2 border-stone-800 hover:bg-stone-800 hover:text-white text-stone-800 rounded text-xl mt-4'
  //             >
  //               Send Link
  //             </button>
  //           </form>
  //         </div>
  //       ) : (
  //         <div className='flex flex-col p-2 border rounded drop-shadow-md w-8/12 mx-auto mt-32 bg-white'>
  //           <p className='font-medium text-stone-800 text-3xl'>
  //             Oops! You forgot your password.
  //           </p>
  //           <p className='font-medium text-stone-800 text-xl mt-2'>
  //             Enter your email below to receive a link to reset your password
  //           </p>

  //           <form className='flex flex-col' onSubmit={handleRequestLink}>
  //             {error && (
  //               <Alert severity='error' className='mt-2'>
  //                 {error}
  //               </Alert>
  //             )}
  //             <input
  //               type='email'
  //               placeholder='Enter email'
  //               className='border-2 border-gray-200 hover:border-gray-300 outline outline-0 w-full rounded mt-4 p-2'
  //               autoFocus
  //               onChange={(e) => setEmail(e.target.value)}
  //             />
  //             <button
  //               type='submit'
  //               className='h-11 w-full border-2 border-stone-800 hover:bg-stone-800 hover:text-white text-stone-800 rounded text-xl mt-4'
  //             >
  //               Send Link
  //             </button>
  //           </form>
  //         </div>
  //       )}
  //     </div>
  //     <Footer />
  //   </>
  // );
};

export default RequestReset;
