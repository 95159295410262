import React from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import SideNavbar from '../../../components/SideNavbar';
import Footer from '../../../components/Footer/Footer';
import { RotateCcw } from 'react-feather';

const Refunds = () => {
  return (
    <>
      <Navbar />
      <div className="flex max-w-6xl mx-auto">
        <SideNavbar />
        <div className="w-full mx-auto bg-white ml-2 h-screen">
          <div
            className="flex flex-col gap-2 border-gray-200 items-center justify-center rounded-md w-full border bg-white"
            style={{ height: '600px' }}
          >
            <RotateCcw size={18} className="text-stone-800" />
            <p className="text-stone-800 text-sm">Refunds Coming Soon</p>
            <p className="text-stone-600 text-xs w-60 text-center">
              Easily create and update refund policies for the items of your
              choice
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Refunds;
