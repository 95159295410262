import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useConfirmEmailMutation } from '../../api/authApiSlice';
import { Check, Mail, Map, X } from 'react-feather';
import { Spinner } from 'flowbite-react';

const ConfirmEmail = () => {
  const { userId } = useParams();
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);

  const [confirmEmail, { data, isLoading, isSuccess }] =
    useConfirmEmailMutation();

  useEffect(() => {
    const confirm = async () => {
      try {
        const confirmEmailReq = await confirmEmail({ userId }).unwrap();
        if (confirmEmailReq.msg === 'User updated') {
          setConfirmed(true);
        }
      } catch (err) {
        setConfirmed(false);
        setError(true);
      }
    };
    confirm();
  }, []);

  const handleConfirmEmail = async (e) => {
    e.preventDefault();

    try {
      const confirmEmailReq = await confirmEmail({ userId }).unwrap();
      if (confirmEmailReq?.msg === 'User updated') {
        setConfirmed(true);
      }
    } catch (err) {
      setConfirmed(false);
      setError(true);
    }
  };

  let content;

  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = (
      <div className="border border-gray-200 rounded-md p-4 flex flex-col items-start gap-4 w-96 mx-auto">
        {confirmed ? (
          <>
            <div>
              <Link to="/dashboard" className="h-full flex gap-1">
                {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
                <Map size={18} />
                <p
                  className="font-black text-stone-800 text-md"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link>
            </div>
            <p className="text-lg text-stone-800 flex items-center">
              Email confirmed <Check size={20} className="ml-2" />
            </p>
            {error && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">
                  There was an error confirming your email
                </p>
              </div>
            )}

            <p className="text-xs text-stone-600">
              Your email,{' '}
              <span className="text-stone-800 font-bold">{data?.email}</span>,{' '}
              has been confirmed. You are one step closer to making sales! Go to
              your account settings for further setup using the button below.
            </p>
            <Link
              to="/settings"
              className="flex items-center justify-center rounded-md bg-gray-200 text-stone-800 p-2 text-xs"
            >
              Go to settings
            </Link>
          </>
        ) : (
          <>
            <div>
              <Link to="/dashboard" className="h-full flex gap-1">
                {/* <IoStorefrontOutline className='text-stone-800 font-bold text-xl' /> */}
                <Map size={18} />
                <p
                  className="font-black text-stone-800 text-md"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </Link>
            </div>
            <p className="text-lg text-stone-800 flex items-center">
              Confirm email <Mail size={20} className="ml-2" />
            </p>
            {error && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">
                  There was an error confirming your email
                </p>
              </div>
            )}
            <p className="text-xs text-stone-600">
              If your email didn't confirm automatically, use the button below.
              This is needed to complete your account setup and to start making
              sales.
            </p>
            <button
              type="button"
              onClick={handleConfirmEmail}
              className="rounded-md bg-gray-200 text-stone-800 p-2 text-xs"
            >
              Confirm Email
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      {content}
    </div>
  );
};

export default ConfirmEmail;
