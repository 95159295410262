import React from 'react';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import StripeAmount from '../../../utils/StripeAmount';
import { PayoutDate } from '../../../utils/PayoutDate';
import { Badge } from 'flowbite-react';
import getSymbolFromCurrency from 'currency-symbol-map';

const Desktop = ({ payout, currentUser }) => {
  const DisplayBadge = ({ status }) => {
    switch (status) {
      case 'paid':
        return (
          <Badge color="success" size="xs">
            Paid
          </Badge>
        );
      case 'failed':
        return (
          <Badge color="failure" size="xs">
            Failed
          </Badge>
        );
      case 'cancelled':
        return (
          <Badge color="failure" size="xs">
            cancelled
          </Badge>
        );
      case 'pending':
        return (
          <Badge color="warning" size="xs">
            Pending
          </Badge>
        );
      case 'in_transit':
        return (
          <Badge color="warning" size="xs">
            In Transit
          </Badge>
        );
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col max-w-3xl gap-2 mx-auto">
      <div className="flex w-full justify-between items-end mx-auto">
        <div className="flex flex-col border border-gray-200 rounded-md p-4">
          <p className="text-sm text-stone-800">Viewing payout</p>
          <p className="text-xs text-stone-600">payout: {payout?._id}</p>
        </div>

        <Link
          to="/dashboard/payouts"
          className="flex items-center text-xs text-stone-600"
        >
          Payouts
          <ChevronRight size={16} />
        </Link>
      </div>
      <div className="w-full border border-gray-200 rounded-md mx-auto p-4">
        <div className="flex items-start">
          <div className="flex flex-col items-start gap-2 w-3/6">
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Paid to</p>
              <p className="text-sm text-stone-800">{currentUser?.name}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Bank name</p>
              <p className="text-sm text-stone-800">{payout?.bankName}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Currency</p>
              <p className="text-sm text-stone-800">
                {`${payout?.currency.toUpperCase()}`}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Amount</p>
              <button
                disabled
                className="p-2 bg-gray-200 text-stone-800 text-xs rounded-md"
              >
                <StripeAmount
                  amount={payout?.amount}
                  currency={payout?.currency}
                />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2 w-3/6">
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Status</p>
              <p className="text-sm text-stone-800">
                <DisplayBadge status={payout?.status} />
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Stripe payout ID</p>
              <p className="text-sm text-stone-800">{payout?.payoutId}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs text-stone-600">Arriving</p>
              <p className="text-sm text-stone-800">
                <PayoutDate payoutDate={payout?.arrivalDate} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
