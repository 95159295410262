import React from 'react';
import Chart from 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { BarChart } from 'react-feather';

const SalesGraph = ({ dataset, view }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatRevenue = (total) => {
    const formattedRevenue = `$${total}`;
    return formattedRevenue;
  };

  const labels =
    view === 'today'
      ? dataset.map((data) => data.hour)
      : dataset.map((data) => formatDate(data.date));
  const sales = dataset.map((data) => data.sales);
  const revenue = dataset.map((data) => data.revenue);
  const visits = dataset.map((data) => data.visits);
  const sumOfSales = sales.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  const sumOfVisits = visits.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Sales',
        backgroundColor: 'rgb(41 37 36)',
        borderColor: 'rgb(41 37 36)',
        data: sales,
      },
      {
        label: 'Revenue($)',
        data: revenue,
        backgroundColor: 'rgb(229 231 235)',
        borderColor: 'rgb(229 231 235)',
      },
      {
        label: 'Visits',
        data: visits,
        backgroundColor: '#ffffff',
        borderColor: 'rgb(41 37 36)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: false, // Hide x-axis labels
      },
      y: {
        display: false, // Hide y-axis labels
        beginAtZero: true, // Ensure the y-axis begins at 0
        min: 0,
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: 'rgb(229 231 235)',
        titleColor: 'rgb(41 37 36)',
        bodyColor: 'rgb(41 37 36)',
        displayColors: false,
      },
      legend: {
        display: false, // This hides the legend
      },
    },
  };

  return (
    <div className="w-full h-auto">
      {sumOfSales > 0 || sumOfVisits > 0 ? (
        <div className="flex flex-col">
          <div className="flex items-center gap-4 mt-2 ml-2">
            <div className="flex items-center gap-2">
              <div className="h-2.5 w-2.5 bg-stone-800 border border-stone-800"></div>
              <p className="text-xs text-stone-800">Sales</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-2.5 w-2.5 bg-gray-200 border border-gray-200"></div>
              <p className="text-xs text-stone-800">Revenue</p>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-2.5 w-2.5 bg-white border border-stone-800"></div>
              <p className="text-xs text-stone-800">Visits</p>
            </div>
          </div>
          <Bar options={options} data={data} />
        </div>
      ) : (
        <div className="w-full h-96 flex flex-col items-center justify-center">
          <BarChart size={18} />
          <p className="text-xs text-stone-600">
            No sales{' '}
            {view === 'today'
              ? 'in the past 24 hours'
              : view === 'seven'
              ? 'in the past 7 days'
              : view === 'thirty'
              ? 'in the past 30 days'
              : 'since you signed up'}
          </p>
        </div>
      )}
    </div>
  );
};

export default SalesGraph;
