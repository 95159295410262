import React from 'react';
import { useNavigate } from 'react-router-dom';
import handleLogoutUser from '../../utils/logout';
import { useDispatch } from 'react-redux';
import { ChevronRight, Map, Monitor, Smartphone } from 'react-feather';

const MobilePlaceholder = ({ currentUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    handleLogoutUser({ navigate, dispatch, type: 'logout' }); //logout function in utils
  };

  return (
    <div className="h-screen w-full p-4 mx-auto flex items-center justify-center">
      <div className="w-full mx-auto flex flex-col gap-6 items-center justify-center">
        <div className="h-full flex gap-1 mb-14">
          <Map size={18} />
          <p
            className="font-black text-stone-800 text-md"
            style={{ fontFamily: 'Inter, sans-serif' }}
          >
            Fruntt
          </p>
        </div>

        <div className="flex items-center gap-2">
          <Smartphone size={18} className="text-stone-800" />
          <ChevronRight size={14} className="text-stone-800" />
          <Monitor size={18} className="text-stone-800" />
        </div>

        <div className="flex flex-col gap-1 items-center">
          <p className="text-sm text-stone-800 text-center">
            Mobile view not available
          </p>

          <p className="text-xs text-stone-600 text-center">
            To fully view your account you need to login using a desktop browser
          </p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-xs text-stone-600">Logged in as:</p>
          <p className="text-xs text-stone-800 text-center">
            {currentUser?.email}
          </p>
        </div>
        <button
          type="button"
          onClick={handleLogout}
          className="pl-2 pr-2 p-1 bg-gray-200 rounded-md text-xs text-stone-800"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default MobilePlaceholder;
