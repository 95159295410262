import React from 'react';
import { isMobile } from 'react-device-detect';
import { ChevronRight, PlayCircle, Youtube } from 'react-feather';
import ReactPlayer from 'react-player';

const Demos = ({ demos, setDocsView, currentUser }) => {
  const style = currentUser
    ? 'h-fit mx-auto max-w-6xl flex flex-col items-start gap-6'
    : 'h-fit mx-auto max-w-6xl flex flex-col items-start mt-24 gap-6';
  return isMobile ? (
    <div className="h-fit mx-auto w-full p-4 flex flex-col items-start mt-14 gap-6">
      <div className="flex justify-between items-center border border-gray-200 p-2 rounded-md w-full">
        <div className="flex flex-col items-start gap-2 w-full">
          <p className="text-sm text-stone-800">Fruntt Tutorials</p>
          <p className="text-xs text-stone-600">
            Watch a collection of quick tutorials on how to use various features
            of Fruntt. Perfect for anyone interested in creating an account or
            needing a quick reminder on how to do something.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2"
          >
            Back to docs <ChevronRight size={14} />
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-14 w-full">
        <div className="flex flex-col items-start gap-2 w-full">
          <div className="w-full flex justify-center">
            <ReactPlayer url={demos[0]?.url} width={345} height={200} />
          </div>
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-stone-800 text-sm">{demos[0]?.title}</p>
            <p className="text-xs text-stone-600">{demos[0]?.summary}</p>
            <a
              href={demos[0]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2 w-full">
          <div className="w-full flex justify-center">
            <ReactPlayer url={demos[1]?.url} width={345} height={200} />
          </div>
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-stone-800 text-sm">{demos[1]?.title}</p>
            <p className="text-xs text-stone-600">{demos[1]?.summary}</p>
            <a
              href={demos[1]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2 w-full">
          <div className="w-full flex justify-center">
            <ReactPlayer url={demos[2]?.url} width={345} height={200} />
          </div>
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-stone-800 text-sm">{demos[2]?.title}</p>
            <p className="text-xs text-stone-600">{demos[2]?.summary}</p>
            <a
              href={demos[2]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start gap-2 w-full">
          <div className="w-full flex justify-center">
            <ReactPlayer url={demos[3]?.url} width={345} height={200} />
          </div>
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-stone-800 text-sm">{demos[3]?.title}</p>
            <p className="text-xs text-stone-600">{demos[3]?.summary}</p>
            <a
              href={demos[3]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
        </div>
      </div>

      <div className="w-full h-12 border border-gray-200 rounded-md flex items-center justify-center">
        <p className="text-stone-600 text-xs">More coming soon</p>
      </div>
    </div>
  ) : (
    <div className={style}>
      <div className="flex justify-between items-center border border-gray-200 p-2 rounded-md w-8/12">
        <div className="flex flex-col items-start gap-2 w-full">
          <p className="text-sm text-stone-800">Fruntt Tutorials</p>
          <p className="text-xs text-stone-600">
            Watch a collection of quick tutorials on how to use various features
            of Fruntt. Perfect for anyone interested in creating an account or
            needing a quick reminder on how to do something.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2"
          >
            Back to docs <ChevronRight size={14} />
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-14 w-full">
        <div className="flex items-start w-full gap-10 rounded-md">
          <div className="w-6/12">
            <ReactPlayer url={demos[0]?.url} width={500} height={250} />
          </div>
          <div className="w-6/12 flex flex-col items-start gap-6">
            <p className="text-stone-800 text-sm">{demos[0]?.title}</p>
            <p className="text-xs text-stone-600">{demos[0]?.summary}</p>
            <a
              href={demos[0]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
        </div>
        <div className="flex items-start w-full gap-10  rounded-md">
          <div className="w-6/12 flex flex-col items-start gap-6">
            <p className="text-stone-800 text-sm">{demos[1]?.title}</p>
            <p className="text-xs text-stone-600">{demos[1]?.summary}</p>
            <a
              href={demos[1]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
          <div className="w-6/12 flex justify-end">
            <ReactPlayer url={demos[1]?.url} width={500} height={250} />
          </div>
        </div>
        <div className="flex items-start w-full gap-10 rounded-md">
          <div className="w-6/12">
            <ReactPlayer url={demos[2]?.url} width={500} height={250} />
          </div>
          <div className="w-6/12 flex flex-col items-start gap-6">
            <p className="text-stone-800 text-sm">{demos[2]?.title}</p>
            <p className="text-xs text-stone-600">{demos[2]?.summary}</p>
            <a
              href={demos[2]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
        </div>
        <div className="flex items-start w-full gap-10 rounded-md">
          <div className="w-6/12 flex flex-col items-start gap-6">
            <p className="text-stone-800 text-sm">{demos[3]?.title}</p>
            <p className="text-xs text-stone-600">{demos[3]?.summary}</p>
            <a
              href={demos[3]?.url}
              target="_blank"
              className="bg-gray-200 rounded-md p-2 flex gap-1 justify-center items-center text-xs text-stone-800"
            >
              Watch on <Youtube size={16} />
            </a>
          </div>
          <div className="w-6/12 flex justify-end">
            <ReactPlayer url={demos[3]?.url} width={500} height={250} />
          </div>
        </div>
      </div>

      <div className="w-full h-12 border border-gray-200 rounded-md flex items-center justify-center">
        <p className="text-stone-600 text-xs">More coming soon</p>
      </div>
    </div>
  );
};

export default Demos;
