import { apiSlice } from './apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: '/auth/register',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    googleLogin: builder.mutation({
      query: ({ code }) => ({
        url: '/auth/google/login',
        method: 'POST',
        body: {
          code: code,
        },
      }),
    }),
    googleSignup: builder.mutation({
      query: ({ code }) => ({
        url: '/auth/google/signup',
        method: 'POST',
        body: {
          code: code,
        },
      }),
    }),
    twitterLogin: builder.mutation({
      query: ({ oauthToken, oauthSecret, oauthVerifier }) => ({
        url: '/auth/twitter/login',
        method: 'POST',
        body: {
          oauthToken: oauthToken,
          oauthSecret: oauthSecret,
          oauthVerifier: oauthVerifier,
        },
      }),
    }),
    twitterRegister: builder.mutation({
      query: ({ oauthToken, oauthSecret, oauthVerifier, storeName }) => ({
        url: '/auth/twitter/register',
        method: 'POST',
        body: {
          oauthToken: oauthToken,
          oauthSecret: oauthSecret,
          oauthVerifier: oauthVerifier,
          storeName: storeName,
        },
      }),
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: '/auth/deleteaccount',
        method: 'POST',
      }),
    }),
    getOnboardUrl: builder.query({
      query: () => '/auth/onboard',
    }),
    getBankUrl: builder.query({
      query: () => '/auth/bank/url',
    }),
    getUpdateUrl: builder.query({
      query: () => '/auth/update/url',
    }),
    getTwitterAuthUrl: builder.query({
      query: ({ type }) => `/auth/twitter/${type}`,
    }),
    getSetupIntent: builder.query({
      query: () => '/auth/getsetupintent',
    }),
    getUpdatedUser: builder.query({
      query: () => '/auth/updateduser',
    }),
    disconnectStripe: builder.mutation({
      query: () => ({
        url: '/auth/disconnectstripe',
        method: 'POST',
      }),
    }),
    removeBank: builder.mutation({
      query: () => ({
        url: '/auth/bank/remove',
        method: 'POST',
      }),
    }),
    addPaymentMethod: builder.mutation({
      query: ({ paymentMethodId }) => ({
        url: '/auth/addpayment',
        method: 'POST',
        body: {
          paymentMethodId: paymentMethodId,
        },
      }),
    }),
    changePayoutSchedule: builder.mutation({
      query: ({ schedule }) => ({
        url: '/auth/schedule/change',
        method: 'POST',
        body: {
          schedule: schedule,
        },
      }),
    }),
    deletePaymentMethod: builder.mutation({
      query: () => ({
        url: '/auth/deletepayment',
        method: 'POST',
      }),
    }),
    accountSetup: builder.mutation({
      query: ({
        name,
        country,
        pageName,
        bio,
        currency,
        currencySym,
        profilePicUrl,
        profilePicKey,
        timezone,
      }) => ({
        url: '/auth/setup',
        method: 'POST',
        body: {
          name: name,
          bio: bio,
          pageName: pageName,
          country: country,
          currency: currency,
          profilePicUrl: profilePicUrl,
          profilePicKey: profilePicKey,
          currencySym: currencySym,
          timezone: timezone,
        },
      }),
    }),
    updateAccountInfo: builder.mutation({
      query: ({
        email,
        country,
        currency,
        currencySym,
        timezone,
        emailChanged,
      }) => ({
        url: '/auth/updateaccountinfo',
        method: 'POST',
        body: {
          email: email,
          country: country,
          currency: currency,
          currencySym: currencySym,
          timezone: timezone,
          emailChanged: emailChanged,
        },
      }),
    }),
    updateSellerProfile: builder.mutation({
      query: ({
        name,
        bio,
        facebook,
        youtube,
        twitter,
        instagram,
        tiktok,
        profilePicUrl,
        profilePicKey,
        linkedin,
        link,
        medium,
      }) => ({
        url: '/auth/updatesellerprofile',
        method: 'POST',
        body: {
          name: name,
          bio: bio,
          facebook: facebook,
          youtube: youtube,
          twitter: twitter,
          instagram: instagram,
          tiktok: tiktok,
          linkedin: linkedin,
          link: link,
          medium: medium,
          profilePicUrl: profilePicUrl,
          profilePicKey: profilePicKey,
        },
      }),
    }),

    updateNotifications: builder.mutation({
      query: ({
        sendUpdates,
        sendNewCustomer,
        sendOrderPlaced,
        sendReviewCollected,
        sendPayouts,
      }) => ({
        url: '/auth/updatenotifications',
        method: 'POST',
        body: {
          sendUpdates: sendUpdates,
          sendNewCustomer: sendNewCustomer,
          sendOrderPlaced: sendOrderPlaced,
          sendReviewCollected: sendReviewCollected,
          sendPayouts: sendPayouts,
        },
      }),
    }),
    confirmEmail: builder.mutation({
      query: ({ userId }) => ({
        url: '/auth/confirmemail',
        method: 'POST',
        body: {
          userId: userId,
        },
      }),
    }),
    sendResetEmail: builder.mutation({
      query: ({ email }) => ({
        url: '/auth/reset/send',
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),
    checkToken: builder.mutation({
      query: ({ token }) => ({
        url: '/auth/checkresettoken',
        method: 'POST',
        body: {
          token: token,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ email, password }) => ({
        url: '/auth/resetpassword',
        method: 'POST',
        body: {
          password: password,
          email: email,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ newPassword, oldPassword }) => ({
        url: '/auth/password/change',
        method: 'POST',
        body: {
          newPassword: newPassword,
          oldPassword: oldPassword,
        },
      }),
    }),
    submitMessage: builder.mutation({
      query: ({ email, name, body, accId }) => ({
        url: '/auth/submit/message',
        method: 'POST',
        body: {
          email: email,
          name: name,
          body: body,
          accId: accId,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useLazyGetOnboardUrlQuery,
  useLazyGetBankUrlQuery,
  useLazyGetUpdateUrlQuery,
  useGetUpdatedUserQuery,
  useLazyGetUpdatedUserQuery,
  useDisconnectStripeMutation,
  useUpdateAccountInfoMutation,
  useUpdateNotificationsMutation,
  useConfirmEmailMutation,
  useLazyGetSetupIntentQuery,
  useAddPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useUpdateSellerProfileMutation,
  useDeleteAccountMutation,
  useSendResetEmailMutation,
  useCheckTokenMutation,
  useResetPasswordMutation,
  useLazyGetTwitterAuthUrlQuery,
  useTwitterLoginMutation,
  useTwitterRegisterMutation,
  useChangePasswordMutation,
  useSubmitMessageMutation,
  useRemoveBankMutation,
  useAccountSetupMutation,
  useChangePayoutScheduleMutation,
  useGoogleLoginMutation,
  useGoogleSignupMutation,
} = authApiSlice;
