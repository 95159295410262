import React, { useEffect, useState } from 'react';
import {
  useGetSingleOrderQuery,
  useGetShippingRatesQuery,
} from '../../../api/ordersApiSlice';
import Navbar from '../../../components/Navbar/Navbar';
import Topbar from '../../../components/SideNavbar';
import Footer from '../../../components/Footer/Footer';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { isMobile } from 'react-device-detect';
import Desktop from './Desktop';
import SideNavbar from '../../../components/SideNavbar';

const OrderDetail = () => {
  const { orderId } = useParams();

  //gets the order
  const {
    data: order,
    isLoading,
    isSuccess,
    refetch,
  } = useGetSingleOrderQuery({
    orderId,
  });

  useEffect(() => {
    refetch();
  }, []);

  let content;
  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = <Desktop order={order} refetch={refetch} />;
  }

  const styles = isMobile
    ? 'w-full mx-auto h-fit p-2 bg-gray-50'
    : 'w-full mx-auto h-screen bg-white ml-2';

  return (
    <>
      <Navbar />
      <div className="flex max-w-6xl mx-auto">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default OrderDetail;
