import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import {
  useCheckTokenMutation,
  useResetPasswordMutation,
} from '../../api/authApiSlice';
import Spinner from '../../components/Spinner';
import { isMobile } from 'react-device-detect';
import { Clock, Frown, X } from 'react-feather';

const PasswordReset = () => {
  const navigate = useNavigate();

  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [error, setError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [valid, setValid] = useState(false);
  //password regex
  const regex =
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])\S{8,}$/;

  const [checkToken, { isLoading, isSuccess, data }] = useCheckTokenMutation();
  const [resetPassword, { isLoading: resetting }] = useResetPasswordMutation();

  // Function to calculate remaining time in minutes
  const calculateRemainingTime = () => {
    const requestedAt = localStorage.getItem('prra') || '';
    if (!requestedAt) return 0; // If timestamp not found, return 0
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - parseInt(requestedAt);
    const remainingTime = Math.max(0, 30 * 60 * 1000 - elapsedTime); // 30 minutes in milliseconds
    return Math.ceil(remainingTime / 1000 / 60); // Convert milliseconds to minutes
  };
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
  // Update remaining time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleCheckToken = async () => {
      const checkTokenReq = await checkToken({ token }).unwrap();
    };

    handleCheckToken();
  }, []);

  useEffect(() => {
    setError('');
  }, [password, passwordTwo]);

  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    if (!password || !passwordTwo) {
      setError('Please fill out both fields');
      return;
    }
    if (password !== passwordTwo) {
      setError('Passwords do not match');
      return;
    }

    setValid(regex.test(password));
    if (!regex.test(password)) {
      setError('Password must match requirements');
      setIsFocused(true);
      return;
    }

    try {
      const resetPassReq = await resetPassword({
        email: data?.email,
        password: password,
      }).unwrap();

      if (resetPassReq === 'Password reset') {
        localStorage.removeItem('prra');
        navigate('/login', { state: { success: true } });
      }
    } catch (err) {
      setError('There was an error');
    }
  };

  let content;
  if (isLoading || resetting) {
    content = (
      <div className="w-full h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  } else if (isSuccess) {
    content = data?.valid ? (
      isMobile ? (
        <div className="w-full h-full p-4">
          <div className="flex flex-col items-start mx-auto justify-center h-full gap-2">
            <div className="flex justify-between w-full">
              <div className="flex flex-col items-start w-full">
                <p className="text-lg text-stone-800">Reset password</p>
                <p className="text-stone-600 text-xs">
                  Reset password for{' '}
                  <span className="font-bold">{data?.email}</span>
                </p>
              </div>
            </div>
            {error && (
              <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">{error}</p>
              </div>
            )}

            <form className="flex flex-col gap-2 items-center w-full bg-white p-2 rounded-md border border-gray-200">
              <input
                type="password"
                placeholder="New password"
                className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent focus:ring-0 text-stone-800 hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={password}
              />
              {isFocused && (
                <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md p-2">
                  <p className="text-stone-800 text-xs">
                    Password must contain:
                  </p>
                  <p className="text-stone-600 text-xs">
                    Minimum of 8 characters
                  </p>
                  <p className="text-stone-600 text-xs">
                    At least one uppercase
                  </p>
                  <p className="text-stone-600 text-xs">
                    At least one special character
                  </p>
                  <p className="text-stone-600 text-xs">At least one number</p>
                </div>
              )}
              <input
                type="password"
                placeholder="Confirm password"
                className="border text-xs border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 outline-0 outline w-full rounded-md p-2"
                onChange={(e) => setPasswordTwo(e.target.value)}
                value={passwordTwo}
              />

              {resetting ? (
                <div className="w-full flex items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <button
                    disabled={resetting || remainingTime === 0}
                    className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs"
                    onClick={handleSubmitPassword}
                  >
                    Reset Password
                  </button>
                </>
              )}
            </form>
            <div className="flex items-center justify-start gap-1">
              <p className="text-stone-600 text-xs">
                This link expires in{' '}
                <span className="font-bold">{remainingTime}</span>{' '}
                {remainingTime === 1 ? 'minute' : 'minutes'}
              </p>
              <Clock
                size={14}
                className={
                  remainingTime < 6 ? 'text-red-400' : 'text-stone-800'
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col items-start mx-auto justify-center h-full gap-2"
          style={{ width: '400px' }}
        >
          <div className="flex justify-between w-full">
            <div className="flex flex-col items-start w-full">
              <p className="text-lg text-stone-800">Reset password</p>
              <p className="text-stone-600 text-xs">
                Reset password for{' '}
                <span className="font-bold">{data?.email}</span>
              </p>
            </div>
          </div>
          {error && (
            <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
              <X size={16} className="text-red-500" />
              <p className="text-stone-800 text-xs">{error}</p>
            </div>
          )}

          <form className="flex flex-col gap-2 items-center w-full bg-white p-2 rounded-md border border-gray-200">
            <input
              type="password"
              placeholder="New password"
              className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent focus:ring-0 text-stone-800 hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              value={password}
            />
            {isFocused && (
              <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md p-2">
                <p className="text-stone-800 text-xs">Password must contain:</p>
                <p className="text-stone-600 text-xs">
                  Minimum of 8 characters
                </p>
                <p className="text-stone-600 text-xs">At least one uppercase</p>
                <p className="text-stone-600 text-xs">
                  At least one special character
                </p>
                <p className="text-stone-600 text-xs">At least one number</p>
              </div>
            )}
            <input
              type="password"
              placeholder="Confirm password"
              className="border text-xs border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 outline-0 outline w-full rounded-md p-2"
              onChange={(e) => setPasswordTwo(e.target.value)}
              value={passwordTwo}
            />

            {resetting ? (
              <div className="w-full flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <button
                  disabled={resetting || remainingTime === 0}
                  className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs"
                  onClick={handleSubmitPassword}
                >
                  Reset Password
                </button>
              </>
            )}
          </form>
          <div className="flex items-center justify-start gap-1">
            <p className="text-stone-600 text-xs">
              This link expires in{' '}
              <span className="font-bold">{remainingTime}</span>{' '}
              {remainingTime === 1 ? 'minute' : 'minutes'}
            </p>
            <Clock
              size={14}
              className={remainingTime < 6 ? 'text-red-400' : 'text-stone-800'}
            />
          </div>
        </div>
      )
    ) : isMobile ? (
      <div className="h-full flex items-center justify-center p-4">
        <div className="border border-gray-200 rounded-md p-4 flex flex-col gap-4 items-center justify-center mx-auto w-full">
          <Frown size={20} />
          <p className="text-stone-800 text-sm">Link expired!</p>
          <p className="text-stone-600 text-xs text-center">
            This link is expired and is no longer valid. Request another reset
            link below.
          </p>
          <Link
            to="/reset/password"
            className="flex items-center justify-center bg-gray-200 p-2 rounded-md text-xs text-stone-800"
          >
            Request Link
          </Link>
        </div>
      </div>
    ) : (
      <div className="h-full flex items-center justify-center">
        <div
          className="border border-gray-200 rounded-md p-4 flex flex-col gap-4 items-center justify-center mx-auto"
          style={{ width: '400px' }}
        >
          <Frown size={20} />
          <p className="text-stone-800 text-sm">Link expired!</p>
          <p className="text-stone-600 text-xs text-center">
            This link is expired and is no longer valid. Request another reset
            link below.
          </p>
          <Link
            to="/reset/password"
            className="flex items-center justify-center bg-gray-200 p-2 rounded-md text-xs text-stone-800"
          >
            Request Link
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto h-screen">{content}</div>
      <Footer />
    </>
  );
};

export default PasswordReset;
