import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { ChevronRight, Map, ShoppingBag } from 'react-feather';
import { Link } from 'react-router-dom';

const MiniNav = ({ product, showSales }) => {
  return isMobile ? (
    <div className="w-full h-full mx-auto flex justify-between items-center border border-gray-200 rounded-md p-2">
      {/* logo section */}
      <div>
        <Link to="/" className="h-full flex gap-1">
          <Map size={18} />
          <p
            className="font-black text-stone-800 text-md"
            style={{ fontFamily: 'Inter, sans-serif' }}
          >
            Fruntt
          </p>
        </Link>
      </div>

      <Link to={`/${product?.pageName}`} className="h-full flex items-center">
        <p className="text-stone-800 text-xs">Creator</p>
        <ChevronRight size={14} />
      </Link>
    </div>
  ) : (
    <div className="w-full h-full mx-auto flex justify-between items-center border border-gray-200 rounded-md mt-8 p-2">
      {/* logo section */}
      <div>
        <Link to="/" className="h-full flex gap-1">
          <Map size={18} />
          <p
            className="font-black text-stone-800 text-md"
            style={{ fontFamily: 'Inter, sans-serif' }}
          >
            Fruntt
          </p>
        </Link>
      </div>

      {showSales && product?.numberOfSales > 0 && (
        <div className="flex items-center justify-start gap-2 border border-gray-200 bg-gray-50 rounded-md p-1">
          <ShoppingBag size={14} />
          <p className="text-stone-600 text-xs font-bold">
            Item has been sold{' '}
            {product?.numberOfSales == 1
              ? '1 time'
              : `${product?.numberOfSales} times`}
          </p>
        </div>
      )}
      <Link to={`/${product?.pageName}`} className="h-full flex items-center">
        <p className="text-stone-800 text-xs">Creator</p>
        <ChevronRight size={14} />
      </Link>
    </div>
  );
};

export default MiniNav;
