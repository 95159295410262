import React, { useEffect, useState } from 'react';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import {
  useGetDigitalOrderQuery,
  useAddProductReviewMutation,
  useMarkAsViewedMutation,
} from '../../../api/ordersApiSlice';
import Spinner from '../../../components/Spinner';
import { isMobile } from 'react-device-detect';
import MobileDownload from './MobileDownload';
import Cookies from 'js-cookie';
import DesktopDownload from './DesktopDownload';
import InvalidOrder from './InvalidOrder';

const Download = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('oat');
  const { orderId } = useParams();

  const [open, setOpen] = useState(false);
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);

  //get order, as long as it is paid for
  const {
    data: orderAndStore,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useGetDigitalOrderQuery({
    orderId,
    token,
  });

  const [addProductReview, { isLoading: isSubmitting }] =
    useAddProductReviewMutation();

  useEffect(() => {
    refetch();
  }, []);

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    try {
      const addReviewReq = await addProductReview({
        review: review,
        rating: rating,
        orderId: orderAndStore?.order?._id,
        email: orderAndStore?.order?.email,
      }).unwrap();

      if (addReviewReq === 'Review added') {
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };

  let content;
  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = isMobile ? (
      <MobileDownload
        orderAndStore={orderAndStore}
        setReview={setReview}
        setRating={setRating}
        handleSubmitReview={handleSubmitReview}
        open={open}
        setOpen={setOpen}
        isSubmitting={isSubmitting}
      />
    ) : (
      <DesktopDownload
        orderAndStore={orderAndStore}
        setReview={setReview}
        setRating={setRating}
        handleSubmitReview={handleSubmitReview}
        open={open}
        setOpen={setOpen}
        isSubmitting={isSubmitting}
      />
    );
  } else if (error) {
    if (error?.status === 403) {
      content = <InvalidOrder />;
    }
  }

  return (
    <>
      <div className="mx-auto h-auto mb-10">{content}</div>
    </>
  );
};

export default Download;
