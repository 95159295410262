import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useChangePasswordMutation } from '../../api/authApiSlice';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/toastSlice';
import { X } from 'react-feather';

const PasswordChange = ({ setChange, change }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newPass, setNewPass] = useState('');
  const [oldPass, setOldPass] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);
  const [error, setError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [valid, setValid] = useState(false);

  const [changePassword, result] = useChangePasswordMutation();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    setChangingPassword(true);

    if (!oldPass || !newPass) {
      setError('Please fill in both fields');
      return;
    }

    //password regex
    const regex =
      /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])\S{8,}$/;
    if (!regex.test(newPass)) {
      setError('New password must match requirements');
      setIsFocused(true);
      setChangingPassword(false);
      return;
    }

    try {
      const changePassReq = await changePassword({
        newPassword: newPass,
        oldPassword: oldPass,
      }).unwrap();

      console.log(changePassReq);

      if (changePassReq === 'Password changed') {
        dispatch(showNotification('Password changed'));
        setChangingPassword(false);
        setChange(false);
      } else if (changePassReq === 'Invalid password') {
        setError('Current password is invalid');
        setChangingPassword(false);
      } else if (changePassReq === 'Same password') {
        setError('Cannot use current password');
        setChangingPassword(false);
      }
    } catch (err) {
      setError('There was a server error');
      setChangingPassword(false);
    }
  };

  useEffect(() => {
    setError('');
  }, [oldPass, newPass]);

  return (
    <>
      <div className="w-full mx-auto">
        <div className="flex flex-col w-full">
          <div>
            <div className="flex items-center justify-between w-full mb-2">
              <div className="flex flex-col items-start">
                <p className="text-sm text-stone-800">Change password</p>
                <p className="text-xs text-stone-600">
                  Use the form below to change your account password
                </p>
              </div>

              <div className="flex items-center gap-2">
                <button
                  type="button"
                  className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                  onClick={(e) => setChange(!change)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                  onClick={handleChangePassword}
                  disabled={changingPassword}
                >
                  Change
                </button>
              </div>
            </div>
            <form className="flex flex-col gap-2 w-96">
              {error && (
                <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                  <X size={16} className="text-red-500" />
                  <p className="text-stone-800 text-xs">{error}</p>
                </div>
              )}

              <input
                type="password"
                className="border text-xs border-gray-200 hover:border-gray-200 hover:bg-gray-200 bg-gray-50 focus:border-gray-200 focus:bg-gray-200 rounded-md p-2"
                placeholder="Current password"
                onChange={(e) => setOldPass(e.target.value)}
                value={oldPass}
              />

              <input
                type="password"
                className="border text-xs border-gray-200 hover:border-gray-200 hover:bg-gray-200 focus:border-gray-200 focus:bg-gray-200 bg-gray-50 outline outline-0 rounded-md p-2"
                placeholder="New password"
                onChange={(e) => setNewPass(e.target.value)}
                value={newPass}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              {isFocused && (
                <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md p-2">
                  <p className="text-stone-800 text-xs">
                    Password must contain:
                  </p>
                  <p className="text-stone-600 text-xs">
                    Minimum of 8 characters
                  </p>
                  <p className="text-stone-600 text-xs">
                    At least one uppercase
                  </p>
                  <p className="text-stone-600 text-xs">
                    At least one special character
                  </p>
                  <p className="text-stone-600 text-xs">At least one number</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
