import { apiSlice } from './apiSlice';
import { createSelector, createEntityAdapter } from '@reduxjs/toolkit';

export const productsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ storeId }) => `/products/client/${storeId}`,
    }),
    getMarketProducts: builder.query({
      query: ({ filter }) => `/products/market/${filter}`,
    }),
    getFeaturedProducts: builder.query({
      query: () => `/products/featured/c`,
    }),
    getProduct: builder.query({
      query: ({ productId }) => `/products/${productId}`,
    }),
    getProductByUrl: builder.query({
      query: ({ productUrl, pageUrl }) =>
        `/products/product/${pageUrl}/${productUrl}`,
    }),
    getProductReviews: builder.query({
      query: ({ productId }) => `/products/reviews/${productId}`,
    }),
    getProductDiscounts: builder.query({
      query: ({ productId }) => `/discounts/get/${productId}`,
    }),
    addProduct: builder.mutation({
      query: (product) => ({
        url: '/products/create',
        method: 'POST',
        body: { ...product },
      }),
    }),
    addDiscount: builder.mutation({
      query: ({
        code,
        percent,
        amount,
        expDate,
        limit,
        maxLimit,
        productId,
      }) => ({
        url: '/discounts/create',
        method: 'POST',
        body: {
          productId: productId,
          code: code,
          percent: percent,
          limit: limit,
          maxLimit: maxLimit,
          amount: amount,
          expDate: expDate,
        },
      }),
    }),
    editDiscount: builder.mutation({
      query: ({
        code,
        percent,
        amount,
        expDate,
        limit,
        maxLimit,
        discountId,
      }) => ({
        url: '/discounts/edit',
        method: 'POST',
        body: {
          discountId: discountId,
          code: code,
          percent: percent,
          limit: limit,
          maxLimit: maxLimit,
          amount: amount,
          expDate: expDate,
        },
      }),
    }),
    applyDiscount: builder.mutation({
      query: ({ productId, code, amount }) => ({
        url: '/discounts/apply',
        method: 'POST',
        body: {
          productId: productId,
          code: code,
          amount: amount,
        },
      }),
    }),
    deleteDiscount: builder.mutation({
      query: ({ discountId }) => ({
        url: '/discounts/delete',
        method: 'POST',
        body: {
          discountId: discountId,
        },
      }),
    }),
    addDigitalProduct: builder.mutation({
      query: (product) => ({
        url: '/products/create/digital',
        method: 'POST',
        body: { ...product },
      }),
    }),

    updateDigitalProduct: builder.mutation({
      query: ({
        productId,
        price,
        title,
        description,
        published,
        coverImageUrl,
        coverImageKey,
        coverImages,
        files,
        digitalType,
        content,
        info,
        payChoice,
        suggestedPrice,
        callToAction,
        url,
        free,
        marketplace,
        categories,
        platform,
        dupLink,
        unpublish,
        versions,
        urlChanged,
      }) => ({
        url: `/products/editdigital/${productId}`,
        method: 'POST',
        body: {
          title: title,
          description: description,
          price: price,
          published: published,
          coverImageUrl: coverImageUrl,
          coverImageKey: coverImageKey,
          files: files,
          digitalType: digitalType,
          content: content,
          info: info,
          payChoice: payChoice,
          suggestedPrice: suggestedPrice,
          callToAction: callToAction,
          url: url,
          free: free,
          marketplace: marketplace,
          categories: categories,
          coverImages: coverImages,
          platform: platform,
          dupLink: dupLink,
          unpublish: unpublish,
          versions: versions,
          urlChanged: urlChanged,
        },
      }),
    }),
    deleteProduct: builder.mutation({
      query: (productId) => ({
        url: `/products/delete/${productId}`,
        method: 'DELETE',
      }),
    }),
    deleteItemImage: builder.mutation({
      query: ({ productId, imgId, key }) => ({
        url: `/products/image/delete/`,
        method: 'POST',
        body: {
          productId: productId,
          imgId: imgId,
          key: key,
        },
      }),
    }),
    deleteFile: builder.mutation({
      query: ({ productId, fileId, key }) => ({
        url: `/products/file/delete/`,
        method: 'POST',
        body: {
          productId: productId,
          fileId: fileId,
          key: key,
        },
      }),
    }),
    getItemImages: builder.query({
      query: (productId) => `/products/images/${productId}`,
      keepUnusedDataFor: 5,
    }),
    getCoverImage: builder.query({
      query: (productId) => `/products/coverimage/${productId}`,
    }),
    getFiles: builder.query({
      query: (productId) => `/products/files/${productId}`,
    }),
    addFAQ: builder.mutation({
      query: ({ productId, question, answer }) => ({
        url: `/products/addfaq`,
        method: 'POST',
        body: {
          productId: productId,
          question: question,
          answer: answer,
        },
      }),
    }),
    addDescription: builder.mutation({
      query: ({ productId, description }) => ({
        url: `/products/description/add`,
        method: 'POST',
        body: {
          productId: productId,
          description: description,
        },
      }),
    }),
    deleteFAQ: builder.mutation({
      query: ({ productId, faqId }) => ({
        url: `/products/deletefaq`,
        method: 'POST',
        body: {
          productId: productId,
          faqId: faqId,
        },
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductQuery,
  useAddProductMutation,
  useAddDigitalProductMutation,
  useDeleteProductMutation,
  useUpdateDigitalProductMutation,
  useDeleteItemImageMutation,
  useGetItemImagesQuery,
  useGetCoverImageQuery,
  useAddFAQMutation,
  useDeleteFAQMutation,
  useGetFilesQuery,
  useDeleteFileMutation,
  useAddDescriptionMutation,
  useGetMarketProductsQuery,
  useGetFeaturedProductsQuery,
  useGetProductByUrlQuery,
  useGetProductReviewsQuery,
  useAddDiscountMutation,
  useGetProductDiscountsQuery,
  useEditDiscountMutation,
  useDeleteDiscountMutation,
  useApplyDiscountMutation,
} = productsApiSlice;
