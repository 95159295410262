import { apiSlice } from './apiSlice';

export const demoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDemos: builder.query({
      query: () => `/demos/all`,
      keepUnusedDataFor: 5,
    }),
    getDemo: builder.query({
      query: ({ demoId }) => `/demos/d/${demoId}`,
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetDemoQuery, useGetDemosQuery } = demoApiSlice;
