import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { X } from 'react-feather';
import { Spinner } from 'flowbite-react';

//mui
import Alert from '@mui/material/Alert';
import { FcGoogle } from 'react-icons/fc';

const RegisterMobile = ({
  error,
  setEmail,
  setPassword,
  signupWithGoogle,
  setFirstName,
  setLastName,
  setBio,
  bio,
  firstName,
  lastName,
  handleSignup,
  handleTwitterSignup,
  state,
  setStoreName,
  profilePic,
  setProfilePic,
  storeName,
  isLoading,
  password,
  email,
  registering,
  pageTaken,
  isFocused,
  setIsFocused,
}) => {
  //auto scrolls to top of page
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="w-full p-4 flex flex-col items-center">
      <div className="flex flex-col gap-2 mx-auto items-center justify-center w-full">
        <div className="flex flex-col items-start w-full">
          <p className="text-lg text-stone-800">Ready to sell?</p>
          <p className="text-stone-600 text-xs">
            Signup below to start selling your project links
          </p>
        </div>

        {error && (
          <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
            <X size={16} className="text-red-500" />
            <p className="text-stone-800 text-xs">{error}</p>
          </div>
        )}

        {state?.msg && (
          <Alert severity="error" className="mt-2 mb-2 w-full">
            {state?.msg}
          </Alert>
        )}

        <form
          onSubmit={handleSignup}
          className="flex flex-col gap-2 items-center w-full bg-white p-2 rounded-md border border-gray-200"
        >
          <button
            type="button"
            onClick={signupWithGoogle}
            className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs flex items-center justify-center gap-1"
            // onMouseOver={() => setShowTooltip(true)}
            // onMouseOut={() => setShowTooltip(false)}
          >
            <FcGoogle className="text-lg" /> Google
            {/* {showTooltip ? (
                'Coming soon!'
              ) : (
                <>
                  <FcGoogle className="text-lg" /> Google
                </>
              )} */}
          </button>
          <div className="flex items-center w-full">
            <div className="flex-grow border-t w-full border-gray-200 h-0"></div>
            <span className="mx-4 text-xs text-stone-800">or</span>
            <div className="flex-grow border-t w-full border-gray-200 h-0"></div>
          </div>

          <input
            type="email"
            placeholder="Email"
            className="border text-xs border-gray-200 bg-gray-50 hover:border-gray-200 hover:bg-gray-200 outline outline-0 focus:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <input
            type="password"
            placeholder="Password"
            className="border text-xs outline outline-0 border-gray-200 bg-gray-50 hover:border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-full rounded-md p-2"
            onChange={(e) => setPassword(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={password}
          />
          {isFocused && (
            <div className="w-full flex flex-col items-start gap-2 border border-gray-200 rounded-md p-2">
              <p className="text-stone-800 text-xs">Password must contain:</p>
              <p className="text-stone-600 text-xs">Minimum of 8 characters</p>
              <p className="text-stone-600 text-xs">At least one uppercase</p>
              <p className="text-stone-600 text-xs">
                At least one special character
              </p>
              <p className="text-stone-600 text-xs">At least one number</p>
            </div>
          )}
          {pageTaken && (
            <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
              <X size={16} className="text-red-500" />
              <p className="text-stone-800 text-xs">Page name taken</p>
            </div>
          )}
          <div className="flex w-full">
            <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
              <p className="text-xs">fruntt.com/</p>
            </div>
            <input
              type="text"
              placeholder="Page name"
              className="border text-xs border-gray-200 border-l-0 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1"
              onChange={(e) => setStoreName(e.target.value)}
              value={storeName}
            />
          </div>

          {registering ? (
            <div className="w-full flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <button
                type="submit"
                disabled={isLoading}
                className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs"
              >
                Signup
              </button>
              {/* <p className='text-xs text-stone-600'>OR</p>
              <button
                style={{
                  backgroundColor: 'rgb(15, 20, 25)',
                  borderColor: 'rgb(15, 20, 25)',
                }}
                className='w-full h-10 text-xs rounded-md flex items-center justify-center text-white'
                type='button'
                onClick={handleTwitterSignup}
              >
                <p className='mr-2'>Signup with</p>
                <FaXTwitter />
              </button> */}
            </>
          )}
          <div className="flex w-full">
            <Link to="/login">
              <p className="text-xs self-start text-stone-600">
                Already have an account? Login here.
              </p>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterMobile;
