import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import {
  useGetFeaturedProductsQuery,
  useGetMarketProductsQuery,
} from '../../../api/productsApiSlice';
import Spinner from '../../../components/Spinner';
import Desktop from './Desktop';
import Cookies from 'js-cookie';
import Mobile from './Mobile';

const Marketplace = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [filter, setFilter] = useState('all');

  const {
    data: products,
    isLoading,
    isSuccess,
    refetch,
  } = useGetMarketProductsQuery({ filter });

  const {
    data: items,
    isLoading: gettingProducts,
    isSuccess: gotProducts,
  } = useGetFeaturedProductsQuery();

  const handleFilterChange = (filter) => {
    setFilter(filter);
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const marketClass = currentUser
    ? `max-w-7xl mx-auto h-fit mt-2`
    : `max-w-7xl mx-auto h-fit mt-24`;

  let content;

  if (isLoading || gettingProducts) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = isMobile ? (
      <Mobile
        products={products}
        handleFilterChange={handleFilterChange}
        filter={filter}
        items={items}
        gotProducts={gotProducts}
        gettingProducts={gettingProducts}
      />
    ) : (
      <Desktop
        products={products}
        handleFilterChange={handleFilterChange}
        filter={filter}
        items={items}
        gotProducts={gotProducts}
        gettingProducts={gettingProducts}
      />
    );
  }

  return (
    <>
      <Navbar />
      <div className="">{content}</div>
      <Footer />
    </>
  );
};

export default Marketplace;
