import { Alert, Checkbox, Label, Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import {
  useCheckPageMutation,
  useUpdatePageMutation,
} from '../../api/storefrontApiSlice';
import { Edit, X, XCircle } from 'react-feather';
import { showNotification } from '../../redux/toastSlice';
import { useDispatch } from 'react-redux';

const Page = ({ user, isFetching, refetch }) => {
  const dispatch = useDispatch();
  const [pageName, setPageName] = useState(user?.store?.name);
  const [filtering, setFiltering] = useState(user?.store?.allowFiltering);
  const [sales, setSales] = useState(user?.store?.showSales);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');
  const [updatingPage, setUpdatingPage] = useState(false);
  const [taken, setTaken] = useState(false);

  const [updatePage, result] = useUpdatePageMutation();
  const [checkPage, { result: res }] = useCheckPageMutation();

  const handleCancelEdit = () => {
    setPageName(user?.store?.name);
    setEdit(false);
  };

  const handleUpdatePage = async (e) => {
    e.preventDefault();

    setUpdatingPage(true);

    //check if the user changed the name
    const sameName = user?.store?.name === pageName ? true : false;

    try {
      if (!sameName) {
        const checkPageReq = await checkPage({ pageName: pageName }).unwrap();

        if (checkPageReq === 'Name taken') {
          setTaken(true);
          setUpdatingPage(false);
          return;
        }
      }

      const updateReq = await updatePage({
        storeId: user?.store?._id,
        pageName: pageName,
        filtering: filtering,
        sales: sales,
      }).unwrap();

      if (updateReq === 'Page updated') {
        refetch();
        setEdit(false);
        setUpdatingPage(false);
        dispatch(showNotification('Page updated'));
        return;
      }
    } catch (err) {
      setUpdatingPage(false);
      setError('There was an error');
    }
  };

  useEffect(() => {
    setTaken(false);
  }, [pageName]);

  return isFetching ? (
    <div className="w-full h-72 flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="w-full border border-gray-200 rounded-md p-4 flex flex-col gap-2">
      {edit ? (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-stone-800">Edit page</p>
              <p className="text-xs text-stone-600">
                Change page name and adjust other settings on your public page
              </p>
            </div>

            <div className="flex items-center gap-2">
              <button
                type="button"
                className="hover:bg-red-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={handleCancelEdit}
              >
                Cancel
              </button>
              <button
                type="button"
                className="bg-gray-200 text-stone-800 rounded-md p-1 pl-2 pr-2 text-xs"
                onClick={handleUpdatePage}
                disabled={updatingPage}
              >
                Save
              </button>
            </div>
          </div>

          <form className="flex flex-col items-start gap-1">
            {taken && (
              <div className="w-96 flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                <X size={16} className="text-red-500" />
                <p className="text-stone-800 text-xs">Page name taken</p>
              </div>
            )}
            {error ? <Alert color="failure">There was an error</Alert> : ''}
            <div className="flex flex-col w-full items-start">
              <div className="flex w-96">
                <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-200 flex items-center p-2 pr-1">
                  <p className="text-xs">fruntt.com/</p>
                </div>
                <input
                  type="text"
                  placeholder="Page name"
                  className="border text-xs border-gray-200 bg-gray-50 border-l-0 focus:bg-gray-200 focus:border-gray-200 hover:bg-gray-200 rounded-tr-md rounded-br-md p-2 pl-1 flex-1"
                  onChange={(e) => setPageName(e.target.value)}
                  value={pageName}
                />
              </div>
              {/* <input
                type="text"
                value={pageName}
                onChange={(e) => setPageName(e.target.value)}
                className="bg-gray-50 rounded-md text-xs border-gray-200 hover:bg-gray-200 focus:bg-gray-200 focus:border-gray-200 w-96"
                placeholder="Page name"
              />
              <p className="text-xs text-stone-600">{`fruntt.com/${pageName}`}</p> */}
            </div>
          </form>

          <div className="flex flex-col gap-6 mt-4">
            <div className="flex items-start gap-2">
              <Checkbox
                checked={filtering}
                onChange={(e) => setFiltering(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              />
              <div className="flex flex-col">
                <p className="text-xs text-stone-800">Allow item filtering</p>
                <p className="text-xs text-stone-600">
                  Allow visitors to filter through your items by paid, free, and
                  categories.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-2">
              <Checkbox
                checked={sales}
                onChange={(e) => setSales(e.target.checked)}
                className="text-xs w-3.5 h-3.5"
              />
              <div className="flex flex-col">
                <p className="text-xs text-stone-800">Show item sales</p>
                <p className="text-xs text-stone-600">
                  Show how many times an item has been sold. This only displays
                  if the item has at least one sale.
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-1">
              <p className="text-sm text-stone-800">Page</p>
              <p className="text-xs text-stone-600">
                Change page name and adjust other settings on your public page
              </p>
            </div>

            <button
              type="button"
              className="text-stone-800"
              onClick={(e) => setEdit(!edit)}
            >
              <Edit size={14} />
            </button>
          </div>

          <div className="flex w-96">
            <div className="rounded-tl-md rounded-bl-md bg-gray-50 border border-r-0 border-gray-50 flex items-center p-2 pr-1">
              <p className="text-xs">fruntt.com/</p>
            </div>
            <input
              type="text"
              placeholder="Page name"
              className="border text-xs border-gray-50 bg-gray-50 border-l-0 rounded-tr-md rounded-br-md p-2 pl-1 flex-1"
              disabled
              value={user?.store?.name}
            />
          </div>

          <div className="flex flex-col gap-6 mt-4">
            <div className="flex items-center gap-2">
              <Checkbox
                color="gray"
                disabled
                checked={user?.store?.allowFiltering}
                className="text-xs w-3.5 h-3.5"
              />
              <p className="text-xs text-stone-800">Allow item filtering</p>
            </div>

            <div className="flex items-center gap-2">
              <Checkbox
                color="gray"
                disabled
                checked={user?.store?.showSales}
                className="text-xs w-3.5 h-3.5"
              />
              <p className="text-xs text-stone-800">Show item sales</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Page;
