import React, { useEffect, useState } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import SideNavbar from '../../../components/SideNavbar';
import Cookies from 'js-cookie';
import Footer from '../../../components/Footer/Footer';
import { isMobile } from 'react-device-detect';
import { useGetStoreStatsQuery } from '../../../api/storefrontApiSlice';
import DashHomeMobile from './DashHomeMobile';
import Select from 'react-select';
import SellerProfile from '../../../components/Settings/SellerProfile';
import { useGetUpdatedUserQuery } from '../../../api/authApiSlice';
import { Info } from 'react-feather';
import SalesGraph from '../../../components/SalesGraph';

//flowbite
import { Tooltip, Checkbox, Spinner } from 'flowbite-react';

const DashHome = () => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [dataView, setDataView] = useState('today');

  const viewOptions = [
    { value: 'today', label: 'Past 24 hours' },
    { value: 'seven', label: 'Past 7 days' },
    { value: 'thirty', label: 'Past 30 days' },
  ];

  const {
    data: stats,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetStoreStatsQuery({
    storeId: currentUser?.store?._id,
    view: dataView,
  });

  const {
    data: user,
    isLoading: gettingUser,
    isError: errorGettingUser,
    isSuccess: gotUser,
    refetch: refetchUser,
  } = useGetUpdatedUserQuery();

  const formattedViewValue = viewOptions.find(
    (option) => option.value === dataView
  );

  const handleView = (value) => {
    setDataView(value.value);
    refetch();
  };

  useEffect(() => {
    refetch();
    refetchUser();
  }, []);

  let content;

  if (isLoading || gettingUser || isFetching) {
    content = (
      <div className="w-full h-96 flex items-center justify-center">
        <Spinner />
      </div>
    );
  } else if (isSuccess && gotUser) {
    content = isMobile ? (
      <DashHomeMobile
        currentUser={currentUser}
        stats={stats}
        dataView={dataView}
        refetchUser={refetchUser}
        user={user}
      />
    ) : (
      <div className="flex flex-col gap-2">
        {/* <div className="flex">
          <div className="flex flex-col bg-white">
            <Select
              options={viewOptions}
              onChange={handleView}
              value={formattedViewValue}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              isSearchable={false}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: 'rgb(229 231 235)',
                  borderWidth: 1,
                  '&:hover': {
                    borderColor: 'rgb(229 231 235)', // Keep the same border color on hover
                  },
                  borderRadius: '.375rem',
                  boxShadow: 'none',
                  zIndex: 99999,
                  position: 'relative',
                  fontSize: '12px',
                }),
                menuPortal: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  fontSize: '12px',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isSelected
                    ? 'rgb(229 231 235)'
                    : state.isFocused
                    ? 'rgb(249 250 251)'
                    : '',
                  color: 'black',
                }),
              }}
              className="w-44 text-sm"
            />
          </div>
        </div> */}
        {isLoading ? (
          <div className="h-96 w-full flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex items-end gap-2 w-full">
              <div className="bg-white border border-gray-200 rounded-md w-8/12 p-2 h-28">
                <SellerProfile user={user} refetch={refetchUser} />
              </div>
              <div className="bg-white w-4/12 flex items-center justify-end gap-2">
                <button
                  type="button"
                  className={`${
                    dataView === 'thirty'
                      ? 'bg-gray-200'
                      : 'bg-white hover:bg-gray-200'
                  } text-xs text-stone-800 rounded-md p-1 pr-2 pl-2`}
                  onClick={() => setDataView('thirty')}
                >
                  30D
                </button>
                <button
                  type="button"
                  className={`${
                    dataView === 'seven'
                      ? 'bg-gray-200'
                      : 'bg-white hover:bg-gray-200'
                  } text-xs text-stone-800 rounded-md p-1 pr-2 pl-2`}
                  onClick={() => setDataView('seven')}
                >
                  7D
                </button>
                <button
                  type="button"
                  className={`${
                    dataView === 'today'
                      ? 'bg-gray-200'
                      : 'bg-white hover:bg-gray-200'
                  } text-xs text-stone-800 rounded-md p-1 pr-2 pl-2`}
                  onClick={() => setDataView('today')}
                >
                  24h
                </button>
              </div>
            </div>

            <div className="grid grid-cols-5 gap-2">
              <div className="bg-white border border-gray-200 rounded-md p-2 h-24 relative">
                <div className="absolute right-0 mr-1">
                  <Tooltip
                    content={
                      <p className="text-xs text-stone-800">
                        {`Total revenue ${
                          dataView === 'today'
                            ? 'in the past 24 hours'
                            : dataView === 'seven'
                            ? 'in the past 7 days'
                            : dataView === 'thirty'
                            ? 'in the past 30 days'
                            : 'since you signed up'
                        }`}
                      </p>
                    }
                    style="light"
                    className="w-52"
                    arrow={false}
                  >
                    <Info size={14} />
                  </Tooltip>
                </div>
                <p className="text-xs text-stone-600">Revenue</p>
                <p className="text-lg font-medium text-slate-800 mt-1 flex items-center">
                  {/* {currentUser?.currency?.symbol} */}$
                  {stats?.revenue > 0
                    ? stats?.revenue.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : '0'}
                </p>
              </div>
              <div className="h-24 bg-white border-gray-200 rounded-md p-2 relative border">
                <div className="absolute right-0 mr-1">
                  <Tooltip
                    content={
                      <p className="text-xs text-stone-800">{`Total number of sales ${
                        dataView === 'today'
                          ? 'in the past 24 hours'
                          : dataView === 'seven'
                          ? 'in the past 7 days'
                          : dataView === 'thirty'
                          ? 'in the past 30 days'
                          : 'since you signed up'
                      }`}</p>
                    }
                    style="light"
                    className="w-52"
                    arrow={false}
                  >
                    <Info size={14} />
                  </Tooltip>
                </div>
                <p className="text-xs text-stone-600">Sales</p>
                <p className="text-lg mt-1 font-medium text-stone-800">
                  {stats?.numOfOrders}
                </p>
              </div>

              <div className="h-24 bg-white border-gray-200 rounded-md p-2 relative border">
                <div className="absolute right-0 mr-1">
                  <Tooltip
                    content={
                      <p className="text-xs text-stone-800">{`Total number of visits ${
                        dataView === 'today'
                          ? 'in the past 24 hours'
                          : dataView === 'seven'
                          ? 'in the past 7 days'
                          : dataView === 'thirty'
                          ? 'in the past 30 days'
                          : 'since you signed up'
                      }`}</p>
                    }
                    style="light"
                    className="w-52"
                    arrow={false}
                  >
                    <Info size={14} />
                  </Tooltip>
                </div>
                <p className="text-xs text-stone-600">Visits</p>
                <p className="text-lg font-medium text-slate-800 mt-1">
                  {stats?.visits}
                </p>
              </div>

              <div className="border-gray-200 h-24 bg-white rounded-md p-2 relative border">
                <div className="absolute right-0 mr-1">
                  <Tooltip
                    content={
                      <p className="text-xs text-stone-800">{`Total number of customers ${
                        dataView === 'today'
                          ? 'in the past 24 hours'
                          : dataView === 'seven'
                          ? 'in the past 7 days'
                          : dataView === 'thirty'
                          ? 'in the past 30 days'
                          : 'since you signed up'
                      }`}</p>
                    }
                    style="light"
                    className="w-52"
                    arrow={false}
                  >
                    <Info size={14} />
                  </Tooltip>
                </div>
                <p className="text-xs text-stone-600">Customers</p>
                <p className="text-lg font-medium mt-1">
                  {stats?.numberOfCustomers > 0
                    ? stats?.numberOfCustomers
                    : '0'}
                </p>
              </div>

              <div className="border-gray-200 h-24 bg-white rounded-md p-2 relative border">
                <div className="absolute right-0 mr-1">
                  <Tooltip
                    content={
                      <p className="text-xs text-stone-800">{`Percentage of vistors who made a purchase ${
                        dataView === 'today'
                          ? 'in the past 24 hours'
                          : dataView === 'seven'
                          ? 'in the past 7 days'
                          : dataView === 'thirty'
                          ? 'in the past 30 days'
                          : 'since you signed up'
                      }`}</p>
                    }
                    style="light"
                    className="w-52"
                    arrow={false}
                  >
                    <Info size={14} />
                  </Tooltip>
                </div>
                <p className="text-xs text-stone-600">Conversion</p>
                <p className="text-lg font-medium mt-1">
                  {stats?.conversion > 0 ? stats?.conversion.toFixed(0) : '0'}%
                </p>
              </div>
            </div>

            <div className="w-full border border-gray-200 rounded-md bg-white flex items-center justify-center">
              {/* <div className='h-72 w-full flex flex-col items-center justify-center'>
                <p className=' text-stone-800 text-sm'>
                  View all sales data here
                </p>
                <p className='text-xs text-stone-600'>Display coming soon</p>
              </div> */}

              <SalesGraph dataset={stats?.dataSet} view={dataView} />
            </div>

            <div className="w-full bg-white border border-gray-200 rounded-md flex flex-col gap-2 p-2">
              <p className="text-stone-600 text-xs">
                Are you ready for customers?
              </p>

              <div className="w-full flex justify-between">
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={currentUser?.emailConfirmed}
                    disabled
                    color={'gray'}
                    className="text-xs w-3.5 h-3.5"
                  />
                  <p className="text-stone-600 text-xs">Email confirmed</p>
                </div>

                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={currentUser?.setup}
                    disabled
                    color={'gray'}
                    className="text-xs w-3.5 h-3.5"
                  />
                  <p className="text-stone-600 text-xs">Setup account</p>
                </div>

                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={
                      currentUser?.stripeOnboard || currentUser?.bankAdded
                    }
                    disabled
                    color={'gray'}
                    className="text-xs w-3.5 h-3.5"
                  />
                  <p className="text-stone-600 text-xs">
                    Connect payout option
                  </p>
                </div>

                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={currentUser?.productAdded}
                    disabled
                    color={'gray'}
                    className="text-xs w-3.5 h-3.5"
                  />
                  <p className="text-stone-600 text-xs">Submit item</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  const styles = isMobile
    ? 'w-full mx-auto h-fit p-2 bg-gray-50'
    : 'w-full mx-auto h-screen bg-white ml-2';

  return (
    <>
      <Navbar />
      <div className="flex max-w-6xl mx-auto">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default DashHome;
