import React, { useEffect } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import { useGetUsersOrdersQuery } from '../../../api/ordersApiSlice';
import Spinner from '../../../components/Spinner';
import { isMobile } from 'react-device-detect';
import LibraryMobile from './LibraryMobile';
import LibraryDesktop from './LibraryDesktop';
import SideNavbar from '../../../components/SideNavbar';

const Library = () => {
  const {
    data: orders,
    isLoading,
    isSuccess,
    refetch,
  } = useGetUsersOrdersQuery();

  useEffect(() => {
    refetch();
  }, []);

  let content;

  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = isMobile ? (
      <LibraryMobile orders={orders} />
    ) : (
      <LibraryDesktop orders={orders} />
    );
  }

  const styles = isMobile
    ? 'mx-auto w-full p-2 bg-gray-50 h-screen mt-14'
    : 'mx-auto w-full bg-white h-screen ml-2';

  return (
    <>
      <Navbar />
      <div className="flex mx-auto max-w-6xl">
        <SideNavbar />
        <div className={styles}>{content}</div>
      </div>
      <Footer />
    </>
  );
};

export default Library;
