import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import MobileStore from './MobileStore';
import DesktopStore from './DesktopStore';
import { Link, useParams } from 'react-router-dom';
import {
  useAddVisitMutation,
  useGetStorefrontByURLQuery,
} from '../../../api/storefrontApiSlice';
import Spinner from '../../../components/Spinner';
import Cookies from 'js-cookie';
import { Map } from 'react-feather';

const CreatorDetail = () => {
  const { storeUrl } = useParams();

  //hook for getting store and items from url param
  const {
    data: storeAndItems,
    isLoading,
    isSuccess,
    refetch,
  } = useGetStorefrontByURLQuery({ storeUrl: storeUrl });

  let content;

  if (isLoading) {
    content = <Spinner />;
  } else if (isSuccess) {
    content = isMobile ? (
      <div className="flex flex-col items-center h-fit">
        <MobileStore storeAndItems={storeAndItems} />
        <div className="flex items-center justify-center mx-auto w-44">
          <Link to="/" className="flex items-center gap-1 mb-4">
            <p className="text-stone-800 text-xs">Powered by</p>
            <div>
              <div className="h-full flex items-center gap-0.5">
                <Map size={14} />
                <p
                  className="font-black text-stone-800 text-sm"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ) : (
      <div className="mx-auto relative flex flex-col items-center max-w-3xl h-screen">
        <DesktopStore storeAndItems={storeAndItems} />
        <div className="absolute bottom-0 flex items-center justify-center mx-auto">
          <Link to="/" className="flex items-center gap-1 mb-4">
            <p className="text-stone-800 text-xs">Powered by</p>
            <div>
              <div className="h-full flex items-center gap-0.5">
                <Map size={14} />
                <p
                  className="font-black text-stone-800 text-sm"
                  style={{ fontFamily: 'Inter, sans-serif' }}
                >
                  Fruntt
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  }

  return content;
};

export default CreatorDetail;
