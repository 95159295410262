import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  CreditCard,
  DownloadCloud,
  File,
  Layout,
  List,
  Mail,
  Tag,
  Truck,
} from 'react-feather';

const Features = () => {
  return isMobile ? (
    <div className="w-full flex flex-col items-center gap-2">
      <p className="text-stone-600 text-sm">Features</p>
      <div className="flex flex-col items-start gap-4">
        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-full h-24">
          <CreditCard size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Secure Payments</p>
            <p className="text-xs text-stone-600">
              All payments and payouts are secured by Stripe.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-full h-24">
          <File size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">MOR</p>
            <p className="text-xs text-stone-600">
              We are your Merchant of Record. No worrying about taxes, refunds,
              or chargebacks.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-full h-24">
          <List size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Variations</p>
            <p className="text-xs text-stone-600">
              Add variations to offer different verions of the same item. Pro,
              Basic, etc..
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-full h-24">
          <Tag size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Discounts</p>
            <p className="text-xs text-stone-600">
              Easily create and offer discounts on your items to attract more
              customers.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-full h-24">
          <Mail size={20} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Delivery</p>
            <p className="text-xs text-stone-600">
              Orders and project links automatically delivered after purchase.
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-full flex flex-col items-center gap-2">
      <p className="text-stone-600 text-sm">Features</p>
      <div className="flex items-start gap-4">
        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-1/5 h-32">
          <CreditCard size={24} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Secure Payments</p>
            <p className="text-xs text-stone-600">
              All payments and payouts are secured by Stripe.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-1/5 h-32">
          <File size={24} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">MOR</p>
            <p className="text-xs text-stone-600">
              We are your Merchant of Record. No worrying about taxes, refunds,
              or chargebacks.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-1/5 h-32">
          <List size={24} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Variations</p>
            <p className="text-xs text-stone-600">
              Add variations to offer different verions of the same item. Pro,
              Basic, etc..
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-1/5 h-32">
          <Tag size={24} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Discounts</p>
            <p className="text-xs text-stone-600">
              Easily create and offer discounts on your items to attract more
              customers.
            </p>
          </div>
        </div>

        <div className="flex flex-col gap-2 p-2 border border-gray-200 rounded-md w-1/5 h-32">
          <Mail size={24} />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-stone-800 font-bold">Delivery</p>
            <p className="text-xs text-stone-600">
              Orders and project links automatically delivered after purchase.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
