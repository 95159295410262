import React, { useState } from 'react';
import { useDeleteAccountMutation } from '../../api/authApiSlice';
import { useNavigate } from 'react-router-dom';
import Randomstring from 'randomstring';

//mui
import Alert from '@mui/material/Alert';
import handleLogutUser from '../../utils/logout';
import { Spinner } from 'flowbite-react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/toastSlice';

const DeleteAccount = ({ setDeleteAcc, deleteAcc, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [key, setKey] = useState('');
  const [deleteKey, setDeleteKey] = useState(Randomstring.generate(6));

  //hook to send delete account req
  const [deleteAccount, result] = useDeleteAccountMutation();

  const handleDeleteAccount = async (e) => {
    e.preventDefault();

    if (deleteKey !== key.trim()) {
      setError('Codes do not match');
      return;
    }

    setDeletingAccount(true);

    try {
      const deleteAccountReq = await deleteAccount().unwrap();

      if (deleteAccountReq === 'Account deleted') {
        setDeletingAccount(false);
        dispatch(showNotification('Account deleted'));
        handleLogutUser({ navigate, dispatch, type: 'delete' });
        return;
      } else {
        setError('There was an error');
        setDeletingAccount(false);
        return;
      }
    } catch (err) {
      setError('There was an error');
    }
  };

  return (
    <div className="flex items-center w-full justify-start gap-2">
      {user?.balance ? (
        <div className="flex items-center gap-2 border border-gray-200 rounded-md p-2 mt-2">
          <X
            size={16}
            className="text-red-500 hover:cursor-pointer"
            onClick={() => setDeleteAcc(!deleteAcc)}
          />
          <p className="text-stone-800 text-xs">
            Must have a balance of $0 to delete your account
          </p>
        </div>
      ) : (
        <>
          {deletingAccount ? (
            <Spinner className="mt-2" />
          ) : (
            <div className="flex flex-col gap-2 mt-2">
              {error ? (
                <div className="flex items-center gap-2 border border-gray-200 rounded-md p-2 mt-2">
                  <X
                    size={16}
                    className="text-red-500 hover:cursor-pointer"
                    onClick={() => setDeleteAcc(!deleteAcc)}
                  />
                  <p className="text-stone-800 text-xs">{error}</p>
                </div>
              ) : (
                ''
              )}
              <div className="flex flex-col">
                <input
                  type="text"
                  className="border border-gray-200 w-full bg-gray-50 hover:border-gray-200 focus:bg-gray-200 hover:bg-gray-200 focus:border-gray-200 rounded-md text-xs"
                  placeholder={deleteKey}
                  onChange={(e) => setKey(e.target.value)}
                />
                <p className="text-xs text-stone-600">
                  Type <span className="font-bold">{deleteKey}</span> above to
                  delete your account
                </p>
              </div>
              <div className="flex items-center gap-1">
                <button
                  type="button"
                  onClick={(e) => setDeleteAcc(!deleteAcc)}
                  className="text-stone-800 hover:bg-gray-200 rounded-md p-1 pl-2 pr-2 text-xs mt-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleDeleteAccount}
                  className="text-stone-800 bg-red-200 rounded-md p-1 pl-2 pr-2 text-xs mt-2"
                >
                  Delete account
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DeleteAccount;
