import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

const Mobile = ({
  products,
  handleFilterChange,
  filter,
  items,
  gettingProducts,
  gotProducts,
}) => {
  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  const endOffset = itemOffset + itemsPerPage;

  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;

    setItemOffset(newOffset);

    window.scrollTo(0, 0);
  };

  const marketClass = currentUser
    ? `w-full mx-auto h-fit mt-2`
    : `w-full mx-auto h-fit p-4 mt-14`;

  return (
    <div className={marketClass}>
      {/* <div className='bg-stone-800 text-sm rounded-tr rounded-tl h-10 flex items-center justify-center'>
        <p className='text-white'>
          Pay us only 1% of sales after processing fees{' '}
        </p>
        <BiSmile className='ml-1 text-white text-xl' />
      </div> */}

      <div className="w-full border border-gray-200 rounded-md flex items-center justify-center p-4 h-screen">
        <div className="flex flex-col gap-2 w-full text-center">
          <p className="text-stone-800 text-sm">
            Marketplace temporarily closed
          </p>
          <p className="text-stone-600 text-xs">
            To prepare for big updates and changes, the marketplace is closed.
            It will reopen once updates are in place and tested. In the meantime
            signup and start selling to your customers.
          </p>
          <Link to="/signup">
            <button className="text-stone-800 rounded-md text-xs p-1 pl-2 pr-2 bg-gray-200 mt-2">
              Start Selling
            </button>
          </Link>
        </div>
      </div>

      {/* {products.length > 9 ? (
        <div className='w-full flex justify-end mx-auto mt-2 mb-10'>
          <div className=''>
            <ReactPaginate
              breakLabel='...'
              nextLabel='Next'
              onPageChange={handlePageClick}
              marginPagesDisplayed={0}
              pageRangeDisplayed={0}
              pageCount={pageCount}
              previousLabel='Prev'
              renderOnZeroPageCount={null}
              className='flex items-center'
              activeLinkClassName='activePage'
              pageLinkClassName='notActivePage'
              breakLinkClassName='breakLink'
              disabledClassName='disabled'
            />
          </div>
        </div>
      ) : (
        ''
      )} */}
    </div>
  );
};

export default Mobile;
