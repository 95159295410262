import React from 'react';
import { Link } from 'react-router-dom';

//mui
import Alert from '@mui/material/Alert';
import { Spinner } from 'flowbite-react';
import { FaXTwitter } from 'react-icons/fa6';
import { Check, X } from 'react-feather';
import { FcGoogle } from 'react-icons/fc';

const LoginMobile = ({
  error,
  setPassword,
  setEmail,
  handleLogin,
  loginWithGoogle,
  handleTwitterLogin,
  isLoading,
  state,
  loggingIn,
  email,
  password,
}) => {
  return (
    <div className="flex flex-col items-center gap-2 w-full p-4">
      <div className="flex flex-col items-start w-full">
        <p className="text-lg text-stone-800">Login</p>
        <p className="text-stone-600 text-xs">
          Welcome back! Login to your account below
        </p>
      </div>
      {error && (
        <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
          <X size={16} className="text-red-500" />
          <p className="text-stone-800 text-xs">{error}</p>
        </div>
      )}
      {state?.success && (
        <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
          <Check size={16} className="text-green-400" />
          <p className="text-stone-800 text-xs">Password reset successful</p>
        </div>
      )}

      {state?.msg && (
        <Alert severity="error" className="w-full">
          {state?.msg}
        </Alert>
      )}
      <form className="flex flex-col gap-2 items-center w-full bg-white p-2 rounded-md border border-gray-200">
        <button
          type="button"
          onClick={loginWithGoogle}
          className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs flex items-center justify-center gap-1"
          // onMouseOver={() => setShowTooltip(true)}
          // onMouseOut={() => setShowTooltip(false)}
        >
          <FcGoogle className="text-lg" /> Google
          {/* {showTooltip ? (
              'Coming soon!'
            ) : (
              <>
                <FcGoogle className="text-lg" /> Google
              </>
            )} */}
        </button>

        <div className="flex items-center w-full">
          <div className="flex-grow border-t w-full border-gray-200 h-0"></div>
          <span className="mx-4 text-xs text-stone-800">or</span>
          <div className="flex-grow border-t w-full border-gray-200 h-0"></div>
        </div>

        <input
          type="email"
          placeholder="Email"
          className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent focus:ring-0 text-stone-800 hover:bg-gray-200 focus:bg-gray-200 0 w-full outline outline-0 rounded-md p-2"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />

        <input
          type="password"
          placeholder="Password"
          className="border text-xs border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 outline-0 outline w-full rounded-md p-2"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />

        {loggingIn ? (
          <div className="w-full flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <button
              disabled={isLoading}
              className="h-10 w-full border border-gray-200 bg-gray-200 text-stone-800 rounded-md text-xs"
              onClick={handleLogin}
            >
              Login
            </button>
            {/* <p className='text-xs text-stone-600'>OR</p>
            <button
              style={{
                backgroundColor: 'rgb(15, 20, 25)',
                borderColor: 'rgb(15, 20, 25)',
              }}
              className='w-full h-10 border text-xs rounded-md flex items-center justify-center text-white'
              type='button'
              onClick={handleTwitterLogin}
            >
              <p className='mr-2'>Login with</p>
              <FaXTwitter />
            </button> */}
          </>
        )}
        <div className="w-full flex justify-between">
          <Link to="/signup">
            <p className="text-xs text-stone-600">Don't have an account yet?</p>
          </Link>
          <Link to="/reset/password">
            <p className="text-xs text-stone-600">Forgot password?</p>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginMobile;
