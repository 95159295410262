import React, { useState } from 'react';
import { ChevronRight } from 'react-feather';
import { availCountries } from '../../../utils/availCountries';
import ReactCountryFlag from 'react-country-flag';
import { isMobile } from 'react-device-detect';

const Countries = ({ setDocsView, currentUser }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCountries = availCountries.filter((country) =>
    country.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const style = currentUser
    ? 'h-fit mx-auto max-w-6xl flex flex-col items-start gap-6'
    : 'h-fit mx-auto max-w-6xl flex flex-col items-start mt-24 gap-6';

  return isMobile ? (
    <div className="h-fit mx-auto w-full p-4 flex flex-col items-start mt-14 gap-4">
      <div className="flex flex-col items-end w-full gap-4">
        <div className="flex flex-col items-start gap-2 w-full border border-gray-200 p-2 rounded-md">
          <p className="text-sm text-stone-800">Available Countries</p>
          <p className="text-xs text-stone-600">
            Checkout our list of countries that Fruntt is available in. If your
            country is not listed below, you are not able to receive payouts
            from us at the moment.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2"
          >
            Back to docs <ChevronRight size={14} />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search countries..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 rounded-md w-52 text-xs"
        />
      </div>

      <div className="flex flex-col gap-4 w-full">
        {filteredCountries.length > 0 ? (
          filteredCountries.map((country) => (
            <div
              key={country.value}
              className="flex items-center gap-2 p-2 border border-gray-200 rounded-lg"
            >
              <ReactCountryFlag
                countryCode={country.value}
                svg
                className="w-6 h-4"
              />
              <p className="text-sm text-stone-800">{country.label}</p>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center p-2 border border-gray-200 rounded-md">
            <p className="text-xs text-stone-800">No countries found</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={style}>
      <div className="flex justify-between items-end w-full">
        <div className="flex flex-col items-start gap-2 w-8/12 border border-gray-200 p-2 rounded-md">
          <p className="text-sm text-stone-800">Available Countries</p>
          <p className="text-xs text-stone-600">
            Checkout our list of countries that Fruntt is available in. If your
            country is not listed below, you are not able to receive payouts
            from us at the moment.
          </p>
          <button
            type="button"
            onClick={() => setDocsView('home')}
            className="flex items-center gap-1 bg-gray-200 rounded-md text-stone-800 text-xs p-1 pl-2 pr-2"
          >
            Back to docs <ChevronRight size={14} />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search countries..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-2 border border-gray-200 bg-gray-50 hover:bg-gray-200 focus:bg-gray-200 focus:ring-0 focus:border-gray-200 rounded-md w-52 text-xs"
        />
      </div>

      <div className="grid grid-cols-4 gap-4 w-full">
        {filteredCountries.map((country) => (
          <div
            key={country.value}
            className="flex items-center gap-2 p-2 border border-gray-200 rounded-lg"
          >
            <ReactCountryFlag
              countryCode={country.value}
              svg
              className="w-6 h-4"
            />
            <p className="text-sm text-stone-800">{country.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Countries;
