import { Rating } from '@mui/material';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Desktop = ({ reviews, productId }) => {
  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  const endOffset = itemOffset + itemsPerPage;

  const currentReviews = reviews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviews.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reviews.length;

    setItemOffset(newOffset);

    window.scroll(0, 0);
  };
  //end of pagination stuff

  return (
    <div className='flex flex-col gap-2 mx-auto max-w-3xl'>
      <div className='w-full flex justify-between items-end'>
        <div className='flex flex-col bg-white rounded-md border border-gray-200 p-2'>
          <p className='text-sm text-stone-800'>Reviews</p>
          <p className='text-xs text-stone-600'>
            Reviews for item: {productId}
          </p>
        </div>

        <div className='flex items-center'>
          <p className='text-stone-600 text-xs'>
            {reviews.length == 1
              ? `${reviews.length} review`
              : `${reviews.length} reviews`}
          </p>
        </div>
      </div>
      {reviews.length > 0 ? (
        currentReviews.map((review) => (
          <div className='w-full flex flex-col gap-1 border border-gray-200 rounded-md p-4'>
            <p className='text-xs text-stone-800'>{review?.name}</p>
            <p className='text-xs text-stone-600'>{review?.review}</p>
            <Rating
              value={review?.rating}
              precision={0.5}
              size='small'
              readOnly
            />
          </div>
        ))
      ) : (
        <div className='w-full border border-gray-200 rounded-md h-72 flex items-center justify-center'>
          <p className='text-xs text-stone-600'>
            This item has not been reviewed
          </p>
        </div>
      )}
      {reviews.length > 5 ? (
        <div className='w-full flex justify-end mx-auto'>
          <div className=''>
            <ReactPaginate
              breakLabel='...'
              nextLabel='Next'
              onPageChange={handlePageClick}
              marginPagesDisplayed={0}
              pageRangeDisplayed={0}
              pageCount={pageCount}
              previousLabel='Prev'
              renderOnZeroPageCount={null}
              className='flex items-center'
              activeLinkClassName='activePage'
              pageLinkClassName='notActivePage'
              breakLinkClassName='breakLink'
              disabledClassName='disabled'
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Desktop;
