import React, { useState, useEffect } from 'react';
import { Carousel, Badge, Tooltip } from 'flowbite-react';
import { Avatar, Rating } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import {
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronUp,
  ShoppingBag,
  X,
} from 'react-feather';
import Checkout from '../../../components/Checkout/Checkout';
import {
  useCreateOrderMutation,
  useGetSingleOrderQuery,
  useLazyGetSingleOrderQuery,
} from '../../../api/ordersApiSlice';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import MiniNav from './MiniNav';
import ReactPaginate from 'react-paginate';
import { useAddVisitMutation } from '../../../api/storefrontApiSlice';
import { useApplyDiscountMutation } from '../../../api/productsApiSlice';

const Mobile = ({ product, rating, payouts, showSales, reviews }) => {
  const orderId = Cookies.get('orderId') ? Cookies.get('orderId') : null;
  const [openMore, setOpenMore] = useState(false);
  const [priceChoice, setPriceChoice] = useState(product?.price);
  const [priceSug, setPriceSug] = useState(product?.suggestedPrice);
  const [title, setTitle] = useState(product?.title);
  const [description, setDescription] = useState(product?.description);
  const [error, setError] = useState('');
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [noPayout, setNoPayout] = useState(false);
  const [openReviews, setOpenReviews] = useState(false);
  const [varIndex, setVarIndex] = useState(1000);
  const [enteredPrice, setEnteredPrice] = useState(0);
  const [versionId, setVersionId] = useState('');
  const [finalProduct, setFinalProduct] = useState(product);
  const [seeVars, setSeeVars] = useState(false);
  const [disCode, setDisCode] = useState('');
  const [disErr, setDisErr] = useState('');
  const [disApplied, setDisApplied] = useState(false);
  const [varTitle, setVarTitle] = useState('');
  const [varDescription, setVarDescription] = useState('');

  const [createOrder, result] = useCreateOrderMutation();
  const [getSingleOrder, { result: res }] = useLazyGetSingleOrderQuery();
  const [createdOrderId, setCreatedOrderId] = useState('');

  //stuff for pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 3;

  const endOffset = itemOffset + itemsPerPage;

  const currentReviews = reviews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(reviews.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % reviews.length;

    setItemOffset(newOffset);
  };
  //end of pagination stuff

  const [visitAdded, setVisitAdded] = useState(false);
  const visited = Cookies.get(`visited-${product?.pageName}`)
    ? Cookies.get(`visited-${product?.pageName}`)
    : null;

  //hook for adding a visit
  const [addVisit, { isLoading, isSuccess }] = useAddVisitMutation();

  const [applyDiscount, { isLoading: applyingDis }] =
    useApplyDiscountMutation();

  const handleOpenReviews = () => {
    setOpenReviews(!openReviews);
    setOpenMore(false);
  };

  const handleOpenDesc = () => {
    setOpenMore(!openMore);
    setOpenReviews(false);
  };

  useEffect(() => {
    const visit = async () => {
      try {
        const visitReq = await addVisit({
          storeId: product?.storeId,
        }).unwrap();

        if (visitReq === 'Visit tracked') {
          Cookies.set(`visited-${product?.pageName}`, true);
          setVisitAdded(true);
        }
        return;
      } catch (err) {
        return;
      }
    };

    if (!visited && !visitAdded) visit();
  }, []);

  const handleVarSelection = (title, description, price, id, index) => {
    setVarTitle(title);
    setPriceChoice(price);
    setPriceSug(price);
    setVarDescription(description);
    setVarIndex(index);
    setVersionId(id);
    setFinalProduct((prevProduct) => ({
      ...prevProduct,
      price: price,
    }));
    setSeeVars(false);
  };

  const handleCloseReviews = () => {
    setOpenReviews(!openReviews);
    window.scroll(0, 0);
  };

  //to see if product info is empty or not
  var regex = /(<([^>]+)>)/gi;
  const hasInfo = !!product?.info.replace(regex, '').length;

  const handleMoveToCheckout = async (e) => {
    e.preventDefault();

    setOpenMore(false);
    setOpenReviews(false);

    if (product?.payChoice && enteredPrice < priceChoice) {
      setError(`Set price must be at least $${priceChoice}`);
      return;
    } else if (product?.payChoice && enteredPrice >= priceChoice) {
      setPriceChoice(enteredPrice);
    }

    if (orderId) {
      setCreatedOrderId(orderId);
      setReadyForPayment(true);
    } else {
      try {
        const createOrderReq = await createOrder({
          total: product?.payChoice ? priceChoice : product?.price,
          item: product,
          storeId: product?.storeId,
        }).unwrap();
        Cookies.set('orderId', createOrderReq?._id);
        setCreatedOrderId(createOrderReq?._id);
        setReadyForPayment(true);
      } catch (err) {
        setError('There was a server error');
      }
    }
  };

  const handleResetCheckout = () => {
    setReadyForPayment(false);
    setPriceChoice(product?.price);
    setPriceSug(product?.suggestedPrice);
    setEnteredPrice(0);
    setVersionId('');
    setVarIndex(1000);
    setTitle(product?.title);
    setDescription(product?.description);
    setDisApplied(false);
  };

  const handleApplyDiscount = async () => {
    try {
      const disReq = await applyDiscount({
        productId: product?._id,
        code: disCode,
        amount: priceChoice,
      }).unwrap();

      if (!disReq.valid) {
        setDisErr(disReq?.msg);
        return;
      } else if (disReq.valid) {
        setPriceChoice(disReq.newAmount);
        setDisApplied(true);
      }

      console.log(disReq);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    setError('');
  }, [priceChoice, enteredPrice]);

  useEffect(() => {
    setDisErr('');
  }, [disCode]);

  return (
    <div className="flex flex-col gap-2 p-4">
      <MiniNav product={product} showSales={showSales} />
      {showSales && product?.numberOfSales > 0 && (
        <div className="flex items-center justify-start gap-2 border border-gray-200 bg-gray-50 rounded-md p-1">
          <ShoppingBag size={14} />
          <p className="text-stone-600 text-xs font-bold">
            Item has been sold{' '}
            {product?.numberOfSales === 1
              ? '1 time'
              : `${product?.numberOfSales} times`}
          </p>
        </div>
      )}
      {product?.published ? (
        <>
          <div className="border border-gray-200 rounded-md w-full flex flex-col mb-10">
            {readyForPayment ? (
              ''
            ) : (
              <div className="w-full rounded-md flex items-center justify-center h-44 p-2">
                {product?.coverImages.length ? (
                  <Carousel className="w-full">
                    {product?.coverImages.map((img, index) => (
                      <img
                        src={img?.url}
                        className="rounded-md object-fill h-full w-full"
                        alt=""
                        key={index}
                      />
                    ))}
                  </Carousel>
                ) : (
                  <img
                    src={product?.coverImage?.url}
                    className="rounded object-fill h-full w-full"
                    alt=""
                  />
                )}
              </div>
            )}

            <div className="w-full flex flex-col gap-4 p-4">
              <div
                className={`bg-white w-full h-full flex flex-col gap-4 ${
                  readyForPayment ? '' : 'border-b border-gray-200'
                } pb-6`}
              >
                {error ? (
                  <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                    <X size={16} className="text-red-500" />
                    <p className="text-stone-800 text-xs">{error}</p>
                  </div>
                ) : (
                  ''
                )}
                {readyForPayment ? (
                  <>
                    <div className="flex flex-col gap-1">
                      <p className="text-stone-600 text-xs mb-1">Buying</p>
                      <div className="w-full border border-gray-200 bg-gray-50 rounded-md p-2 flex flex-col gap-2 items-start">
                        <p className="text-stone-800 text-sm font-bold">
                          {product?.title}
                        </p>
                        <p className="text-stone-600 text-xs">
                          {product?.description}
                        </p>
                        {versionId ? (
                          <div className="w-full flex flex-col gap-1">
                            <p className="text-stone-600 text-xs">Variation</p>
                            <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                              <div className="flex flex-col items-start">
                                <p className="text-xs text-stone-800">
                                  {varTitle}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="flex items-center">
                          <div className="flex justify-center bg-gray-200 items-center pr-2 pl-2 p-1 rounded-md">
                            <p className="text-xs text-stone-800">
                              {product?.free
                                ? 'FREE'
                                : `${
                                    product?.currency?.symbol
                                  }${priceChoice?.toLocaleString('en-US', {
                                    minimumFractionDigits:
                                      priceChoice % 1 === 0 ? 0 : 2,
                                    maximumFractionDigits: 2,
                                  })}`}
                            </p>
                          </div>
                        </div>
                      </div>
                      {product?.discounts && (
                        <div className="flex flex-col gap-2 w-full mt-2">
                          {disErr && (
                            <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                              <X size={16} className="text-red-500" />
                              <p className="text-stone-800 text-xs">{disErr}</p>
                            </div>
                          )}
                          {disApplied ? (
                            <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                              <Check size={16} className="text-green-400" />
                              <p className="text-stone-800 text-xs">
                                Discount applied
                              </p>
                            </div>
                          ) : (
                            <div className="flex items-center gap-1">
                              <input
                                type="text"
                                className={`border border-gray-200 hover:border-gray-200 hover:bg-gray-200 w-full text-xs rounded-md p-2 bg-gray-50 flex-1 focus:bg-gray-200 focus:border-gray-200`}
                                placeholder="Enter code"
                                onChange={(e) => setDisCode(e.target.value)}
                              />
                              <button
                                type="button"
                                onClick={handleApplyDiscount}
                                className="p-2 bg-gray-200 text-stone-800 border border-gray-200 rounded-md text-xs"
                                disabled={applyingDis || disApplied}
                              >
                                Apply Discount
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <Checkout
                      orderId={createdOrderId}
                      total={priceChoice}
                      item={finalProduct}
                      setReadyForPayment={setReadyForPayment}
                      handleResetCheckout={handleResetCheckout}
                      error={error}
                      setError={setError}
                      versionId={versionId}
                      disCode={disCode}
                    />
                  </>
                ) : (
                  <>
                    <div className="flex flex-col gap-2">
                      <p className="text-stone-800 text-sm font-bold">
                        {title}
                      </p>
                      {description ? (
                        <p className="text-stone-600 text-xs">{description}</p>
                      ) : (
                        ''
                      )}
                    </div>

                    {versionId ? (
                      <div className="w-full flex flex-col gap-1">
                        <p className="text-stone-600 text-xs">
                          Variation selected
                        </p>
                        <div className="flex items-center w-full justify-between p-2 rounded-md border border-gray-200">
                          <div className="flex flex-col items-start">
                            <p className="text-xs text-stone-800">{varTitle}</p>
                            <p className="text-xs text-stone-600">
                              {varDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="flex items-center gap-4">
                      <div className="flex justify-center bg-gray-200 items-center pr-2 pl-2 p-1 rounded-md">
                        <p className="text-xs text-stone-800 font-bold">
                          {product?.free
                            ? 'FREE'
                            : product?.payChoice
                            ? `${
                                product?.currency?.symbol
                              }${priceChoice?.toLocaleString('en-US', {
                                minimumFractionDigits:
                                  priceChoice % 1 === 0 ? 0 : 2,
                                maximumFractionDigits: 2,
                              })}+`
                            : `${
                                product?.currency?.symbol
                              }${priceChoice?.toLocaleString('en-US', {
                                minimumFractionDigits:
                                  priceChoice % 1 === 0 ? 0 : 2,
                                maximumFractionDigits: 2,
                              })}`}
                        </p>
                      </div>
                      <button
                        type="button"
                        onClick={handleOpenReviews}
                        className="flex items-end text-xs text-stone-600"
                        disabled={reviews.length === 0}
                      >
                        <Rating
                          value={rating}
                          readOnly
                          precision={0.5}
                          size="small"
                        />
                        ({reviews.length})
                      </button>
                      {product?.versions.length > 0 && (
                        <button
                          type="button"
                          onClick={() => setSeeVars(!seeVars)}
                          className="flex items-center justify-between border border-gray-200 rounded-md p-2 w-full"
                        >
                          <p className="text-xs text-stone-800">Variations</p>
                          {seeVars ? (
                            <ChevronUp size={16} className="text-stone-800" />
                          ) : (
                            <ChevronDown size={16} className="text-stone-800" />
                          )}
                        </button>
                      )}
                    </div>
                    {seeVars ? (
                      <>
                        {product?.versions.length > 0 && (
                          <div className="flex flex-col gap-2 items-start w-full">
                            <div className="flex flex-wrap gap-1 w-full">
                              <button
                                type="button"
                                className={`flex items-center w-full justify-between p-2 rounded-md border border-gray-200 hover:bg-gray-100 ${
                                  varIndex === 1000 && 'bg-gray-100'
                                }`}
                                onClick={() =>
                                  handleVarSelection(
                                    product?.title,
                                    product?.description,
                                    product?.price,
                                    '',
                                    1000
                                  )
                                }
                              >
                                <div className="flex flex-col items-start">
                                  <p className="text-xs text-stone-800">
                                    {product?.title}
                                  </p>
                                </div>
                                <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center text-xs">
                                  <p className="text-xs text-stone-800">
                                    $
                                    {product?.price?.toLocaleString('en-US', {
                                      minimumFractionDigits:
                                        product?.price % 1 === 0 ? 0 : 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </p>
                                </div>
                              </button>
                              {product?.versions.map((version, index) => (
                                <button
                                  type="button"
                                  className={`flex items-center w-full justify-between p-2 rounded-md border border-gray-200 hover:bg-gray-100 ${
                                    varIndex === index && 'bg-gray-100'
                                  }`}
                                  key={index}
                                  onClick={() =>
                                    handleVarSelection(
                                      version?.title,
                                      version?.description,
                                      version?.price,
                                      version?._id,
                                      index
                                    )
                                  }
                                >
                                  <div className="flex flex-col items-start">
                                    <p className="text-xs text-stone-800">
                                      {version?.title}
                                    </p>
                                  </div>
                                  <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center text-xs">
                                    <p className="text-xs text-stone-800">
                                      $
                                      {version?.price?.toLocaleString('en-US', {
                                        minimumFractionDigits:
                                          version?.price % 1 === 0 ? 0 : 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </p>
                                  </div>
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {product?.payChoice ? (
                      <div className="flex flex-col w-full gap-1">
                        <p className="text-xs text-stone-600">Set your price</p>
                        {noPayout && (
                          <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                            <X
                              size={16}
                              className="text-red-500 hover:cursor-pointer"
                              onClick={() => setNoPayout(!noPayout)}
                            />
                            <p className="text-stone-800 text-xs">
                              This item cannot be purchased right now
                            </p>
                          </div>
                        )}
                        <div className="w-full flex gap-2">
                          <div className="w-3/12">
                            <input
                              type="number"
                              className="border text-xs border-gray-200 bg-gray-50 focus:bg-gray-200 focus:border-gray-200 mr-2 rounded-md h-8 p-2 w-full"
                              placeholder={`${product?.currency?.symbol}${priceSug}+`}
                              onChange={(e) => setEnteredPrice(e.target.value)}
                            />
                          </div>
                          {payouts ? (
                            <>
                              {hasInfo ? (
                                <div className="w-9/12 flex gap-2">
                                  <button
                                    type="button"
                                    onClick={handleMoveToCheckout}
                                    className="w-10/12 h-8 text-xs bg-gray-200 rounded-md"
                                  >
                                    {product?.callToAction === 'buy'
                                      ? 'Buy Now'
                                      : product?.callToAction === 'want'
                                      ? 'I Want This!'
                                      : 'Get Now'}
                                  </button>
                                  <button
                                    type="button"
                                    onClick={handleOpenDesc}
                                    className="bg-gray-200 rounded-md h-8 text-stone-800 w-2/12 flex items-center justify-center"
                                  >
                                    {openMore ? (
                                      <ChevronUp size={14} />
                                    ) : (
                                      <ChevronDown size={14} />
                                    )}
                                  </button>
                                </div>
                              ) : (
                                <div className="w-9/12 flex">
                                  <button
                                    type="button"
                                    onClick={handleMoveToCheckout}
                                    className="w-full h-8 text-xs bg-gray-200 rounded-md"
                                  >
                                    {product?.callToAction === 'buy'
                                      ? 'Buy Now'
                                      : product?.callToAction === 'want'
                                      ? 'I Want This!'
                                      : 'Get Now'}
                                  </button>
                                </div>
                              )}
                            </>
                          ) : (
                            <button
                              type="button"
                              className="w-9/12 h-8 text-xs bg-gray-200 rounded-md"
                              onClick={() => setNoPayout(!noPayout)}
                            >
                              {product?.callToAction === 'buy'
                                ? 'Buy Now'
                                : product?.callToAction === 'want'
                                ? 'I Want This!'
                                : 'Get Now'}
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-1">
                        {noPayout && (
                          <div className="w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2">
                            <X
                              size={16}
                              className="text-red-500 hover:cursor-pointer"
                              onClick={() => setNoPayout(!noPayout)}
                            />
                            <p className="text-stone-800 text-xs">
                              This item cannot be purchased right now
                            </p>
                          </div>
                        )}
                        {payouts ? (
                          <>
                            {hasInfo ? (
                              <div className="w-full flex gap-2">
                                <button
                                  type="button"
                                  onClick={handleMoveToCheckout}
                                  className="w-10/12 h-8 text-xs bg-gray-200 rounded-md"
                                >
                                  {product?.callToAction === 'buy'
                                    ? 'Buy Now'
                                    : product?.callToAction === 'want'
                                    ? 'I Want This!'
                                    : 'Get Now'}
                                </button>
                                <button
                                  type="button"
                                  onClick={handleOpenDesc}
                                  className="bg-gray-200 rounded-md h-8 text-stone-800 w-2/12 flex items-center justify-center"
                                >
                                  {openMore ? (
                                    <ChevronUp size={14} />
                                  ) : (
                                    <ChevronDown size={14} />
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div className="w-full flex">
                                <button
                                  type="button"
                                  onClick={handleMoveToCheckout}
                                  className="w-full h-8 text-xs bg-gray-200 rounded-md"
                                >
                                  {product?.callToAction === 'buy'
                                    ? 'Buy Now'
                                    : product?.callToAction === 'want'
                                    ? 'I Want This!'
                                    : 'Get Now'}
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <button
                            type="button"
                            className="w-full h-10 text-xs bg-gray-200 rounded-md"
                            onClick={() => setNoPayout(!noPayout)}
                          >
                            {product?.callToAction === 'buy'
                              ? 'Buy Now'
                              : product?.callToAction === 'want'
                              ? 'I Want This!'
                              : 'Get Now'}
                          </button>
                        )}
                      </div>
                    )}
                    {openReviews ? (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between w-full">
                          <div className="flex items-center">
                            <p className="text-stone-600 text-xs">
                              {reviews.length === 1
                                ? `${reviews.length} review`
                                : `${reviews.length} reviews`}
                            </p>
                          </div>

                          <X
                            className="text-stone-600 hover:cursor-pointer"
                            size={14}
                            onClick={() => setOpenReviews(!openReviews)}
                          />
                        </div>
                        <div className="flex flex-col w-full gap-1">
                          {currentReviews.map((review) => (
                            <div className="w-full flex flex-col gap-1 border border-gray-200 rounded-md p-2">
                              <div className="flex items-center gap-2">
                                <p className="text-xs text-stone-800">
                                  {review?.name}
                                </p>
                                <Rating
                                  value={review?.rating}
                                  precision={0.5}
                                  size="small"
                                  readOnly
                                />
                              </div>
                              <p className="text-xs text-stone-600">
                                {review?.review}
                              </p>
                            </div>
                          ))}
                        </div>
                        {reviews.length > 3 ? (
                          <div className="w-full flex justify-end">
                            <div className="">
                              <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={0}
                                pageCount={pageCount}
                                previousLabel="Prev"
                                renderOnZeroPageCount={null}
                                className="flex items-center"
                                activeLinkClassName="activePage"
                                pageLinkClassName="notActivePage"
                                breakLinkClassName="breakLink"
                                disabledClassName="disabled"
                              />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
              <div className="bg-white w-full h-full flex flex-col items-start">
                {readyForPayment ? (
                  ''
                ) : (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2 items-start w-full">
                      <p className="text-xs text-stone-600">Creator</p>
                      <Link to={`/${product?.pageName}`}>
                        <div className="flex items-center">
                          <Avatar
                            src={product?.userPicture}
                            sx={{ width: 22, height: 22 }}
                          />
                          <p className="ml-1 text-stone-800 text-sm">
                            {product?.userName}
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="flex flex-col gap-2 items-start w-full">
                      <p className="text-xs text-stone-600">Platform</p>
                      <span className="bg-gray-200 text-stone-800 text-xs p-2 pt-0.5 pb-0.5 rounded-md">
                        {product?.platform}
                      </span>
                    </div>
                    {product?.categories.length > 0 && (
                      <div className="flex flex-col gap-2 items-start w-full">
                        <p className="text-xs text-stone-600">Categories</p>
                        <div className="flex flex-wrap gap-2">
                          {product?.categories.map((cat, index) => (
                            <span
                              className="bg-gray-200 text-stone-800 text-xs p-2 pt-0.5 pb-0.5 rounded-md"
                              key={index}
                            >
                              {cat?.label}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {openMore && (
              <ReactQuill
                value={product?.info}
                readOnly={true}
                theme={'bubble'}
              />
            )}
          </div>
        </>
      ) : (
        <div
          className="flex flex-col border-gray-200 items-center justify-center rounded-md w-full border bg-white"
          style={{ height: '600px' }}
        >
          <p className="text-stone-800 text-sm">Not published</p>
          <p className="text-stone-600 text-xs">
            This template is not published for sales yet
          </p>
        </div>
      )}
    </div>
  );
};

export default Mobile;
