import { Spinner } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { AlertCircle, ChevronLeft, X } from 'react-feather';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import {
  useConfirmPaymentMutation,
  useUpdateOrderMutation,
} from '../../api/ordersApiSlice';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

//flowbite
import { Alert } from 'flowbite-react';

const CheckoutForm = ({
  orderId,
  total,
  item,
  handleResetCheckout,
  setError,
  error,
  versionId,
  disCode,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const currentUser = Cookies.get('currentUser')
    ? JSON.parse(Cookies.get('currentUser'))
    : null;

  const [email, setEmail] = useState(currentUser?.email || '');
  const [name, setName] = useState('');
  const [placingOrder, setPlacingOrder] = useState(false);

  const [updateOrder, { isLoading }] = useUpdateOrderMutation();
  const [confirmPayment, { isLoading: paying }] = useConfirmPaymentMutation();

  const handleConfirmOrder = async (e) => {
    e.preventDefault();
    setPlacingOrder(true);
    setError('');

    if (!stripe || !elements) {
      setError('There was an error');
      setPlacingOrder(false);
      return;
    }

    // //make sure all fields are filled out
    if (!email || !name) {
      setError('All feilds must be filled out');
      setPlacingOrder(false);
      return;
    }

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setError(submitError);
        setPlacingOrder(false);
        return;
      }
      const { error, confirmationToken } = await stripe.createConfirmationToken(
        {
          elements,
          params: {
            payment_method_data: {
              billing_details: {
                name: name,
              },
            },
          },
        }
      );
      if (error) {
        setError(error);
        setPlacingOrder(false);
        return;
      }
      //update order with data
      const updatedOrderReq = await updateOrder({
        orderId: orderId,
        email: email.toLowerCase(),
        name: name,
        country: confirmationToken?.payment_method_preview?.card?.country,
        zipcode:
          confirmationToken?.payment_method_preview?.billing_details?.address
            ?.postal_code,
        total: total,
        item: item,
        versionId: versionId,
        disCode: disCode,
        userId:
          currentUser && currentUser?.email === email ? currentUser?._id : '',
      }).unwrap();
      if (updatedOrderReq.msg === 'Order updated') {
        const paymentReq = await confirmPayment({
          confirmationToken: confirmationToken?.id,
          orderId: orderId,
        }).unwrap();
        if (paymentReq?.status === 'succeeded') {
          Cookies.remove('orderId');
          setPlacingOrder(false);
          navigate(`/order/${orderId}?oat=${paymentReq?.oat}`);
        } else if (paymentReq?.status === 'failed') {
          setPlacingOrder(false);
          setError(paymentReq?.msg);
          return;
        }
      }
    } catch (err) {
      setPlacingOrder(false);
      setError('There was a server error');
      return;
    }
  };

  useEffect(() => {
    setError('');
  }, [email, name]);

  return (
    <div>
      <form onSubmit={handleConfirmOrder} className="flex flex-col">
        {/* {error ? (
          <div className='w-full flex items-center justify-start gap-2 border border-gray-200 rounded-md p-2'>
            <X size={16} className='text-red-500' />
            <p className='text-stone-800 text-xs'>{error}</p>
          </div>
        ) : (
          ''
        )} */}

        <p className="text-stone-600 text-xs">Customer</p>
        <div className="flex items-center mt-1">
          <input
            type="email"
            placeholder="Email"
            className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-3/6 outline outline-0 rounded-md p-2"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input
            type="text"
            placeholder="Name"
            className="border text-xs border-gray-200 bg-gray-50 ring-0 focus:border-transparent hover:bg-gray-200 focus:bg-gray-200 0 w-3/6 outline outline-0 rounded-md p-2 ml-2"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <p className="text-stone-600 text-xs mb-1 mt-2">Payment</p>
        <PaymentElement />
        <div className="w-full flex items-center mt-2">
          <button
            type="button"
            disabled={placingOrder}
            onClick={handleResetCheckout}
            className="w-3/12 h-10 text-stone-800 bg-gray-200 rounded-md mr-2 flex items-center justify-center"
          >
            <ChevronLeft size={16} />
          </button>
          <button
            disabled={placingOrder}
            type="submit"
            className="w-9/12 h-10 bg-gray-200 text-stone-800 text-xs rounded-md"
          >
            {placingOrder ? <Spinner /> : 'Get Now'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;
