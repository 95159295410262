import { apiSlice } from './apiSlice';

export const payoutsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPayouts: builder.query({
      query: () => `/payouts/get`,
      keepUnusedDataFor: 5,
    }),
    getPayout: builder.query({
      query: ({ payoutId }) => `/payouts/get/${payoutId}`,
      keepUnusedDataFor: 5,
    }),
  }),
});

export const { useGetPayoutsQuery, useGetPayoutQuery } = payoutsApiSlice;
