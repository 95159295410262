import React from 'react';
import { Inbox } from 'react-feather';
import { Link } from 'react-router-dom';

const Desktop = () => {
  return (
    <div className="flex flex-col">
      <div className="border border-gray-200 rounded-md w-full flex items-center justify-center h-72">
        <div className="flex flex-col items-center gap-2">
          <Inbox size={18} className="text-stone-600" />
          <p className="text-stone-800 text-xs">You have no notifications</p>
          <Link
            to="/"
            className="bg-gray-200 text-xs rounded-md p-2 flex items-center justify-center"
          >
            Return home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
