import React, { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import ReactPaginate from 'react-paginate';
import { BsPeople } from 'react-icons/bs';
import { Tooltip } from 'flowbite-react';
import { User } from 'react-feather';

const DesktopCustomers = ({ customers }) => {
  //stuff for pagination//
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  const endOffset = itemOffset + itemsPerPage;
  const currentCustomers = customers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(customers.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % customers.length;

    setItemOffset(newOffset);
  };
  //pagination stuff ends here//

  return customers.length > 0 ? (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="w-full flex justify-between items-end">
        <div className="flex flex-col items-start rounded-md border border-gray-200 p-2">
          <p className="text-sm text-stone-800">Customers</p>
          <p className="text-xs text-stone-600">View all your customers</p>
        </div>

        <p className="text-stone-600 font-medium text-xs">
          {customers.length > 1
            ? `${customers.length} customers`
            : `${customers.length} customer`}
        </p>
      </div>

      <div className="flex flex-col gap-2">
        {currentCustomers.map((customer, index) => (
          <div
            className="flex items-center justify-between w-full border border-gray-200 rounded-md p-2"
            key={index}
          >
            <div className="flex justify-start w-3/12">
              <p className="text-xs text-stone-800">{customer?.email}</p>
            </div>

            <div className="flex items-center justify-center gap-2 w-3/12">
              <p className="text-xs text-stone-800">{customer?.name}</p>
            </div>

            <div className="flex items-center justify-center gap-2 w-3/12">
              <p className="text-xs text-stone-800">
                {customer?.country?.name}
              </p>

              <ReactCountryFlag countryCode={customer?.country?.code} />
            </div>

            <div className="flex justify-end w-3/12">
              <div className="rounded-md p-1 pl-2 pr-2 bg-gray-200 flex items-center justify-center">
                <p className="text-xs">
                  {customer?.numberOfOrders === 1
                    ? `${customer?.numberOfOrders} order`
                    : `${customer?.numberOfOrders} orders`}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {customers.length > 12 ? (
        <div className="w-full flex justify-end mx-auto mt-2">
          <div className="">
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              marginPagesDisplayed={0}
              pageRangeDisplayed={0}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="flex items-center"
              activeLinkClassName="activePage"
              pageLinkClassName="notActivePage"
              breakLinkClassName="breakLink"
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <div
      className="w-full bg-white border-gray-200 border rounded-md flex flex-col gap-2 items-center justify-center"
      style={{ height: '600px' }}
    >
      <User size={18} className="text-stone-800" />
      <p className="text-stone-800 text-sm">No customers</p>

      <p className="text-stone-600 text-xs text-center w-60">
        View all customers who make a purchase from you here
      </p>
    </div>
  );
};

export default DesktopCustomers;
